import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { MessageService } from "../../services";
import { Message, MessageType } from "src/app/models";

@Component({
  selector: "element-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit, OnDestroy {
  constructor(private ms: MessageService) {}
  //@ts-ignore
  _message: Subscription;
  //@ts-ignore
  message: Message = null;

  ping: boolean = false;
  active: boolean = false;
  timer: any = null;

  ngOnInit(): void {
    this._message = this.ms.message.subscribe((message: Message) => {
      if (message) {
        this.message = message;

        this.ping = true;

        setTimeout(() => {
          this.active = true;
        }, 500);

        setTimeout(() => {
          this.active = false;

          setTimeout(() => {
            this.ping = false;
          }, 500);
        }, message.delay);
      }
    });
  }

  close() {
    clearTimeout(this.timer);
    setTimeout(() => {
      this.active = false;
      setTimeout(() => {
        this.ping = false;
      }, 500);
    }, 1);
  }

  ngOnDestroy() {
    this._message.unsubscribe();
  }
}
