import {Component, HostListener, OnInit} from '@angular/core';
import {GcPdfViewer, ViewerOptions} from "@grapecity/gcpdfviewer";
import {config} from "../../app.config";
import {downloadData} from "../../utilities";
import {ActivatedRoute, RouterModule} from "@angular/router";
import {DocumentRpcService} from "../../services/document.rpc.service";

@Component({
  selector: 'view-asci-message-preview',
  templateUrl: './asci-message-preview.component.html',
  styleUrls: ['./asci-message-preview.component.scss']
})
export class AsciMessagePreviewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private doc: DocumentRpcService) {
  }

  is_loading = false;
  is_reloading = false;

  _viewerOptions: ViewerOptions;
  _viewer: GcPdfViewer;

  did: string;
  bid: string;

  idleTimer: any;
  lastTouched: Date;

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:click', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:load', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:scroll', ['$event'])
  async initKeydownListener(event: KeyboardEvent) {
    const currentDate = new Date();

    this.resetTimer();

    if (this.lastTouched && (currentDate.getTime() - this.lastTouched.getTime()) >= 1000 * 60 * 60) {
      // await this.reload()
      this.lastTouched = currentDate;
    }

  }
  resetTimer() {
    if(this.idleTimer){
      clearTimeout(this.idleTimer);
      this.idleTimer = setTimeout(()=>window.close(), 3600000);  //close window in an hour when idle
    }
  }

  async reload() {
    if (!this.is_reloading) {

      this.is_reloading = true;
      this.doc.getAsciMessage().then((stream) => {
        this.is_loading = true;

        if (stream) {
          this._viewer.open(stream);
        }

        this.is_loading = false;
        this.is_reloading = false;
      });

    }
  }

  async ngOnInit() {
    this.lastTouched = new Date();
    this.is_loading = true;
    this.loadViewer();
    this.doc.getAsciMessage().then(stream => {
      this._viewer.open(stream).then(() => {
        this.is_loading = false;
      });
    });
  }

  loadViewer() {
    if (this._viewer) {
      this._viewer.dispose();
    }

    this._viewerOptions = new ViewerOptions();
    this._viewerOptions.workerSrc = "//node_modules/@grapecity/gcpdfviewer/gcpdfviewer.worker.js";
    this._viewerOptions.restoreViewStateOnLoad = true;
    this._viewerOptions.buttons = ["custom-fullscreen", "custom-download", "print", "$navigation", "zoom", "pan", "text-selection", "view-mode", "rotate"];
    this._viewerOptions.requireTheme = "viewer";
    this._viewerOptions.renderer = "canvas";

    GcPdfViewer.LicenseKey = atob(config.gc);

    this._viewer = new GcPdfViewer("#preview", this._viewerOptions);
    this._viewer.addThumbnailsPanel();
    this._viewer.addSearchPanel();
    this._viewer.options.shortcuts = {
      82: [{
        ctrl: true, keyCode: 82, tool: () => {
        }
      }]
    };

    this._viewer.options.zoomByMouseWheel = {always: false, ctrlKey: true, metaKey: true};

    const React = this._viewer.getType("React");
    const viewer: any = this._viewer;
    const toolbar = viewer.toolbar;

    this._viewer.onAfterOpen.register(() => {
      if (document.querySelector('#viewer:has(.gcv-main-view.gcv-main-view--fullscreen)')) {
        viewer.toolbar._view._commands.toggleFullscreen();
      }
    });


    toolbar.addItem({
      key: "custom-fullscreen",
      icon: {
        type: 'svg',
        content: React.createElement(
          'svg',
          {xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#559765'},
          React.createElement('path', {d: 'M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z'})
        )
      },
      title: "Fullscreen",
      enabled: true,
      action: async () => {
        viewer.toolbar._view._commands.toggleFullscreen();
        window.dispatchEvent(new Event('resize'));
      },
    });


    // toolbar.addItem({
    //   key: "custom-download",
    //   icon: {
    //     type: 'svg',
    //     content: React.createElement(
    //       'svg',
    //       {xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#559765'},
    //       React.createElement('path', {d: 'M5 19.998h14v-2H5m14-9h-4v-6H9v6H5l7 7 7-7z'})
    //     )
    //   },
    //   title: "Download",
    //   enabled: true,
    //   action: async () => {
    //     this.is_loading = true;
    //
    //     if (this.bid && this.did) {
    //       const stream = await this.doc.getDocumentDownload(this.bid, [this.did]);
    //       if (stream) {
    //         downloadData(stream, "_" , "zip");
    //       }
    //     }
    //     this.is_loading = false;
    //   },
    // });


  }
}
