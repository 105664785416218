import {
  AfterViewInit,
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {LegacyPageEvent as PageEvent} from "@angular/material/legacy-paginator";
import {MatLegacyTable, MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatSort, Sort} from "@angular/material/sort";
import {SelectionModel} from "@angular/cdk/collections";
import {
  Batch,
  MessageService,
  AuthenticationService,
  InterfaceService,
} from "src/app/services";
import {
  InterfacePayloadType,
  InterfacePayload,
} from "src/app/services/interface.service";
import {BatchService} from "src/app/services/batch.service";
import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
} from "../table/types";
import {MessageType} from "src/app/models/message";
import {Role, Claim} from "src/app/models/account";
import {Router} from "@angular/router";
import {UntypedFormBuilder} from "@angular/forms";
import {FormsModel} from "src/app/models/forms";
import {OnChange, OnChangeType} from "../quick-edit/quick-edit.component";
import {config} from "../../app.config";

export interface IBatch {
  awb_bol_number: string;
  batch_id: string;
  bid: string;
  bookmarked: string;
  courier_type: string;
  created_on: string;
  estimated_date_arrival: string;
  gov_status: string;
  importers: string;
  order_number: string;
  status: string;
  supplliers: string;
  tags: string;
  truckers: string;
  updated_on: string;
}

@Component({
  selector: "element-table-ng",
  templateUrl: "./table-ng.component.html",
  styleUrls: ["./table-ng.component.scss"],
})
export class TableNgComponent implements AfterViewInit, OnChanges {
  get userRole(): Role {
    return this._userRole;
  }

  set userRole(value: Role) {
    this._userRole = value;
  }

  constructor(
    private bs: BatchService,
    private _auth: AuthenticationService,
    private fb: UntypedFormBuilder,
    private _is: InterfaceService,
    private router: Router,
    private _ms: MessageService
  ) {
  }

  // @ts-ignore
  @Input() datasource: IBatch[];
  // @ts-ignore
  @Input() columns: string[];
  // @ts-ignore
  @Input() table: TableData;

  @Output() onChange: EventEmitter<OnTableChangePayload> = new EventEmitter();

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  // @ts-ignore
  @ViewChild("wrapperEle") wrapperEle: ElementRef;
  hide_export = false;

  // @ts-ignore
  _role: Role;

  displayedColumns: string[] = [
    "clicker",
    "bid",
    "bookmarked",
    "awb_bol_number",
    "batch_id",
    "courier_type",
    "created_on",
    "created_by",
    "estimated_date_arrival",
    "gov_status",
    "importers",
    "order_number",
    "status",
    "supplliers",
    "tags",
    "truckers",
    "updated_on",
    "actions",
  ];

  dataSource = new MatTableDataSource<IBatch>();
  selection = new SelectionModel<string>(true, []);

  isLoading = true;

  forms: FormsModel = {
    discard_draft: {
      form: this.fb.group({}),
      fields: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
    },
  };

  batch_discard_state: { confirm: boolean; active: any; status: any } = {
    confirm: false,
    active: null,
    status: null,
  };

  quick_edit: { active: boolean; selections: any[] , filter:any} = {
    active: false,
    selections: [],
    filter:null,
  };

  // @ts-ignore
  protected _userRole:Role;

  protected readonly Claim = Claim;
  protected readonly Role = Role;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const that = this;

    const numRows = this.dataSource.data.filter((row) => {
      return that.selection.selected.indexOf(row.bid) != -1;
    }).length;

    return numRows === numSelected;
  }

  toggleAllRows() {
    const that = this;
    if (this.selection.selected.length > 0) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((e) => {
        that.selection.toggle(e.bid);
      });
    }
  }

  toggleDraftDiscard(item: Batch) {
    this.batch_discard_state.status = String(item.status);
    this._is.toggle({type: "delete", payload: item.bid});
  }


  discardHideConditions(item: any) {
    return (
      item.status == "Draft" || this._auth.getClaim(Claim.Role) == Role.Alpha
    );
  }


  onAwbBolClick(item:IBatch){
    if(item.courier_type != undefined){
      let link = '#';

      //@ts-ignore
      if(item.courier_type == 1){
        link = "https://connect.track-trace.com/for/"+config.trackingID+"/aircargo/"+item.awb_bol_number;
      }

      //@ts-ignore
      if(item.courier_type == 0){
        link = "https://connect.track-trace.com/for/"+config.trackingID+"/billoflading/"+item.awb_bol_number;
      }

      window.open(link, '_blank');
    }
  }


  async bookmarkBatch(item: Batch, parentEle: HTMLElement) {
    if (item.bid) {
      const icon = parentEle.querySelector("i");
      if (icon) {
        const result = await this.bs.bookmarkBatch(item.bid);
        if (icon?.classList.contains("bookmark-active") && !result) {
          icon.classList.remove("bookmark-active");
        }
        if (!icon?.classList.contains("bookmark-active") && result) {
          icon.classList.add("bookmark-active");
        }
      }
    }
  }

  onQuickEditChange(change: OnChange) {
    switch (change.type) {
      case OnChangeType.Toggle:
        this.quick_edit.active = change.data;
        break;
      case OnChangeType.Reload:
        this.isLoading = true;

        this.onChange.emit({
          type: TablePayloadType.Reload,
          payload: {...this.table.options},
        });
        this.quick_edit.active = change.data;
        break;
    }
  }

  toggleQuicedit() {
    this.quick_edit.active = !this.quick_edit.active;
  }

  async reviseBatch(item: Batch) {
    if (item.bid) {
      const pending = await this.bs.getPendingBatchTask(
        "DocumentPrintAll",
        item.bid
      );

      if (pending && pending.completedOn == null) {
        this._ms.set({
          message:
            "Batch documents are currently being processed. Please try again later.",
          type: MessageType.Info,
          delay: 7000,
        });
        return;
      }

      await this.router.navigate(["dashboard", "batch", "revise", item.bid]);
    }
  }

  async viewBatch(item: Batch) {
    if (item.bid) {
      await this.router.navigate(["dashboard", "batch", item.bid]);
    }
  }

  editHideConditions(item: any) {
    return (
      ((item.status == "Created" ||
          item.status == "Created *" ||
          item.status == "Issue" ||
          item.status == "Issue *" ||
          item.status == "Draft") &&
        this._role != Role.Trucker) ||
      this._role == Role.Alpha ||
      this._role == Role.Clerk ||
      this._role == Role.Manager
    );
  }

  checkboxLabel(row?: IBatch): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row.bid) ? "deselect" : "select"} row ${
      row.batch_id + 1
    }`;
  }

  // tslint:disable-next-line:ban-types
  onClickCapture(func: Function) {
    if (func) {
      func();
    }
  }

  sortChange(sortState: Sort) {
    this.isLoading = true;

    this.table.options.page.orderBy.column = sortState.active;
    this.table.options.page.orderBy.direction =
      sortState.direction == "asc"
        ? TableOrderByDirection.ASC
        : TableOrderByDirection.DESC;

    this.onChange.emit({
      type: TablePayloadType.Reload,
      payload: {...this.table.options},
    });
  }

  groupBy(group: string) {
    this.wrapperEle.nativeElement.scrollTop = 0;
    this.wrapperEle.nativeElement.scrollLeft = 0;

    this.isLoading = true;

    this.table.options.page.groupBy = group;
    this.table.options.page.current = 0;
    this.onChange.emit({
      type: TablePayloadType.GroupBy,
      payload: {...this.table.options},
    });
  }

  onPagerChange(e: PageEvent) {
    this.wrapperEle.nativeElement.scrollTop = 0;
    this.wrapperEle.nativeElement.scrollLeft = 0;

    this.isLoading = true;

    this.table.options.page.size = e.pageSize;
    this.table.options.page.current = e.pageIndex;

    this.onChange.emit({
      type: TablePayloadType.Next,
      payload: {...this.table.options},
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hide_export = false;
    if (changes.table) {
      this.table.options = changes.table.currentValue.options;
    }
    if (changes.datasource) {
      this.dataSource = new MatTableDataSource<IBatch>(
        changes.datasource.currentValue
      );

      this.isLoading = changes.table && changes.table.firstChange;

      this.displayedColumns = [

        "bid",
        ...this.columns.map((o: any) => o.id),
        "actions",

      ];
    }
  }

  async exportWorkspace() {
    const notice = await this.bs.exportWorkspace(this.table.options.page);
    this.hide_export = true;
    this._is.interfaceBS.next({
      type: InterfacePayloadType.SetNotice,
      payload: notice,
    });
  }
  ngAfterViewInit() {

    this._userRole =  this._auth.getClaim(Claim.Role);
    const that = this;
    this._role = this._auth.getClaim(Claim.Role);
    this.dataSource.sort = this.sort;
    this._is.interface.subscribe((data: InterfacePayload) => {
      if (!data) {
        return;
      }
      switch (data.type) {
        case InterfacePayloadType.Loading:
          this.isLoading = !this.isLoading;
          break;
        case InterfacePayloadType.Keyword:
          this.isLoading = true;
          break;
      }
    });

    this.wrapperEle.nativeElement.addEventListener('scroll', (evt: MouseEvent) => {

      if (!evt.target) {
        return;
      }
      const target: any = evt.target;
      const thead: HTMLElement = target.querySelector('thead');
      thead.style.zIndex = "8";
      thead.style.width = "100%";
      thead.style.position = "absolute";
      thead.style.top = target.scrollTop + "px";
    });


  }
}
