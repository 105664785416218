import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
  TableAction,
  TablePagerLocation,
  TableColumn,
  TableColumnType,
} from "src/app/elements/table/types";
import {
  AccountService,
  InterfaceService,
  MessageService,
} from "src/app/services";
import { Role, MessageType } from "src/app/models";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { InterfacePayloadType } from "src/app/services/interface.service";
import { first } from "rxjs/operators";
import { MustMatch, HasConflict } from "src/app/helpers/util";
import { FormsModel } from "src/app/models/forms";
import { CreateAccountRequest } from "src/app/models/account";

@Component({
  selector: "view-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.css"],
})
export class AccountsComponent implements OnInit {
  constructor(
    private _ms: MessageService,
    private _as: AccountService,
    private _is: InterfaceService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  loaded = false;

  interfaceSubscriber: Subscription = new Subscription();

  forms: FormsModel = {
    create_account: {
      form: this.fb.group({}),
      fields: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
    },
    delete_account: {
      form: this.fb.group({}),
      fields: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
    },
  };
  account_table: TableData = {
    data: [],
    options: {
      layout: {
        pagerLocation: TablePagerLocation.Top,
        header: true,
        footer: false,
        hideActions: false,
      },
      columns: [
        { id: "accountName", label: "Account Name" },
        { id: "username", label: "Username" },
        { id: "email", label: "Email" },
        { id: "status", label: "Status", type: TableColumnType.Status },
      ],
      groups: [
        { id: Role.Alpha, label: Role.Alpha },
        { id: Role.Manager, label: Role.Manager },
        { id: Role.Clerk, label: Role.Clerk },
        { id: Role.Supplier, label: Role.Supplier },
        { id: Role.Importer, label: Role.Importer },
        { id: Role.Trucker, label: Role.Trucker },
      ],
      page: {
        orderBy: {
          column: "name",
          direction: TableOrderByDirection.ASC,
        },
        groupBy: Role.Alpha,
        filter: "",
        current: 1,
        size: 50,
      },
    },
  };

  tableActions: TableAction[] = [
    {
      onClick: true,
      name: "edit",
      icon: "icon-edit",
      function: this.editAccount,
      router: this.router,
      scope: this,
    },
    {
      onClick: false,
      name: "delete",
      icon: "icon-trash",
      function: this.deleteAccount,
      router: this.router,
      scope: this,
    },
  ];

  conflict: any = {
    customer_id: false,
    email: false,
  };

  editAccount(event: any, item: { aid: any }) {
    const that: any = this;
    that.router.navigate(["dashboard", "account", item.aid]);
  }

  account_deletion_state: { confirm: boolean; active: any | null } = {
    confirm: false,
    active: null,
  };

  toggleDeleteAccount() {
    this._is.toggle({ type: "delete" });
    if (!this.forms.delete_account.toggled) {
      this.account_deletion_state.active = null;
    }
  }

  deleteAccountConfirmation() {
    if (
      this.forms.delete_account.toggled &&
      this.account_deletion_state.active &&
      this.account_deletion_state.confirm
    ) {
      this._as
        .deleteAccount(this.account_deletion_state.active.aid)
        .then((res) => {
          this._ms.set({
            message: `${res.message}`,
            type: MessageType.Success,
            delay: 2500,
          });
          this.reloadTable();
        })
        .catch((err) => {
          this._ms.set({
            message: `${err.message}`,
            type: MessageType.Error,
            delay: 2500,
          });
        });

      this.account_deletion_state.active = null;
      this.account_deletion_state.confirm = false;
      this.forms.delete_account.toggled = false;
    }
  }
  deleteAccountConfirmToggle() {
    this.account_deletion_state.confirm = !this.account_deletion_state.confirm;
  }
  deleteAccount(
    event: any,
    item: any,
    scope: {
      router?: any;
      _as: AccountService;
      _ms: MessageService;
      _is: InterfaceService;
      forms: FormsModel;
      role: Role;
      account_deletion_state: any;
      reloadTable: any;
    }
  ) {
    scope._is.toggle({ type: "delete" });

    scope.account_deletion_state.active = item;
    scope.forms.delete_account.toggled = true;
  }

  get accountFields() {
    if (this.forms.create_account.form)
      return this.forms.create_account.form.controls;
  }

  onSubmit() {
    this.forms.create_account.submitted = true;

    if (this.forms.create_account.form?.invalid) {
      return;
    }

    this.forms.create_account.loading = true;

    this._as
      .createAccount(this.forms.create_account.form?.getRawValue())
      .pipe(first())
      .subscribe(
        (response) => {
          this._ms.set({
            message: `${response.username} created!`,
            type: MessageType.Success,
            delay: 2500,
          });

          this.toggleCreateAccount();
          this.forms.create_account.form?.reset();
          this.setForms();
          this.reloadTable();
        },
        (error) => {
          switch (error.conflict) {
            case "email":
              this.conflict.email = true;
              this.forms.create_account.form.controls["email"].setErrors({
                conflict: this.conflict.email,
              });
              break;
            case "customer_id":
              this.conflict.customer_id = true;
              this.forms.create_account.form.controls["customer_id"].setErrors({
                conflict: this.conflict.customer_id,
              });
              break;
            case "customer_id_email":
              this.conflict.email = true;
              this.conflict.customer_id = true;
              this.forms.create_account.form.controls["customer_id"].setErrors({
                conflict: this.conflict.customer_id,
              });
              this.forms.create_account.form.controls["email"].setErrors({
                conflict: this.conflict.email,
              });
              break;
          }

          this._ms.set({
            message: error.message,
            type: MessageType.Error,
            delay: 2500,
          });

          this.forms.create_account.error = error;
          this.forms.create_account.loading = false;
        }
      );
  }

  ngOnInit() {

    this.interfaceSubscriber = this._is.interface.subscribe(
      async (_interface) => {
        if (_interface){
	    switch (_interface.type) {
		case InterfacePayloadType.CTA:
		    if (_interface.payload.type == "create") {
			this.forms.create_account.toggled = _interface.payload.toggled;
			if (this.forms.create_account.toggled) {
			    this.forms.create_account.roles = await this._as.getRoles() || [];
			}
		    }
		    if (_interface.payload.type == "delete") {
			this.forms.delete_account.toggled = _interface.payload.toggled;
		    }
		    break;
	    }
	}
      }
    );

    this.setForms();

  }

  setForms() {
    this.forms.create_account.form = this.fb.group(
      {
        customer_id: ["", Validators.required],
        role: ["", Validators.required],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$"
            ),
          ],
        ],
        password: ["", Validators.required],
        confirm_password: ["", Validators.required],
      },
      {
        validator: [
          MustMatch("password", "confirm_password"),
          HasConflict("customer_id", this.conflict.username),
          HasConflict("email", this.conflict.email),
        ],
      }
    );
  }

  toggleCreateAccount() {
    this._is.toggle({ type: "create" });
  }

  async onTableChange(event: OnTableChangePayload) {
    if (
      event.type == TablePayloadType.Next ||
      event.type == TablePayloadType.Previous
    ) {
      if (this.loaded) {
        this.account_table.options.page.current =
          event.type == TablePayloadType.Next
            ? event.payload.page.current + 1
            : event.payload.page.current - 1;
      }
    }

    this.loaded = true;

    this.reloadTable();
  }

  async reloadTable() {
    const payload = await this._as.getAll(this.account_table.options.page);
    if(payload){
	this.account_table.data = payload.data;
	this.account_table.options.page = payload.options.page;
    }
    this._is.interfaceBS.next({type: InterfacePayloadType.Loading});

  }
}
