<section [ngClass]="{'active': states.panel}">
  <div class="content">
    <div class="loader" [ngClass]="{active: isLoading}">
      <img alt="loading" src="../../../assets/branding/loader.gif">
    </div>

    <p class="notice"><b>Quick Edit: </b><b>{{selection.length}}</b> batch{{(selection.length > 1) ? "es" : ""}}
      selected</p>
    <div *ngIf="selection.length == 1 && (current_role == Role.Alpha || current_role == Role.Clerk ||  current_role == Role.Manager)" class="field__group">
      <div class="multi_selector__wrapper">
        <label>Tags</label>
        <div class="info"><i class="icon-info"></i>&nbsp;<p>Press comma, enter or tab to add the entry below.</p></div>
        <input type="text" #input autocomplete="off" (keyup)="keyup($event)" (keydown)="keydown($event)"
               [placeholder]="(values.length > 0)? 'Add another tag': 'Add a tag'">
        <div class="multi_selector_values scrollbar__">
          <span *ngFor="let value of values">{{value}}<i class="icon-remove" (click)="remove(value)"></i></span>
        </div>
      </div>
    </div>

    <div class="field__group" *ngIf="(current_role == Role.Alpha || current_role == Role.Clerk ||  current_role == Role.Manager)">
      <label>Status</label>
      <div class="field__input">
        <element-dropdown-filter [options]="statusSettings" [data]="status"
                                 (change)="filterChange($event)"></element-dropdown-filter>
      </div>
    </div>
    <div class="field__group" *ngIf="(current_role == Role.Alpha || current_role == Role.Clerk ||  current_role == Role.Manager)">
      <label>ETA</label>
      <div class="field__input" style="padding: 1em 1em 0 1em;">
        <view-date-picker [is_range]="false" (change)="filterChange($event)"/>
      </div>
    </div>

    <div class="field__group">
      <label>Export</label>
      <div class="field__input">
        <button class="button__ outline" (click)="exportSelection($event)">Export {{selection.length}}
          batch{{(selection.length > 1) ? "es" : ""}} </button>
      </div>
    </div>
  </div>
  <div class="filter_actions">
    <button class="button__ " (click)="trigger()">Cancel</button>
    <button class="button__ primary" (click)="onUpdate($event)">Update</button>
  </div>
</section>
<div [ngClass]="{'active': states.panel }" class="overlay" (click)="trigger()">

</div>
