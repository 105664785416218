import {Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges} from "@angular/core";
import {FormatDate} from "src/app/helpers/util";
import {Batch, BatchService} from "src/app/services";
import {BatchType} from "src/app/services/batch.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "element-batch-history",
  templateUrl: "./batch-history.component.html",
  styleUrls: ["./batch-history.component.scss"],
})
export class BatchHistoryComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(private _bs: BatchService, private route: ActivatedRoute) {
  }

  @Input() toggled = false;
  @Input() batch: Batch | null = null;

  FormatDate = FormatDate;

  history: IHistory[] = [];

  @Input() showBanner = true;

  hasRecentError = false;
  issueNotice = false;
  isIncomplete = false;

  toggle() {
    this.toggled = !this.toggled;
    this.issueNotice = false;
  }


  async ngAfterViewInit() {
  }


  async ngOnChanges(changes: SimpleChanges) {
    if (changes.batch && !changes.batch.isFirstChange()) {
      const batch = structuredClone(changes.batch.currentValue);

      this.history = [];

      if (batch && batch.parentBID) {
        const history = await this._bs.getBatchHistory(batch.parentBID);
        if (history) {
          this.history = (history).map((h) => {
            return {...h, createdOn: FormatDate(new Date(h.createdOn))};
          });
        }
      }

      if (
        this.history.length > 0 &&
        (this.history[0].type == "Issue" ||
          this.history[0].type == "Incomplete")
      ) {
        this.hasRecentError = true;
        this.issueNotice = true;
        if (this.history[0].type == "Incomplete") {
          this.isIncomplete = true;
        }
      }

      if (batch && batch.status == BatchType.Issue) {
        this.hasRecentError = true;
        this.issueNotice = true;
      }
    }
  }

  async ngOnInit() {
  }
}

export interface IHistory {
  message: string;
  createdOn: string;
  type?: string;
}
