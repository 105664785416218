import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { OnChangePayload, TypeEnum } from 'src/app/models';

@Component({
  selector: 'element-state-toggler',
  templateUrl: './state-toggler.component.html',
  styleUrls: ['./state-toggler.component.scss']
})
export class StateTogglerComponent implements OnInit {

  constructor() {}
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter();

  @Input() option: StateToggleOption = {
    group:'courier_type',
    data: [{
      value: '0',
      label: 'bol',
      icon: 'icon-freight-outline',
      color:'#4056b6',
      textColor:'#ffffff'

    },
    {
      value: '',
      label: 'na',
      icon:'icon-remove'
    },
    {
      value: '1',
      label: 'awb',
      icon: 'icon-plane-outline',
      color:'#559765',
      textColor:'#ffffff'
    }],
    selected: {
      id:1,
      value: '', 
      label: 'na',
    },
  };

  @Input() selected:string = '';

  @Input()
  selected_value: StateToggleData | undefined;

  changeState(data:StateToggleData){
    this.option.selected = data
    this.change.emit({
      type: TypeEnum.StateToggler,
      payload: {
        search: this.option.selected.value,
        type: "select",
        id: 'courier_type',
      },
    });
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      if(this.selected_value){
        this.changeState(this.selected_value)
        const auto_select =  this.option.data?.find((v,i)=> v.value == String(this.selected_value?.value));
        if(auto_select){
          this.option.selected =auto_select ;
        }
      }
    },500)
  }

  ngOnInit(): void {
    const non_immutable_option = Object.assign({}, this.option);
    if(this.option.data){
      for (let index = 0; index < this.option.data.length; index++) {
        const element =  this.option.data[index];
        element.id = index;
      }

      const selected = this.option.data.find((v,i)=> v.value == parseInt(this.selected));
      if(selected){
        this.option.selected = selected;
      }


    
    }

  }


}

export interface StateToggleOption {
  group:string;
  selected: StateToggleData;
  data ? : StateToggleData[],
}

export interface StateToggleData {
    id?: any,
    value: any,
    label: string,
    icon?: string,
    color?:string,
    textColor?:string
}
