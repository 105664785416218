import { Component, OnInit, OnDestroy } from "@angular/core";
import { EmailService, FilterService } from "src/app/services";
import { TableData, TableOrderByDirection } from "../table/types";
import { Role } from "src/app/models";
import { FormatDate, setDateTimezone } from "src/app/helpers/util";

@Component({
  selector: "element-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent implements OnInit, OnDestroy {
  constructor(private _es: EmailService, private _fs: FilterService) {}
  loadMore = false;
  FormatDate = FormatDate;
  isDownloadingAll = false;
  currentDownloadAll: string[] = [];
  active: any;
  filter = "Recent";

  readDelayModifier: any = setTimeout(() => {
    if (this.active){ this.active.read = true; }
  }, 2000);

  options: TableData = {
    data: [],
    options: {
      page: {
        orderBy: {
          column: "desc",
          direction: TableOrderByDirection.ASC,
        },
        filter: "Recent",
        current: 1,
        size: 25,
      },
    },
  };

  queryInterval: any = null;

  setActive(email: any) {
    this.active = email;
    clearTimeout(this.readDelayModifier);
    this.readDelayModifier = setTimeout(async () => {
      this.active.read = true;
      await this._es.read(this.active.eid);
    }, 2000);
  }

  async orderBy(_orderBy: any, filter: string) {
    this.options.options.page.current = 1;
    this.options.options.page.filter = filter;
    this.query();
  }

  async loadMoreEmails() {
    this.options.options.page.current = this.options.options.page.current + 1;
    this.query(true);
  }

  ngOnDestroy() {
    if (this.queryInterval) {
      clearInterval(this.queryInterval);
    }
  }

  async ngOnInit() {
    this.queryInterval = setInterval(() => {
      this.getByDate();
    }, 30000);

    this.query();

    this._fs.filter.subscribe((res) => {
      if (res) {
        this.options.options.page.filter = res;
        this.query();
      }
    });
  }

  downloadAttachment(eid: string, attachment: any) {
    attachment.isDownloading = true;
    this._es
      .downloadAttachment(eid, attachment.attachmentID)
      .subscribe((data:any) => {
        const blob = new Blob([data], { type: attachment.contentType });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = attachment.name;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

        attachment.isDownloading = false;
      });
  }

  downloadAttachments(eid: string) {
    this.currentDownloadAll.push(eid);
    this.isDownloadingAll = true;
    this._es.downloadAttachments(eid).subscribe((data:any) => {
      const blob = new Blob([data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = this.active.subject + ".zip";
      a.click();

      window.URL.revokeObjectURL(url);
      a.remove();
      this.isDownloadingAll = true;
      this.currentDownloadAll = this.currentDownloadAll.filter((e) => e != eid);

      this.isDownloadingAll = false;
    });
  }

  async getByDate() {
    if (this.options.data.length > 0) {
      const emails: any[] = await this._es.getByDate(
        this.options.data[0]._receivedOn
      ) || [];

      if (emails) {
        const emails_list = emails.map((i: any) => {
          return {
            ...i,
            receivedOn: FormatDate(new Date(i.receivedOn), true),
            _receivedOn: i.receivedOn,
          };
        });

        this.options.data = [...emails_list, ...this.options.data];
      }
    }
  }
  async query(loadingMore: boolean = false) {
    const emails = await this._es.get(this.options.options.page);

    if (emails && emails.data) {
	const emails_list = emails.data.map((i: any) => {
	    return {
		...i,
		receivedOn: FormatDate(new Date(i.receivedOn), true),
		_receivedOn: i.receivedOn,
	    };
	});
	if (loadingMore) {
	    this.options.data = [...this.options.data, ...emails_list];
	} else {
	    this.options.data = emails_list;
	}
	this.options.options = emails.options;
	this.loadMore = false;
    }
  }
}
