import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { TablePageOptions, TableData } from "../elements/table/types";
import {
  CreateAccountRequest,
  AccountAssociationRequest,
} from "../models/account";
import { Log } from "../utilities";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private http: HttpClient) {}

  async getAll(pageOptions: TablePageOptions) {
    return await this.http
      .post<TableData>("account/list", { page: pageOptions })
      .toPromise();
  }

  createAccount(account: CreateAccountRequest) {
    return this.http.post<any>("account/create", account);
  }

  deleteAccount(aid: string) {
    return this.http.delete<any>("account/" + aid).toPromise();
  }

  async getRoles() {
    return await this.http.get<any[]>("account/roles").toPromise();
  }

  async getAccount(aid: string) {
    return await this.http.get(`account/${aid}`).toPromise();
  }

  async getMyAccount() {
    return await this.http.get(`account/me`).toPromise();
  }

  async updateMyAccount(data: any) {
    return await this.http.put(`account/me`, data).toPromise();
  }

  async getAccountsByRole(role: string, filter: string) {
    return await this.http
      .post(`account/by-role`, { role: role, filter: filter })
      .toPromise();
  }

  async getAccountAssociationsByRole(
    role: string,
    filter: string,
    aid?: string
  ) {
    const query =
      aid != null
        ? `account/assocations/by-role/${aid}`
        : `account/assocations/by-role/`;

    return await this.http
      .post(query, {
        role: role,
        filter: filter,
      })
      .toPromise();
  }

  async updateAccount(aid: string | null, data: any) {
    if (aid) return await this.http.put(`account/${aid}`, data).toPromise();
  }

  addAccountAssociation(account: AccountAssociationRequest) {
    return this.http.post<any>("account/associate", account).toPromise();
  }

  remvoeAccountAssociation(account: AccountAssociationRequest) {
    return this.http
      .delete<any>(`account/associate/${account.aaid}`)
      .toPromise();
  }

  async getAccountAssociations(aid: string, pageOptions: TablePageOptions) {
    return await this.http
      .post<TableData>("account/associate/list", {
        aid: aid,
        page: pageOptions,
      })
      .toPromise();
  }
}
