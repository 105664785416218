import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ɵConsole,
} from "@angular/core";
import { OnChangePayload, TypeEnum } from "../../models";

@Component({
  selector: "element-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent {
  @Input() disableToggle: boolean = false;

  @Input() data: any = [];
  @Input() autofocus: boolean = false;
  @Input() placeholder: any = "Enter name of author";
  //@ts-ignore
  @Output() onChange: EventEmitter<OnChangePayload> = new EventEmitter(null);
  //@ts-ignore
  @ViewChild("viewpoint") viewpoint: ElementRef;
  //@ts-ignore
  @ViewChild("input") input: ElementRef;

  highlighted: number = 0;

  search: string = "";
  active: boolean = false;

  ngAfterViewInit() {
    if(this.autofocus){
      this.input.nativeElement.focus();
    }
  }
  /**
   * Triggers onChange EventEmitter and resets states.
   * @param item
   */
  onSelect(item: any) {
    if (item && this.search != "") {
      this.toggle();

      this.onChange.emit({
        type: TypeEnum.Select,
        payload: Object.assign(item),
      });

      this.search = "";
      this.highlighted = -1;
    }
  }

  /**
   * Toggles autocomplete component active state.
   */
  toggle() {
    this.active = !this.active;
    if (this.active) this.input.nativeElement.focus();
  }

  /**
   * Navigates autocomplete component filtered list.
   * @param evt:KeyboardEvent
   */
  keyDownEvents(evt: KeyboardEvent) {
    switch (evt.keyCode) {
      case 9:
        if (this.data[this.highlighted]) {
          this.onSelect(this.data[this.highlighted]);
        }
        break;
    }
  }
  keyEvents(evt: KeyboardEvent) {
    evt.preventDefault();
    switch (evt.keyCode) {
      case 38:
        this.highlighted =
          this.highlighted == 0 ? this.data.length - 1 : this.highlighted - 1;
        break;
      case 40:
        this.highlighted =
          this.highlighted == this.data.length - 1 ? 0 : this.highlighted + 1;
        break;
      case 13:
        this.onSelect(this.data[this.highlighted]);
        break;
      default:
        this.onChange.emit({ type: TypeEnum.Filter, payload: this.search });
        break;
    }

    if (this.viewpoint) {
      const wrapper: HTMLDivElement = this.viewpoint.nativeElement;
      const activeItem: HTMLDivElement | null = wrapper.querySelector(
        ".autocomplete__item.active"
      );
      const scrollOffset =
        activeItem && this.highlighted > 3
          ? activeItem.offsetTop - activeItem.offsetHeight
          : 0;
      wrapper.scrollTo({ top: scrollOffset });
    }
  }
}
