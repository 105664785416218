import { Injectable } from "@angular/core";
import { Message } from "../models";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor() {}

  messageBS: BehaviorSubject<Message | any> = new BehaviorSubject(null);
  message: Observable<Message> = this.messageBS.asObservable();

  set(message: Message) {
    this.messageBS.next(message);
  }

  get() {
    return this.messageBS.getValue();
  }
}
