export const config = {
  // apiUrl: "https://staging.alphabrokers.com/api/",
  apiUrl: "https://platform.alphabrokers.com/api/",
  // apiUrl: "https://api.alpha.dev:5000/",
  enableRpc: true,
  gc: "QWxwaGEgQnJva2VycyBDb3JwLiwxMzM0ODQ0NDkzODYxOTMjQjBMWVpZdVI0UXhoSFVFWkZUQko1TW9Ka1E1Y0hWd05HUlNSNWJMNW1ZRzNFV3hFSFJDcFVZekJ6VTdwR2RLdGlSOHhrVzRFNFNIVkhTMEp6YURKRE00a0RaU3A0VkhONlNzbDVWeDg0S1lsN1pNUkRWYVZ6WjdzeVY2VkhST2g1YTVONlFxSmpXS3hHYk5GVFN1SnpNcGhUVkVoVFFxRlZSaGQ2TUNsVlRhUkRWemM2YTZjRVVHWmxTSHBrWkg1V1lyTjRNVWxsUU1wR041aEZkck1HVzRaWFRybFZleDVFYjZWV1k2TkVaeVU3ZFFOV01qWmxZeHRtYXlabFdSbEVlRXA0TnRoVmQwdGlaekI3Yk5Wa1dodG1XNVFIWnhGN1Z2RkRSa0JuUXRKbVVuZEdiWUJWZXdSRU9qSjRidkpIVzVkNFM2RkhlVUZGVzZCVlZpb2pJVEpDTGlJa1FDZFROOEl6TmlvaklJSkNMeGtqTTlVak02UWpOMElpY2ZKeWUzNVhYM0pTV1hOVVRpb2pJREpDTGlFalZnSVhaN1ZXYVdWNlpoNVdTamRrSTBJaVRpc0hMM0pDV2FsVlJpb2pJREpDTGlRalZnSVhaN1ZXYVdaR1pRTjZSaW9qSU9KeWVzNG5JU2hFU0VKaU9pTWtJc0lTTTZCaWNsZFhacFpWWTRGR1JqZGtJMElpVGlzN1cwSUNaeUJsSXNJQ081WXpNeUFESXdNVE53TWpNd0lqSTBJQ2R5TmtJc0lpTHdKN2JEQnljeVY2YXZKblFnRUdhd3hXUWlvakloOTRRaXdpSXprVE02Z3pNOVFETjRnRE56TVRNaW9qSWtsa0kxcGpJRUpDTGk0VFA3cG1hbHhXTzlnNEtpbDViS1I3TTR4a2NhWmpXamh6YzZSVmE5WjVWRHA2TkgzR2RVQmpRWWRFT3pNVlQ3SUZhck5sVWo5NGNtbDRZeUFsYjQ1R1NPdGtWaVpHYTlrbFc1M0daeVE3Vk9SblVaQkZTdDlXTUpkVVQ=",
  rpcUrl: "https://rpc.alphabrokers.com",
  // rpcUrl: "https://127.0.0.1:32792",
  // apiUrl: "https://127.0.0.1:5000/",
  trackingID: "alpha112889"
};
