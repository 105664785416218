import { Injectable } from '@angular/core';
import {GatewayRpcService, Namespace, Version} from "./gateway.rpc.service";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationRpcService {

  constructor(private rpc: GatewayRpcService) {

  }

  async authenticate(username: string, password:string):Promise<AuthenticationResponse|any>{
    try
    {

      return await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Identity , action: IdentityActions.Authenticate, data: {username, password}}
      , false);

    }catch (e){
      return e;
    }
  }

}

export interface AuthenticationResponse{
  username:string;
  token:string;
}

enum IdentityActions{
  Authenticate = "Authenticate"
}
