import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef, OnDestroy,
} from "@angular/core";
import {DocumentService} from "../../services/document.service";
import {Newid} from "src/app/helpers/util";
import {Subscription} from "rxjs";
import {OnChangePayload, TypeEnum, DropDownFilterData} from "src/app/models";
import {IDocument} from "src/app/services";
import {EmptyGuid} from "src/app/utilities";
import {DocumentRpcService} from "../../services/document.rpc.service";

@Component({
  selector: "element-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
})
export class UploadComponent implements OnInit, OnDestroy {
  constructor(private _ds: DocumentService, private doc: DocumentRpcService) {
  }

  documentTypes: DropDownFilterData = [];
  EmptyGuid = EmptyGuid;

  //@ts-ignore
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

  @Input() queue: any = [];

  @Input() batchId: string | any = null;

  //@ts-ignore
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter(null);

  async ngOnDestroy() {
  }

  async ngOnInit() {
    this.documentTypes = await this.doc.getDocumentTypes();
    this.change.emit({type: TypeEnum.Uploader, payload: this.queue});
  }

  browseFiles() {
    const fileUpload = this.fileUpload.nativeElement;

    fileUpload.onchange = async () => {
      await this.onFileDrop(fileUpload.files);
    };

    fileUpload.click();
  }

  async openPreview(file: any) {
    const params =
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100`;

    open(`/document/${this.batchId}/${file.did}`, file.did.toString(), params);
  }

  async remove(file: any) {
    this.queue = this.queue.filter((f: IDocument) => f.did != file.did);
    this.change.emit({type: TypeEnum.Uploader, payload: this.queue});
  }

  async onChange(evt: OnChangePayload, doc: IDocument) {

    switch (evt.type) {
      case TypeEnum.DropDown:
        await this.doc.createUpdateDocument(this.batchId, doc.did, evt.payload.dtid);
        this.queue.find((f: IDocument) => f.did == doc.did).dtid = evt.payload.dtid;
        break;
    }

    this.change.emit({type: TypeEnum.Uploader, payload: this.queue});
  }

  async onFileDrop(files: FileList) {
    for (let x = 0; x < files.length; x++) {
      const tempID = Newid();

      const {name, size, type} = files[x];

      const current_upload = {did: tempID, name, size, type, progress: 0};

      this.queue.push(current_upload);

      try {
        this.doc.progress = (per) => {
          const doc = this.queue.find((f: IDocument) => f.did == tempID);
          if (doc && doc.progress) {
            doc.progress = per;
          }
          return per;
        };

        const response = await this.doc.upload(this.batchId, files[x]);

        const document = this.queue.find((q: IDocument) => q.did == tempID);

        if (document) {
          document.did = response.did;
        }

        this.change.emit({type: TypeEnum.Uploader, payload: this.queue});

      } catch (e) {
        console.log(e);
      }

    }
  }
}
