import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";

import {
  BatchService,
  AccountService,
  Batch,
  IDocument,
  DocumentService,
  AuthenticationService,
  MessageService,
} from "src/app/services";

import { ActivatedRoute, Router } from "@angular/router";
import { TableColumnType, TableColumn } from "src/app/elements/table/types";
import { FormatDate, Wait } from "src/app/helpers/util";
import {
  Status,
  BatchRevision,
  BatchType,
  IStatuses,
} from "src/app/services/batch.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { Role, MessageType } from "src/app/models";
import { Claim } from "src/app/models/account";

@Component({
  selector: "view-batch-details",
  templateUrl: "./batch-details.component.html",
  styleUrls: ["./batch-details.component.scss"],
})
export class BatchDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _ds: DocumentService,
    private _as: AuthenticationService,
    private _bs: BatchService,
    private _ms: MessageService
  ) {}

  Role = Role;
  BatchType = BatchType;
  column: TableColumn = {
    id: "",
    type: TableColumnType.MoreInfo,
    label: "Order Number",
  };
  tag_column: TableColumn = {
    id: "",
    type: TableColumnType.MoreInfo,
    label: "Tag",
  };
  awbFormat: TableColumn = {
    id: "",
    type: TableColumnType.General,
    label: "AWB",
  };
  id: string = "";
  batch_issue: string | null = null;
  batch: Batch = {
    associations: [],
    orderNumber: [],
    awbBolNumber: "",
    status: 0,
    statusLabel: "",
    documents: [],
    documentsList: [],
  };

  revisions: BatchRevision[] = [];

  status_form: UntypedFormGroup = this.fb.group({
    status: ["", [Validators.required]],
    gov_status: ["", [Validators.required]],
  });

  document: IDocument | null = null;

  states = {
    revision: false,
    print_all: false,
    print_all_pending: false,
    current: {
      preview: "",
      direct: "",
    },
    menus: {
      status: false,
      revision: false,
    },
  };

  statuses: IStatuses | undefined;
  //@ts-ignore
  selected_status: number = null;
  selected_status_label: string | undefined = "";
  gov_statuses: Status[] = [];

  disableStatus: boolean = true;

  role = this._as.getClaim(Claim.Role);

  openedTimer: any = null;
  _column = {};

  ngOnDestroy() {
    if (this.openedTimer) {
      clearInterval(this.openedTimer);
    }
  }

  editBatch() {
    this.router.navigate(["dashboard", "batch", "revise", this.batch.bid]);
  }

  async ngOnInit() {
    this.route.params.subscribe(async (draft) => {
      if (draft.bid) {
        this.id = draft.bid;

        const batch = await this._bs.getBatch(draft.bid);

	if(batch){
	    this.batch = batch;
	}else{
	    this._ms.set({message:'Invalid Batch', type: MessageType.Error, delay: 2000});
	    this.router.navigate(['dashboard','batches']);
	}

	this.selected_status = this.batch.status;
        this.selected_status_label = this.batch.statusLabel;

        this.statuses = await this._bs.getBatchStatus(this.id);
	const revisions = await this._bs.getBatchRevisions(draft.bid);
	if(revisions){
	    this.revisions = revisions.map(
		(s) => {
		    return { ...s, updatedOn: FormatDate(new Date(s.updatedOn)) };
		}
	    );
	}

        this.batch.documents = Object.assign(this.batch?.documentsList);

        if (this.batch.updatedOn) {
          this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
        }

        if (this.batch.documents.length > 0) {
          setTimeout(() => {
            this.selectDocument(this.batch.documents[0]);
          }, 500);
        }

        this.checkPrintAllPending();

        // if (
        //   (this.role == Role.Alpha ||
        //     this.role == Role.Clerk ||
        //     this.role == Role.Manager) &&
        //   this.selected_status == BatchType.Created
        // ) {
        //   this.openedTimer = setTimeout(async () => {
        //     await this._bs.updateBatchStatus(this.id, BatchType.Opened);
        //   }, 5000);
        // }

        if (
          this.role == Role.Trucker &&
          this.selected_status == BatchType.Created
        ) {
          this.openedTimer = setTimeout(async () => {
            await this._bs.updateBatchStatus(this.id, BatchType.Opened);
          }, 5000);
        }

        if (!this.batch.isRead) {
          this._bs.markBatchAsRead(this.id);
        }
      }
    });
  }

  async checkPrintAllPending() {
    if (this.batch.bid) {
      const _printALlTask = await this._bs.getPendingBatchTask(
        "DocumentPrintAll",
        this.batch.parentBID ? this.batch.parentBID : this.batch.bid
      );
      if (_printALlTask && _printALlTask.completedOn == null) {
        this.states.print_all_pending = true;
        await Wait(5);
        this.checkPrintAllPending();
      } else {
        this.states.print_all_pending = false;
      }
    }
  }

  toggleStatusMenu() {
    this.states.menus.status = !this.states.menus.status;
  }

  toggleRevisionMenu() {
    this.states.menus.revision = !this.states.menus.revision;
  }

  async onRevisionSelect(batchId: string, index: number = 0) {
    this.states.revision = index == 0 ? false : true;
    const batch =await this._bs.getBatchRevision(batchId);
    if(batch){
	this.batch = batch;
	this.batch.documents = Object.assign(this.batch?.documentsList);
	if (this.batch?.updatedOn) {
	    this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
	}
    }
    this.toggleRevisionMenu();
  }

  async setRevision() {
    if (this.batch && this.batch.bid) {
      const revision = await this._bs.setBatchRevision(this.batch.bid);

      if (revision?.bid) {
        const batch = await this._bs.getBatch(revision.bid);
        if(batch){

	    this.batch.documents = Object.assign(this.batch?.documentsList);

	    if (this.batch?.updatedOn) {
		this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
	    }

	    const revisions =await this._bs.getBatchRevisions(this.id) || [];

	    this.revisions = revisions.map(
		(s) => {
		    return { ...s, updatedOn: FormatDate(new Date(s.updatedOn)) };
		}
	    );
	}

        this.states.revision = false;
      }
    }
  }

  async selectDocument(doc: IDocument) {
    this.document = doc;
    const token = await this._ds.getDocumentToken(doc.did);
    setTimeout(() => {
      const link = this._ds.getDocumentDownloadLink(token?.dtid);
      this.states.current.preview = `https://docs.google.com/viewer?url=${encodeURIComponent(
        link
      )}&embedded=true`;
      this.states.current.direct = link;
    }, 500);
  }

  downloadPreview() {
    if (this.document?.fileExtension) {
      let type:
        | "pdf"
        | "html"
        | "image"
        | "json"
        | "raw-html"
        | undefined;

      switch (this.document?.fileExtension) {
        case "jpg" || "jpeg" || "png" || "gif":
          type = "image";
          break;
        case "htm" || "html":
          type = "html";
          break;
        case "pdf":
          type = "pdf";
          break;
      }

      if (type == undefined) {
        window.open(this.states.current.direct, "_blank");
      } else {
        // printJS(this.states.current.direct, type);
      }
    }
  }

  async downloadBatch() {
    if (this.batch?.bid) {
      this.states.print_all = true;
      const doc = await this._ds.printAll(this.batch?.bid);
      // printJS({ printable: doc.stream, type: "pdf", base64: true });
      this.states.print_all = false;
    }
  }

  async onSubmit() {
    if (this.batch.bid) {
      await this._bs
        .updateBatchStatuses({
          bid: this.batch.bid,
          statuses: this.selected_status,
          message: this.batch_issue,
        })
        .then((_res) => {
          this._ms.set({
            type: MessageType.Success,
            message: "Batch has been updated.",
            delay: 2500,
          });
        })
        .catch((err: { message: any }) => {
          this._ms.set({
            type: MessageType.Error,
            message: err.message,
            delay: 2500,
          });
        });
    }
  }
}
