import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "element-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.scss"],
})
export class DocumentComponent implements OnInit {
  constructor() {}
  @Input() batchId: string = "";

  ngOnInit(): void {}
}
