import {Component, OnInit} from "@angular/core";
import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
  TableAction,
  TablePagerLocation,
  TableColumnType,
  TableOptions,
  TableColumn,
} from "src/app/elements/table/types";
import {BatchService, Batch} from "src/app/services/batch.service";
import {Router} from "@angular/router";
import {Role, MessageType} from "src/app/models";
import {
  MessageService,
  AuthenticationService,
  InterfaceService,
  WorkspaceService,
} from "src/app/services";
import {Claim} from "src/app/models/account";
import {Subscription} from "rxjs";
import {FormsModel} from "src/app/models/forms";
import {UntypedFormBuilder} from "@angular/forms";
import {InterfacePayloadType} from "src/app/services/interface.service";
import {config} from "src/app/app.config";
import {Column, Filter, FilterOptions} from "src/app/elements/list-filter/list-filter.settings";
import {Wait} from "src/app/helpers/util";
import {DocumentRpcService} from "../../services/document.rpc.service";

@Component({
  selector: "view-batches",
  templateUrl: "./batches.component.html",
  styleUrls: ["./batches.component.css"],
})
export class BatchesComponent implements OnInit {
  constructor(
    private bs: BatchService,
    private fb: UntypedFormBuilder,
    private _is: InterfaceService,
    private router: Router,
    private _ms: MessageService,
    private _auth: AuthenticationService,
    private _ws: WorkspaceService,
    private doc: DocumentRpcService
  ) {
  }

  loaded: boolean = false;
  batch_table: TableData = {
    data: [],
    options: {
      layout: {
        header: true,
        footer: false,
        pagerLocation: TablePagerLocation.Top,
      },
      columns: [],
      groups: [
        {id: 'Bookmarked', label: "<i class='icon-bookmark'></i>", tabWidth: 45},
        {id: 'Add', label: "<i class='icon-add'></i>", tabWidth: 45, bgColor: '#f2f2f2', textColor: '#000'}
      ],
      page: {
        orderBy: {
          column: "updated_on",
          direction: TableOrderByDirection.DESC,
        },
        groupBy: Role.Supplier,
        filter: null,

        current: 1,
        size: 25,
      },
    },
  };

  tableActions: TableAction[] = [
    {
      onClick: false,
      name: "discard",
      icon: "icon-trash",
      function: this.toggleDraftDiscard,
      scope: this,
      hideCondition: this.discardHideConditions,
    },
    {
      onClick: false,
      name: "revise",
      icon: "icon-revision_edit",
      function: this.reviseBatch,
      scope: this,
      hideCondition: this.editHideConditions
    },
    {
      onClick: true,
      name: "view",
      icon: "icon-eye",
      function: this.viewBatch,
      scope: this,
      hideCondition: () => true,
    },
  ];

  interfaceSubscriber: Subscription = new Subscription();
  IFilterSubscriber: Subscription = new Subscription();

  forms: FormsModel = {
    discard_draft: {
      form: this.fb.group({}),
      fields: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
    },
  };

  batch_discard_state: { confirm: boolean; active: any, status: any } = {
    confirm: false,
    active: null,
    status: null,
  };

  filter_toggles = false;

  //@ts-ignore
  role: Role;

  editHideConditions(item: any, scope: BatchesComponent) {
    return ((item.status == 'Created' || item.status == 'Created *' || item.status == 'Issue' || item.status == "Issue *" || item.status == 'Draft') && scope.role != Role.Trucker) || (scope.role == Role.Alpha || scope.role == Role.Clerk || scope.role == Role.Manager);
  }

  deleteHideConditions(item: any, scope: BatchesComponent) {
    return item.status == "Draft" || scope._auth.getClaim(Claim.Role) == Role.Alpha;
  }

  discardHideConditions(item: any, scope: BatchesComponent) {
    return item.status == "Draft" || scope._auth.getClaim(Claim.Role) == Role.Alpha;
  }

  _toggleDraftDiscard() {
    this._is.toggle({type: "delete"});
    if (!this.forms.discard_draft.toggled) {
      this.batch_discard_state.active = null;
    }
  }

  async bookmarkBatch(item: Batch, parentEle: HTMLElement) {
    if (item.bid) {
      const icon = parentEle.querySelector('i');
      if (icon) {
        const result = await this.bs.bookmarkBatch(item.bid);
        if (icon?.classList.contains('bookmark-active') && !result) {
          icon.classList.remove('bookmark-active');
        }
        if (!icon?.classList.contains('bookmark-active') && result) {
          icon.classList.add('bookmark-active');
        }
      }
    }
  }

  toggleDraftDiscard(
    event: {},
    item: Batch,
    scope: {
      router?: any;
      _is: InterfaceService;
      forms: any;
      batch_discard_state: any;
      _ms: MessageService;
      bs: BatchService;
      role: Role;
    }
  ) {

    scope.batch_discard_state.status = String(item.status);
    scope._is.toggle({type: "delete"});

    if (!scope.forms.discard_draft.toggled) {
      scope.batch_discard_state.active = null;
    } else {
      scope.batch_discard_state.active = item.bid;
    }
  }

  discardDraftConfirmation() {
    // console.log(this.forms.discard_draft, this.batch_discard_state);
    if (
      this.forms.discard_draft.toggled &&
      this.batch_discard_state.confirm &&
      this.batch_discard_state.active
    ) {

      if (this.batch_discard_state.status == "Draft") {
        this.discardDraft(this.batch_discard_state.active).then(async (res) => {
          const payload = await this.bs.getAll(this.batch_table.options.page);
          if (payload) {
            this.batch_table.data = payload.data;
            this.batch_table.options.page = payload.options.page;
          }
          this._is.toggle({type: "delete"});

          this.batch_discard_state.active = null;
          this.batch_discard_state.confirm = false;
          this.forms.discard_draft.toggled = false;
        });
      } else {
        this.deleteBatch(this.batch_discard_state.active).then(async (res) => {
          const payload = await this.bs.getAll(this.batch_table.options.page);
          if (payload) {
            this.batch_table.data = payload.data;
            this.batch_table.options.page = payload.options.page;
          }

          this._is.toggle({type: "delete"});

          this.batch_discard_state.active = null;
          this.batch_discard_state.confirm = false;
          this.forms.discard_draft.toggled = false;
        });

      }
    }
  }

  discardDraftConfirmToggle() {
    this.batch_discard_state.confirm = !this.batch_discard_state.confirm;
  }

  async deleteBatch(bid: string) {
    if (bid) {
      await this.bs.deleteBatch(bid);
    }
    this.router.navigate(["/dashboard/batches"]);
  }

  async discardDraft(bid: string) {
    if (bid) {
      await this.bs.discardDraft(bid);
    }
    this.router.navigate(["/dashboard/batches"]);
  }

  toggleFilter(active: boolean | null = null) {

    if (active != null) {
      this.filter_toggles = active;
    } else {
      this.filter_toggles = !this.filter_toggles;
    }

    this._is.interfaceBS.next({type: InterfacePayloadType.CreateWorkspaceToggle, payload: {toggled: true, is_new: true}});
  }

  onFilterColumnSetup(filter: FilterOptions, groupBy: string | null = null) {

    this.batch_table.options.page.filter = filter;
    this.batch_table.options.page.groupBy = filter.awid;

    this.setupFilterColumns(filter);

  }

  onGroupFilterClick(filter: FilterOptions, groupBy: string | null = null) {

    this.batch_table.options.page.filter = filter;
    this.batch_table.options.page.groupBy = filter.awid;

    localStorage.setItem('filters', JSON.stringify(this.batch_table.options));

    this.setupFilterColumns(filter);

    const payload = {
      type: 'on-tab-click',
      filter: filter,
      groupBy: (groupBy) ? groupBy : filter.awid,
    };

    this._is.interfaceBS.next({type: InterfacePayloadType.WorkspaceTabClick, payload});
  }

  async initListFilter(filters: FilterOptions[], filter: FilterOptions, payloadType: InterfacePayloadType = InterfacePayloadType.ListFilter) {
    if (filters) {
      filters = Object.assign(filters);

      this.batch_table.options.groups = [];

      for (let index = 0; index < filters.length; index++) {

        const _filter = filters[index];

        if (_filter.awid) {
          switch (_filter.name) {
            case "Bookmarked":
              this.batch_table.options.groups.push({id: 'bookmarked', label: "<i class='icon-bookmark'></i>", tabWidth: 45});
              break;
            default:
              this.batch_table.options.groups.push({
                id: _filter.awid,
                label: _filter.name,
                onClick: () => this.onGroupFilterClick(_filter)
              });
          }
        }

      }

      this.batch_table.options.groups?.push({
        id: 'Add', label: "<i class='icon-add'></i>", tabWidth: 45, bgColor: '#f2f2f2', textColor: '#000', onClick: () => {
          this.toggleFilter(true);
        }
      });
      if (Role.Alpha == this.role || Role.Clerk == this.role || Role.Manager == this.role) {
        this.batch_table.options.groups?.push({
          id: 'Messages',
          label: "<span class='material-symbols-outlined'>history_edu</span>",
          tabWidth: 45,
          bgColor: 'transparent',
          textColor: '#000',
          onClick: () => {

            const params =
              `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100`;

            open(`/document/asci/messages`, 'ASCI Messages', params);

            this._is.interfaceBS.next({type: InterfacePayloadType.Loading, payload: {}});

          }
        });
      }
      //Load filter stores in batch_table state groupBy
      if (!filter) {

        const stores_filter: FilterOptions | undefined = filters.find(c => c.awid == this.batch_table.options.page.groupBy);

        if (stores_filter) {
          filter = stores_filter;
        } else {
          if (filters.length > 0) {
            filter = filters[0];
          }
        }

      }

      if (filter && payloadType == InterfacePayloadType.ListFilter) {
        this.setupListFilter(filter, filters);
      } else {
        this.setupListFilter(filter, filters, false);
      }

    }

  }

  async setupListFilter(filter: FilterOptions, filters: FilterOptions[], init_group: boolean = true) {

    if (filter && init_group) {
      this.onGroupFilterClick(filter);
    }

    this.batch_table.options.page.filter = filter;

    if (this.batch_table.options.page.filter && filter && filters.length > 0) {

      this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

      const payload = await this.bs.getAll(this.batch_table.options.page);
      if (this.batch_table.data && payload?.data) {
        this.batch_table.data = payload.data;
        this.batch_table.options.page.filter = payload.options.page.filter;
      }

      this._is.interfaceBS.next({type: InterfacePayloadType.Loading});

    }

  }

  setupFilterColumns(filter: FilterOptions) {
    if (this.batch_table.options.columns) {

      this.batch_table.options.columns = [
        {
          id: "bookmarked",
          label: "<i class='icon-bookmark'></i>",
          type: TableColumnType.BookBookmark,
          width: 50,
          onClick: true,
          function: (item: Batch, parentEle: HTMLElement) => {
            this.bookmarkBatch(item, parentEle);
          }
        }
      ];

      for (let index = 0; index < filter.columns.length; index++) {

        const column: Column = filter.columns[index];

        const _column: TableColumn = {
          id: column.value,
          label: column.name
        };

        switch (column.value) {
          case "courier_type":
            _column.type = TableColumnType.AWBBOL;
            break;
          case "gov_status":
            _column.type = TableColumnType.GovStatuses;
            break;
          case "status":
            _column.type = TableColumnType.BatchStatus;
            break;
          case "order_number":
            _column.type = TableColumnType.MoreInfo;
            break;
          case "tags":
            _column.label = "Tag";
            _column.type = TableColumnType.MoreInfo;
            break;
          case "awb_bol_number":
            _column.type = TableColumnType.TrackerLink;
            _column.onClick = true;
            _column.function = (item: any, parentEle: HTMLElement) => {
              if (item.courier_type != undefined) {
                //@ts-ignore
                if (item.courier_type == 1) {
                  window.open(
                    "https://connect.track-trace.com/for/" + config.trackingID + "/aircargo/" + item.awb_bol_number, '_blank');
                }
                //@ts-ignore
                if (item.courier_type == 0) {
                  window.open(
                    "https://connect.track-trace.com/for/" + config.trackingID + "/billoflading/" + item.awb_bol_number,
                    "_blank");
                }
              }
            };
            break;
        }

        if (column.options?.table?.type) {
          _column.type = column.options.table.type;
        }

        if (column.options?.table?.onClick) {
          _column.onClick = true;
          _column.function = column.options.table.onClick;
        }

        this.batch_table.options.columns.push(_column);
      }
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.interfaceSubscriber.unsubscribe();
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {

    this.interfaceSubscriber = this._is.interface.subscribe(
      async (_interface) => {
        if (_interface && _interface.type) {
          switch (_interface.type) {
            case InterfacePayloadType.SetUpFilterColumns:
              this.onFilterColumnSetup(_interface.payload.filter);
              break;
            case InterfacePayloadType.CreateWorkspace:
              await this.initListFilter(_interface.payload.filters, _interface.payload.filter, InterfacePayloadType.CreateWorkspace);
              break;
            case InterfacePayloadType.ReloadWorkspace:
              if (_interface.payload.filter && _interface.payload.filter.awid) {
                this.batch_table.options.page.filter = _interface.payload.filter;
                this.batch_table.options.page.groupBy = _interface.payload.filter.awid;

                await this.initListFilter(_interface.payload.filters, _interface.payload.filter, InterfacePayloadType.CreateWorkspace);
                this.setupFilterColumns(_interface.payload.filter);
              }
              break;
            case InterfacePayloadType.CTA:
              if (_interface.payload.type == "delete") {
                if (_interface.payload.payload) {
                  this.batch_discard_state.active = _interface.payload.payload;
                }
                this.forms.discard_draft.toggled = _interface.payload.toggled;
              }
              break;
            case InterfacePayloadType.QueryFilter:
              this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

              this.batch_table.options.page.current = 0;
              // console.log(this.batch_table.data)
              var payload = await this.bs.getAll(this.batch_table.options.page);

              if (payload?.data) {
                this.batch_table.data = payload.data;
                this.batch_table.options.page = payload.options.page;
              }

              this._is.interfaceBS.next({type: InterfacePayloadType.Loading});
              break;
            case InterfacePayloadType.WorkspaceTabClick:

              this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

              // console.log(this.batch_table.data)
              var payload = await this.bs.getAll(this.batch_table.options.page);

              if (payload?.data) {
                this.batch_table.data = payload.data;
                this.batch_table.options.page = payload.options.page;
              }

              this._is.interfaceBS.next({type: InterfacePayloadType.Loading});
              break;
            case InterfacePayloadType.Filter:
              this.batch_table.options.page.filter = _interface.payload.filter;
              break;
            case InterfacePayloadType.Keyword:

              if (this.batch_table.options.page.filter) {

                //@ts-ignore
                var filter_store: any = Object.assign(this.batch_table.options.page.filter);

                if (filter_store) {
                  filter_store.keyword = _interface.payload.search;
                  this.batch_table.options.page.filter = filter_store;
                }

                localStorage.setItem('filters', JSON.stringify(this.batch_table.options));

                this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

                var payload = await this.bs.getAll(this.batch_table.options.page);

                if (payload?.data) {
                  this.batch_table.data = payload.data;
                  this.batch_table.options.page = payload.options.page;
                }

                localStorage.setItem('filters', JSON.stringify(this.batch_table.options));

                this._is.interfaceBS.next({type: InterfacePayloadType.Loading});

              }

              break;
            case InterfacePayloadType.ListFilter:

              if (_interface.payload.filters && _interface.payload.filter) {
                let active_filter: FilterOptions = _interface.payload.filter;

                this.batch_table.options.page.filter = active_filter;

                const saved_filter = localStorage.getItem('filters');

                if (saved_filter) {

                  const parsed_filter = JSON.parse(saved_filter);

                  if (parsed_filter.page.groupBy) {
                    this.batch_table.options.page.groupBy = parsed_filter.page.groupBy;
                    active_filter = _interface.payload.filters.find((c: any) => c.awid == parsed_filter.page.groupBy);
                    await this.initListFilter(_interface.payload.filters, active_filter);
                  }

                }

              }

              if (_interface.payload.toggled != undefined) {
                this.filter_toggles = _interface.payload.toggled;
              }
              break;
            case InterfacePayloadType.SetFilters:
              if (_interface.payload.filters && _interface.payload.filter) {
                let active_filter: FilterOptions = _interface.payload.filter;

                this.batch_table.options.page.filter = active_filter;

                const saved_filter = localStorage.getItem('filters');

                if (saved_filter) {

                  const parsed_filter = JSON.parse(saved_filter);

                  if (parsed_filter.page.groupBy) {
                    this.batch_table.options.page.groupBy = parsed_filter.page.groupBy;
                    active_filter = _interface.payload.filters.find((c: any) => c.awid == parsed_filter.page.groupBy);
                    await this.initListFilter(_interface.payload.filters, active_filter, InterfacePayloadType.SetFilters);
                  }
                }
              }
              if (_interface.payload.toggled != undefined) {
                this.filter_toggles = _interface.payload.toggled;
              }
              break;
            case InterfacePayloadType.GroupBy:
              const that = this;
              setTimeout(function() {
                that.onGroupFilterClick(_interface.payload.filter);
              }, 80);
              break;
          }
        }
      }
    );
  }

  async ngOnInit() {

    this.batch_discard_state.active = null;
    this.batch_discard_state.confirm = false;
    this.forms.discard_draft.toggled = false;

    this.role = this._auth.getClaim(Claim.Role);

    const filter_store: string | null = localStorage.getItem('filters');

    if (filter_store) {
      const filters: TableOptions = JSON.parse(filter_store);

      this._is.IFilterBS.next(filters);
      this.batch_table.options.page = filters.page;

      const that = this;

      setTimeout(() => {
        const payload = {
          type: 'on-tab-click',
          filter: filters.page,
        };
        that._is.interfaceBS.next({type: InterfacePayloadType.OnInit, payload});
      }, 500);


      if (filters.page.groupBy == 'bookmarked') {
        await this.onGroupBookmarked();
      }
    }

  }

  async reviseBatch(
    event: {},
    item: Batch,
    scope: { router?: any; _ms: MessageService; bs: BatchService; role: Role }
  ) {
    if (item.bid) {
      const pending = await scope.bs.getPendingBatchTask(
        "DocumentPrintAll",
        item.bid
      );

      if (pending && pending.completedOn == null) {
        scope._ms.set({
          message:
            "Batch documents are currently being processed. Please try again later.",
          type: MessageType.Info,
          delay: 7000,
        });
        return;
      }

      await scope.router.navigate(["dashboard", "batch", "revise", item.bid]);
    }
  }

  viewBatch(event: {}, item: Batch, scope: { router?: any }) {
    if (item.bid) {
      scope.router.navigate(["dashboard", "batch", item.bid]);
    }
  }

  async onTableChange(event: OnTableChangePayload) {
    if (
      event.type == TablePayloadType.Next ||
      event.type == TablePayloadType.Previous
    ) {
      if (this.loaded) {
        this.batch_table.options.page.current =
          event.type == TablePayloadType.Next
            ? event.payload.page.current + 1
            : event.payload.page.current - 1;
      }
    }

    if (event.type != TablePayloadType.Filter) {

      if (event.type == TablePayloadType.GroupBy) {
        if (event.payload.page.groupBy == "bookmarked") {
          this.onGroupBookmarked();
        }
      }

      if (event.type != TablePayloadType.GroupBy) {
        this.loaded = true;

        localStorage.setItem('filters', JSON.stringify(this.batch_table.options));

        //@ts-ignore
        this._is.interfaceBS.next({type: InterfacePayloadType.SetKeyword, payload: this.batch_table.options.page.filter.keyword});

        this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

        // await Wait(1);

        // this._is.interfaceBS.next({type: InterfacePayloadType.StartLoading});

        const payload = await this.bs.getAll(this.batch_table.options.page);

        if (payload?.data) {
          this.batch_table.data = payload.data;
          this.batch_table.options.page = payload.options.page;
        }

        this._is.interfaceBS.next({type: InterfacePayloadType.Loading});

      }

    } else {
      this.batch_table.options = event.payload;
    }


  }

  async onGroupBookmarked() {

    const filters = await this._ws.list_defaults();

    this.batch_table.options.page.groupBy = "bookmarked";
    if (filters) {
      const filter = filters.find((c: FilterOptions) => c.name == "Bookmarked");

      if (filter) {

        const payload = {
          type: 'on-tab-click',
          filter,
        };

        this.batch_table.options.page.filter = filter;

        this._is.interfaceBS.next({type: InterfacePayloadType.SetKeyword, payload: ""});

        localStorage.setItem('filters', JSON.stringify(this.batch_table.options));

        this.setupFilterColumns(filter);

        this._is.interfaceBS.next({type: InterfacePayloadType.WorkspaceTabClick, payload});

      }
    }
  }
}
