import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TextToClipboard} from "../../utilities";

@Component({
  selector: 'element-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss']
})
export class ClipboardComponent implements OnInit, OnChanges {
  constructor() {
  }



	@Output() onClick = new EventEmitter();
  @Input() value = "";
  @Input() color = "green";
  copied = false;

  onClickCapture() {
    this.onClick.emit(true);
  }
  ngOnChanges(changes: SimpleChanges): void {
		this.value = changes.value.currentValue;
  }
  ngOnInit(): void {

  }

  copy() {
    TextToClipboard(this.value);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

}
