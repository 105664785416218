import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filesize",
})
export class FilesizePipe implements PipeTransform {
  transform(bytes: number, decimals: number = 0, bold: boolean = true): string {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (bold) {
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} <strong>${
        sizes[i]
      }</strong>`;
    } else {
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }
  }
}
