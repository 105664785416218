import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";

import {Account, Claim} from "../models/account";
import {AuthenticationRpcService} from "./authentication.rpc.service";
import {config} from "../app.config";

@Injectable({providedIn: "root"})
export class AuthenticationService {
  constructor(private http?: HttpClient, private router?: Router, private authRpc?: AuthenticationRpcService) {
    const storage: string | null = localStorage.getItem("current_account");

    if (!storage) {
      localStorage.setItem("current_account", "{}");
    }
    if (storage) {
      this.currentAccountSubject = new BehaviorSubject<Account>(
        JSON.parse(storage)
      );
      this.current_account = this.currentAccountSubject.asObservable();
    }
  }

  //@ts-ignore
  private currentAccountSubject: BehaviorSubject<Account> = new BehaviorSubject(
    null
  );

  //@ts-ignore
  public current_account: Observable<Account>;

  public get currentAccountValue(): Account {
    return this.currentAccountSubject.value;
  }

  login(username: string, password: string) {
    if (this.http) {
      return this.http
        .post<any>(`account/auth`, {username, password})
        .pipe(
          map((user) => {
            if (user && user.token) {
              localStorage.setItem("current_account", JSON.stringify(user));

              //Store RPC AccessToken
              if (this.authRpc && config.enableRpc ) {
                this.authRpc.authenticate(username, password)
                  .then(res => localStorage.setItem("rpc.access_token", JSON.stringify(res)));
              }

              const filter_locals = localStorage.getItem("filters");

              if (filter_locals) {
                const filters = JSON.parse(filter_locals);
                if (filters) {
                  if (!filters?.page?.filter?.columns) {
                    localStorage.removeItem("filters");
                  }
                }
              }

              this.currentAccountSubject.next(user);
            }
            return user;
          })
        );
    }
  }

  createAccount(username: string, password: string) {
    if (this.http) {
      return this.http
        .post<any>(`account`, {username, password})
        .pipe(
          map((user) => {
            if (user && user.token) {
              localStorage.setItem("current_account", JSON.stringify(user));
              this.currentAccountSubject.next(user);
            }
            return user;
          })
        );
    }
  }

  async forogtPasswrd(email: string) {
    if (this.http) {
      return await this.http.post(`account/forgot`, {email}).toPromise();
    }
  }

  async validateResetCode(code: string) {
    if (this.http) {
      return await this.http
        .post(`account/reset/validate`, {code})
        .toPromise();
    }
  }

  async resetPassword(
    password: string,
    confirm_password: string,
    code: string
  ) {
    if (this.http) {
      return await this.http
        .post(`account/reset`, {password, confirm_password, code})
        .toPromise();
    }
  }

  getClaim(key: Claim) {
    const token = this.currentAccountValue?.token;
    const res = token ? JSON.parse(atob(token.split(".")[1])) : "";
    return res[key];
  }

  logout() {
    if (this.router) {
      localStorage.removeItem("current_account");
      this.router.navigate([""]);
      window.location.reload();
    }
  }
}
