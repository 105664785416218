import {
  Directive,
  HostListener,
  HostBinding,
  EventEmitter,
  Output,
} from "@angular/core";

@Directive({
  selector: "[UploadDirective]",
})
export class UploadDirective {
  constructor() {}

  @HostBinding("class.fileover") isFileOverZone: boolean = false;
  @HostBinding("class.files-in-queue") isFilesInQueue: boolean = false;

  @Output() fileDropped: EventEmitter<FileList> = new EventEmitter();

  @HostListener("dragover", ["$event"])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.isFileOverZone = true;
  }

  @HostListener("dragLeave", ["$event"])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.isFileOverZone = false;
  }

  @HostListener("drop", ["$event"])
  public onDrop(evt: DragEvent) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();

      this.isFileOverZone = false;

      const files = evt?.dataTransfer?.files;

      if (files && files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
