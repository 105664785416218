<div class="overlay" *ngIf="toggle" (click)="toggler()"></div>
<div class="dropdownm_filter__wrapper">
    <div class="selected" (click)="toggler()" >
        <div *ngIf="!selected && !options.hide_label">{{options.placeholder}}</div>
        <div *ngIf="selected &&  !options.hide_label">{{selected[options?.keys?.label]}}</div>
        <i class="{{options.icon}}"></i>
    </div>
    <div #viewpoint class="values field__input light" [ngClass]="{active: toggle}">
        <div class="search field_input_icon">
            <input [(ngModel)]="search" [name]="random_id" [id]="random_id" >
            <i class="icon-search left"></i>
        </div>
        <div class="items scrollbar__" >
            <div class="item" *ngFor="let item of data" #parentEle>
                <div *ngIf="item[options?.keys?.children].length != 0">
                    <div class="parent">
                        {{item[options?.keys?.label]}}
                    </div>
                    <div class="children">
                        <div class="item" *ngFor="let child of item[options?.keys?.children] | filter:search:options?.keys?.label:parentEle" (click)="select(child)">
                            {{child[options?.keys?.label]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
