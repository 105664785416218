<element-table-ng [datasource]="batch_table.data" [columns]="batch_table.options.columns" [table]="batch_table"
                  (onChange)="onTableChange($event)"></element-table-ng>

<element-modal name="Discard Draft" type="delete" [toggle]="forms.discard_draft.toggled"
               *ngIf="forms.discard_draft.toggled">
  <div class="field-toggle">
    <input type="checkbox" id="account_deletion_confirmation">
    <label for="account_deletion_confirmation" (click)="discardDraftConfirmToggle()">
      <div class="switch">
        <div class="dot"></div>
      </div>
      <span>Please toggle to confirm draft deletion. </span>
    </label>
  </div>
  <div class="spacer__"></div>
  <div class="spacer__"></div>
  <div class="field__input row" style="border-top: 1px solid #E2E2E2;padding: 1.5em;margin:0 -2em -2em;">
    <button type="button" class="button__" (click)=" _toggleDraftDiscard()">Cancel</button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button type="submit" class="button__ primary" (click)="discardDraftConfirmation()"
            [disabled]="!batch_discard_state.confirm">Delete Account
    </button>
  </div>

</element-modal>
