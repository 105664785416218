import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment,
  Route
} from "@angular/router";

import { Role } from "../models";
import { AuthenticationService } from "../services/authentication.service";
import { Claim } from "../models/account";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _as: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const current_account = this._as.currentAccountValue;
    if (current_account) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

@Injectable()
export class CanLoadDashboard implements CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    const role = this.authenticationService.getClaim(Claim.Role);
    if (role) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }
}
