import { Component, OnInit } from "@angular/core";
import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
  TableAction,
  TablePagerLocation,
  TableColumnType,
} from "src/app/elements/table/types";
import { BatchService, Batch } from "src/app/services/batch.service";
import { Router } from "@angular/router";
import { Role, MessageType } from "src/app/models";
import { MessageService } from "src/app/services";
import {config} from "src/app/app.config";

@Component({
  selector: "view-batches",
  templateUrl: "./batches.component.html",
  styleUrls: ["./batches.component.css"],
})
export class BatchesTruckerComponent implements OnInit {
  constructor(
    private bs: BatchService,
    private router: Router,
    private _ms: MessageService
  ) {}
  loaded: boolean = false;
  batch_table: TableData = {
    data: [],
    options: {
      layout: {
        header: true,
        footer: false,
        pagerLocation: TablePagerLocation.Top,
        hideActions: true,
      },
      columns: [
        { 
          id: "bookmarked", 
          label: "<i class='icon-bookmark'></i>", 
          type: TableColumnType.BookBookmark, 
          width:50 , 
          onClick:true, 
          function:(item:Batch, parentEle:HTMLElement)=>{
            this.bookmarkBatch(item, parentEle)
          }
        },
        { id: "id", label: "Batch No." },
        { id: "status", label: "Status", type: TableColumnType.BatchStatus },
        {
          id: "governmentStatuses",
          label: "Gov. Statuses",
          type: TableColumnType.GovStatuses,
        },
        {
          id: "orderNumber",
          label: "Order Number",
          type: TableColumnType.MoreInfo,
        },
        { 
          id: "awbBolNumber", 
          label: "AWB/BOL NO." , 
          type: TableColumnType.TrackerLink, 
          onClick:true, 
          function:(item:Batch, parentEle:HTMLElement)=>{
              if(item.courierType){
                window.open("https://connect.track-trace.com/for/"+config.trackingID+"/aircargo/"+item.awbBolNumber,'_blank');
              }else{
                window.open("https://connect.track-trace.com/for/"+config.trackingID+"/billoflading/"+item.awbBolNumber,'_blank');
              }
          }
        },
        { id: "eta", label: "ETA", type: TableColumnType.Date },
        { id: "importer", label: "Importer" },
        {
          id: "tags",
          label: "Tag",
          type: TableColumnType.MoreInfo,
        },
        {
          id: "updatedOn",
          label: "Updated On",
          type: TableColumnType.DateTime,
        },
      ],
      groups: [
        { id: 'Bookmarked', label: "<i class='icon-bookmark'></i>" , tabWidth:45},
        { id: Role.Trucker, label: "Batches" },
      ],
      page: {
        orderBy: {
          column: "updated_on",
          direction: TableOrderByDirection.DESC,
        },
        groupBy: Role.Trucker,
        filter: null,
        filterables:
          '{"suppliers":"","importers":"","truckers":"","status":"","gov_statuses":"","courier_type":""}',
        current: 1,
        size: 25,
      },
    },
  };

  tableActions: TableAction[] = [
    {
      onClick: true,
      name: "view",
      icon: "icon-eye",
      function: this.viewBatch,
      scope: this,
    },
  ];

  ngOnInit() {}

  reviseBatch(
    event: {},
    item: Batch,
    scope: { router?: any; _ms: MessageService }
  ) {
    if (item.bid) {
      if (item.pendingDocuments != 0) {
        scope._ms.set({
          message:
            "Batch documents are currently being processed. Please try again later.",
          type: MessageType.Info,
          delay: 7000,
        });
      } else {
        if (
          String(item.status) == "Created" ||
          String(item.status) == "Draft" ||
          String(item.status) == "Issue"
        ) {
          scope.router.navigate(["dashboard", "batch", "revise", item.bid]);
        } else {
          scope._ms.set({
            message:
              "Batch is currently being worked on and is locked. Please try again later.",
            type: MessageType.Info,
            delay: 7000,
          });
          return;
        }
      }
    }
  }
  async bookmarkBatch(item:Batch, parentEle:HTMLElement){
    if(item.bid){
      const icon = parentEle.querySelector('i')
      if(icon){
        const result = await this.bs.bookmarkBatch(item.bid)
        if( icon?.classList.contains('bookmark-active') && !result){
          icon.classList.remove('bookmark-active');
        }
        if(!icon?.classList.contains('bookmark-active') && result){
          icon.classList.add('bookmark-active');
        }
        if(this.batch_table.options.page.groupBy == Role.Bookmarked){
          const payload = await this.bs.getAll(this.batch_table.options.page);
	    if(payload){
		this.batch_table.data = payload.data;
		this.batch_table.options.page = payload.options.page;
	    }
        }
      }
    }
  }

  viewBatch(event: {}, item: Batch, scope: { router?: any }) {
    if (item.bid) {
      scope.router.navigate(["dashboard", "batch", item.bid]);
    }
  }

  async onTableChange(event: OnTableChangePayload) {
    if (
      event.type == TablePayloadType.Next ||
      event.type == TablePayloadType.Previous
    ) {
      if (this.loaded) {
        this.batch_table.options.page.current =
          event.type == TablePayloadType.Next
            ? event.payload.page.current + 1
            : event.payload.page.current - 1;
      }
    }

    this.loaded = true;

    const payload = await this.bs.getAll(this.batch_table.options.page);
    if(payload){
	this.batch_table.data = payload.data;
	this.batch_table.options.page = payload.options.page;
    }
  }
}
