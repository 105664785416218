import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ModalService {
  triggerBS: BehaviorSubject<boolean | any> = new BehaviorSubject(false);
  trigger: Observable<boolean> = this.triggerBS.asObservable();
  constructor() {}
}
