import {AfterContentInit, AfterViewInit, Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges,
  ViewChild
} from "@angular/core";

import {
  BatchService,
  Batch,
  IDocument,
  DocumentService,
  AuthenticationService,
  MessageService,
  InterfaceService,
} from "src/app/services";

import {ActivatedRoute, Router} from "@angular/router";
import {TableColumnType, TableColumn} from "src/app/elements/table/types";
import {FormatDate, FormatDateInputValue} from "src/app/helpers/util";
import {
  Status,
  BatchRevision,
  BatchType,
  IStatuses,
} from "src/app/services/batch.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import {Role} from "src/app/models";
import {Claim} from "src/app/models/account";
import {FormsModel} from "src/app/models/forms";
import {DocumentRpcService} from "../../services/document.rpc.service";
import {GcPdfViewer, ViewerOptions} from "@grapecity/gcpdfviewer";
import {downloadData} from '../../utilities';
import {BatchRpcService} from "../../services/batch.rpc.service";
import {config} from "../../app.config";

@Component({
  selector: "view-batch-details-main",
  templateUrl: "./batch-details-main.component.html",
  styleUrls: ["./batch-details-main.component.scss"],
})
export class BatchDetailsMainComponent implements OnInit, OnDestroy, AfterViewInit{

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _ds: DocumentService,
    private _as: AuthenticationService,
    private _bs: BatchService,
    private _is: InterfaceService,
    private _ms: MessageService,
    private doc: DocumentRpcService,
    private _batch: BatchRpcService
  ) {

  }


  _viewerOptions: ViewerOptions;
  _viewer: GcPdfViewer;

  @ViewChild("viewer") viewerDom: HTMLElement;

  Role = Role;
  BatchType = BatchType;
  column: TableColumn = {
    id: "",
    type: TableColumnType.MoreInfo,
    label: "Order Number",
  };
  gov_status: TableColumn = {
    id: "",
    type: TableColumnType.GovStatuses,
    label: "Gov. Statuses",
  };

  id = "";
  batch_issue: string | null = null;
  batch: Batch = {
    associations: [],
    orderNumber: [],
    awbBolNumber: "",
    status: 0,
    statusLabel: "",
    documents: [],
    documentsList: [],
    notes: "",
  };

  revisions: BatchRevision[] = [];

  loading = false;

  previewLabel = "";
  document: IDocument | null = null;
  Object = Object;
  states = {
    revision: false,
    print_all: false,
    print_all_pending: false,
    current: {
      preview: "",
      direct: "",
    },
    menus: {
      status: false,
      revision: false,
    },
  };

  statuses: IStatuses | undefined;

  selected_status: number | null = null;

  selected_status_label: string | undefined = "";
  gov_statuses: Status[] = [];

  role = this._as.getClaim(Claim.Role);

  openedTimer: any = null;

  is_loading = false;

  selectedDocuments: IDocument[] = [];

  ngOnDestroy() {
    this._viewer.dispose();
    if (this.openedTimer) {
      clearInterval(this.openedTimer);
    }
  }

  editBatch() {
    this.router.navigate([
      "dashboard",
      "batch",
      "revise",
      this.batch.bid,
    ]);
  }

  onWheel(event: WheelEvent): void {
    //@ts-ignore
    (<Element>event.target).parentElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  loadViewer() {
    if (this._viewer) {
      this._viewer.dispose();
    }

    this._viewerOptions = new ViewerOptions();
    this._viewerOptions.workerSrc = "//node_modules/@grapecity/gcpdfviewer/gcpdfviewer.worker.js";
    this._viewerOptions.restoreViewStateOnLoad = true;
    this._viewerOptions.buttons = ["custom-fullscreen", "custom-download", "print", "$navigation", "zoom", "pan", "text-selection", "view-mode", "rotate"];
    this._viewerOptions.requireTheme = "viewer";
    this._viewerOptions.renderer = "canvas";

    GcPdfViewer.LicenseKey = atob(config.gc);

    this._viewer = new GcPdfViewer("#viewer", this._viewerOptions);
    this._viewer.addThumbnailsPanel();
    this._viewer.addSearchPanel();
    this._viewer.options.shortcuts = {
      82: [{
        ctrl: true, keyCode: 82, tool: () => {
        }
      }]
    };

    this._viewer.options.zoomByMouseWheel = {always: false, ctrlKey: true, metaKey: true};

    const React = this._viewer.getType("React");
    const viewer: any = this._viewer;
    const toolbar = viewer.toolbar;

    this._viewer.onAfterOpen.register(() => {
      if (document.querySelector('#viewer:has(.gcv-main-view.gcv-main-view--fullscreen)')) {
        viewer.toolbar._view._commands.toggleFullscreen();
      }
    });


    toolbar.addItem({
      key: "custom-fullscreen",
      icon: {
        type: 'svg',
        content: React.createElement(
          'svg',
          {xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#559765'},
          React.createElement('path', {d: 'M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z'})
        )
      },
      title: "Fullscreen",
      enabled: true,
      action: async () => {
        viewer.toolbar._view._commands.toggleFullscreen();
        window.dispatchEvent(new Event('resize'));
      },
    });


    toolbar.addItem({
      key: "custom-download",
      icon: {
        type: 'svg',
        content: React.createElement(
          'svg',
          {xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#559765'},
          React.createElement('path', {d: 'M5 19.998h14v-2H5m14-9h-4v-6H9v6H5l7 7 7-7z'})
        )
      },
      title: "Download",
      enabled: true,
      action: async () => {
        this.is_loading = true;
        const docs: string[] = this.selectedDocuments.map(d => d.did);
        if (this.batch.bid && docs.length > 0) {

          const stream = await this.doc.getDocumentDownload(this.batch.bid, docs);
          if (stream) {
            const downloadName = (docs.length == 1) ? this.selectedDocuments[0].name : String(Math.random() * 9999999) + "-Batch-Archive.zip";
            downloadData(stream, downloadName, "zip");
          }
        }
        this.is_loading = false;
      },
    });


  }

  initRouterSubscriber() {
    this.route.params.subscribe(async (draft) => {
      this.is_loading = true;

      if (draft.bid) {
        this.id = draft.bid;

        this.is_loading = true;

        const batch = await this._bs.getBatch(draft.bid);

        if (!batch) {
          await this.router.navigate(["/dashboard/batches"]);
          return;
        }

        this.batch = structuredClone(batch);

        if (batch.bid) {
          this.batch.documents = await this.doc.getBatchDocuments(batch.bid, batch.parentBID) ?? [];
          if (this.batch.documents.length > 0) {
            this.document = this.batch.documents[0];
          }
        }

        this.loadViewer();

        this.selected_status = this.batch.status;
        this.selected_status_label = this.batch.statusLabel;

        if (this.batch.eta) {
          this.batch.eta = FormatDateInputValue(new Date(this.batch.eta), 2);
        }

        if (this.batch.lfd) {
          this.batch.lfd = FormatDateInputValue(new Date(this.batch.lfd), 2);
        }

        this.batch.associations = this.batch.associations.filter(
          (i) => i.data != null
        );

        this.statuses = await this._bs.getBatchStatus(this.id);

        const revisions = (await this._bs.getBatchRevisions(draft.bid)) || [];
        this.revisions = revisions.map((s) => {
          return {...s, updatedOn: FormatDate(new Date(s.updatedOn))};
        });

        if (this.batch.updatedOn) {
          this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
        }

        if (this.batch.documents.length > 0) {
          await this.selectDocument(this.batch.documents[0]);
          await this.generatePreview();
        }

        if (
          (this.role == Role.Alpha ||
            this.role == Role.Clerk ||
            this.role == Role.Manager) &&
          this._as.getClaim(Claim.ID) != this.batch.createdBy &&
          this.selected_status == BatchType.Created
        ) {
          this.openedTimer = setTimeout(async () => {
            await this._bs.updateBatchStatus(this.id, BatchType.Opened);
          }, 5000);
        }

        await this._bs.markBatchAsRead(this.id);
      }

      this.is_loading = false;
    });
  }


  async previewDocument(doc: IDocument) {
    this.is_loading = true;
    this.document = doc;
    if (this.batch && this.batch.bid) {
      const stream = await this.doc.generatePreview([doc.did], this.batch.bid);
      await this._viewer.open(stream);
    }
    this.is_loading = false;
  }

  selectAllDocument() {
    this.selectedDocuments = this.batch.documents;
  }

  @HostListener('window:keydown', ['$event'])
  async initKeydownListener(event: KeyboardEvent) {

    if (event.ctrlKey && event.key == 'a') {
      event.preventDefault();
      if (this.selectedDocuments.length == this.batch.documents.length) {
        this.selectedDocuments = [];
      } else {
        this.selectedDocuments = this.batch.documents;
      }
      await this.generatePreview();
    }
  }

  clearSelectedDocuments() {
    this.selectedDocuments = [];
  }

  isDocumentSelected(did: string) {
    return !!this.selectedDocuments.find(d => d.did == did);
  }

  ngAfterViewInit(): void {
    this.initRouterSubscriber();
  }

  ngOnInit() {
  }

  toggleRevisionMenu() {
    this.states.menus.revision = !this.states.menus.revision;
  }

  async onRevisionSelect(batchId: string, index: number = 0) {
    this.states.revision = index != 0;
    const batch = await this._bs.getBatchRevision(batchId);
    if (batch && batch.bid) {
      this.batch.documents = await this.doc.getBatchDocuments(batch.bid, batch.parentBID) ?? [];
      batch.documents = Object.assign(this.batch.documents);
    }
    if (batch) {
      this.batch = structuredClone(batch);
      // this.batch.documents = Object.assign(this.batch?.documentsList);
      if (this.batch?.updatedOn) {
        this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
      }
    }
    this.toggleRevisionMenu();
  }

  async setRevision() {
    if (this.batch?.bid) {
      const revision: Batch =
        (await this._bs.setBatchRevision(this.batch?.bid)) || this.batch;

      if (revision && revision.bid) {
        if (revision?.bid && this.batch.bid) {
          await this._batch.updateBatchDocuments(this.batch.bid, revision.bid);

        }
        const batch = await this._bs.getBatch(revision.bid);

        if (batch) {
          this.batch = structuredClone(batch);
          this.batch.documents = await this.doc.getBatchDocuments(revision.bid, revision.parentBID) ?? [];

          if (this.batch?.updatedOn) {
            this.batch.updatedOn = FormatDate(new Date(this.batch.updatedOn));
          }

          const revisions = (await this._bs.getBatchRevisions(this.id)) || [];

          this.revisions = revisions.map((s) => {
            return {...s, updatedOn: FormatDate(new Date(s.updatedOn))};
          });
        }
        this.states.revision = false;
      }
    }
  }

  async generatePreview() {
    this.is_loading = true;
    try {
      const docs: string[] = this.selectedDocuments.map(d => d.did);
      if (this.batch.bid) {
        const stream = await this.doc.generatePreview(docs, this.batch.bid);
        await this._viewer.open(stream);
      }
    } catch (e) {
      console.log(e);
    }
    this.is_loading = false;
  }


  async selectDocument(doc: IDocument) {
    if (!this.selectedDocuments.find(d => d.did == doc.did)) {
      this.selectedDocuments.push(doc);
    } else {
      this.selectedDocuments = this.selectedDocuments.filter(d => d.did != doc.did);
    }
    this.previewLabel = this.selectedDocuments.map(d => d.name).join(', ');
    // await this.generatePreview();
  }

}
