import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TableOptions } from "../elements/table/types";

@Injectable({
  providedIn: "root",
})
export class InterfaceService {
  constructor() {}

  interfaceBS: BehaviorSubject<InterfacePayload | any> = new BehaviorSubject(
    null
  );

  interface: Observable<InterfacePayload> = this.interfaceBS.asObservable();

  IFilterBS:BehaviorSubject<TableOptions|any> = new BehaviorSubject(null);
  IFilter:Observable<TableOptions> = this.IFilterBS.asObservable();

  modal__creat_account: boolean = false;

  __modal: boolean = false;

  toggle(data: any = {}) {
    this.__modal = !this.__modal;
    this.interfaceBS.next({
      type: InterfacePayloadType.CTA,
      payload: {
        ...data,
        toggled: this.__modal,
      },
    });
  }

  toggleCreateAccountModal(data: any = {}) {
    this.modal__creat_account = !this.modal__creat_account;
    this.interfaceBS.next({
      type: InterfacePayloadType.CTA,
      payload: {
        ...data,
        toggled: this.modal__creat_account,
      },
    });
  }
}

export interface InterfacePayload {
  type: InterfacePayloadType;
  payload: CTAPayload;
}

export interface CTAPayload {
  toggled: boolean;
  [key: string]: any;
}

export enum InterfacePayloadType {
  CTA = "cta",
  ListFilter = "list-filter",
  Loading = "loading",
  StartLoading = "start-loading",
  Filter = "Filter",
  ToggleOnFilter = "ToggleOnFilter",
  ToggleOffFilter = "ToggleOffFilter",
  CreateWorkspace = "CreateWorkspace",
  GroupBy = "GroupBy",
  Keyword = "Keyword",
  SetKeyword = "SetKeyword",
  QueryFilter = "QueryFilter",
  SetUpFilterColumns = "SetUpFilterColumns",
  CreateWorkspaceToggle = "CreateWorkspaceToggle",
  WorkspaceTabClick = "WorkspaceTabClick",
  ReloadWorkspace = "ReloadWorkspace",
  OnInit = "OnInit",
  SetFilters = "SetFilters",
  SetNotice = "SetNotice",
  GetNotices = "GetNotices",
}
