import { Pipe, PipeTransform } from "@angular/core";
import { Status } from "../services/batch.service";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(
    items: any[],
    search: string,
    field: string,
    parentEle: HTMLDivElement
  ): any[] {
    parentEle.style.display = "block";

    let filtered = [];

    if (!items) return [];

    if (!search) return items;

    filtered = items.filter(
      (item) => item[field].toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
    if (filtered.length == 0) {
      parentEle.style.display = "none";
    }
    return filtered;
  }
}

@Pipe({
  name: "filterStatus",
})
export class FilterStatusPipe implements PipeTransform {
  transform(items: Status[], search: number): any[] {
    if (!items) return [];
    return items.filter((s) => {
      return s.requiredStatus.indexOf(search) != -1;
    });
  }
}
