import {Component, ComponentFactoryResolver, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Account, Role, TypeEnum} from "src/app/models";
import {FilterService, InterfaceService, BatchService, DocumentService, AuthenticationService} from "../../services";
import {Claim} from "src/app/models/account";
import {InterfacePayload, InterfacePayloadType} from "src/app/services/interface.service";
import {config} from "../../app.config";

@Component({
  selector: "element-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
})
export class TopBarComponent implements OnInit {
  constructor(
    private _as: AuthenticationService,
    private _fs: FilterService,
    private _is: InterfaceService,
    private _bs: BatchService,
    private _ds: DocumentService,
    public router: Router
  ) {
    // router.events.subscribe((val) => {
    //   this.advancedSearch = false;
    // });
  }

  notices: any[] = [];
  unread_notice_count = 0;
  Role = Role;

  //@ts-ignore
  account: Account;
  //@ts-ignore
  role: Role;
  //@ts-ignore
  username: string;
  //@ts-ignore
  username_initials: string;

  states = {
    account_menu: {
      hover: false,
      active: false,
    },
  };

  advancedSearch = false;

  is_observer: any = null;


  //@ts-ignore
  filter = "";

  on_change_wait_count = 0;
  on_change_interval: any = null;
  processNoticeHover = this.debounce_leading(() => this.getNotices());

  toggleAdvancedSearch() {
    this.advancedSearch = !this.advancedSearch;
    this._is.interfaceBS.next({type: InterfacePayloadType.ListFilter, payload: {toggled: this.advancedSearch}});
  }

  toggleCreateAccount() {
    this._is.toggle({type: "create"});
  }

  onChange(event: KeyboardEvent) {

    if (this.router.url == '/dashboard/batches') {

      if (event.key == "Enter") {
        this._is.interfaceBS.next({type: InterfacePayloadType.Keyword, payload: {search: this.filter}});
      }

    } else {
      this._fs.setFilter(this.filter);
    }
  }

  setFilterables(evt: any) {
    // if (evt?.type == "multi-filter-select")
    //   this._fs.setFilterables(evt.payload.search, evt.payload.id.toLowerCase());
  }

  toggleAvatarMouseover() {
    this.states.account_menu.hover = !this.states.account_menu.hover;

    setTimeout(() => {
      this.states.account_menu.active = !this.states.account_menu.active;
    }, 500);
  }

  logout() {
    this._as.logout();
  }


  async noticeItemOnHover(notice: any) {
    if (!notice.isRead) {
      await this._bs.readNotice(notice);
      notice.isRead = true;
      this.unread_notice_count = this.notices.filter(n => n.isRead == 0).length;
    }
  }

  downloadBlob(blob: Blob, name = 'file.xlsx') {

    const navigator: any = window.navigator;
    if (
      navigator &&
      navigator.msSaveOrOpenBlob
    ) {
      return navigator.msSaveOrOpenBlob(blob);
    }

    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  downloadFile(exportId: string, filename: string) {
    const url = `${config.apiUrl}document/export/${exportId}/direct`;

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this._as.currentAccountValue.token}`,
      }
    }).then((resp) => {
      return resp.blob();
    }).then((blob) => {
      this.downloadBlob(blob, filename);
    });
  }

  async dismissNotice(notice: any) {
    await this._bs.dismissNotice(notice);
    this.notices = this.notices.filter(n => n.nid != notice.nid);
  }

  async noticeAction(actions: any) {
    switch (actions.icon) {
      case 'download':
        if (actions.id) {
          this.downloadFile(actions.id, 'worksheet-export.xlsx');
        }
        break;
    }
  }
  // tslint:disable-next-line:ban-types
  debounce(func:Function, timeout = 300){
    // @ts-ignore
    let timer;
    // @ts-ignore
    return (...args) => {
      // @ts-ignore
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  // @ts-ignore
  debounce_leading(func, timeout = 300){
    // @ts-ignore
    let timer;
    // @ts-ignore
    return (...args) => {
      // @ts-ignore
      if (!timer) {
        func.apply(this, args);
      }
      // @ts-ignore
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }
  async getNotices() {
    // @ts-ignore
    this.notices = await this._bs.getNotices();
    this.unread_notice_count = this.notices.filter(n => n.isRead == 0).length;
  }

  async ngOnInit() {

    //@ts-ignore
    this.notices = await this._bs.getNotices();
    if(this.notices && this.notices.length > 0){
      this.unread_notice_count = this.notices.filter(n => n.isRead == 0).length;
    }
    this.is_observer = this._is.interface.subscribe((data: InterfacePayload) => {
      if (!data) {
        return;
      }

      switch (data.type) {
        case InterfacePayloadType.GetNotices:
          //@ts-ignore
          this.notices = data.payload;
          break;
        case InterfacePayloadType.SetNotice:
          this.notices = [data.payload, ...this.notices];
          this.unread_notice_count = this.notices.filter(n => n.isRead == 0).length;
          break;
        case InterfacePayloadType.SetKeyword:
          //@ts-ignore
          this.filter = (data.payload) ? data.payload : "";
          break;
        case InterfacePayloadType.WorkspaceTabClick:
          //@ts-ignore
          this.filter = (data.payload.filter.keyword) ? data.payload.filter.keyword : "";
          break;
        case InterfacePayloadType.ToggleOnFilter:
          this.advancedSearch = true;
          break;
        case InterfacePayloadType.ToggleOffFilter:
          this.advancedSearch = false;
          break;
        case InterfacePayloadType.ListFilter:
          if (data.payload.type) {
            this.filter = "";
          }
          if (data.payload.toggled != undefined) {
            this.advancedSearch = data.payload.toggled;
          }
          break;
      }
    });

    this.account = this._as.currentAccountValue;
    this.role = this._as.getClaim(Claim.Role);
    this.username = this.account.username;
    this.username_initials = this.username.charAt(0);
  }
}
