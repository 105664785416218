<div class="account_settings__top">
    <div class="account_info">
        <h2>{{account_name}}</h2>
        <p>{{account_role_label}}</p>
    </div>
    <div>
        <button class="button__ "  [routerLink]="['/dashboard/accounts']">Cancel</button>&nbsp;&nbsp;&nbsp;
        <button class="button__ primary" (click)="onSubmit()">Update Account</button>
    </div>
</div>
<div class="account_settings__wrapper">

    <ul class="account__navigation">
        <li  [ngClass]="{active: pane == 'general'}" (click)="pane = 'general'"><i class="icon-house"></i> General</li>
        <li *ngIf="account_role_label == Role.Supplier || account_role_label == Role.Importer ||  account_role_label == Role.Trucker" [ngClass]="{active: pane == 'association'}" (click)="pane = 'association'"><i class="icon-account_associations"></i> Account Associations</li>
    </ul>
    <div class="account_setting__content">
        <div *ngIf="pane == 'general'">

            <form [formGroup]="forms.account_settings.form" (ngSubmit)="onSubmit()" autocomplete="off">
                <div class="field_group" >
                    <h2>Account Settings</h2>
                    <div class="field__input">
                        <label>Account Name</label>
                        <input type="text" autocomplete="off" formControlName="name" [ngClass]="{invalid:  forms.account_settings.submitted && field.name.errors?.required }">
                        <div *ngIf=" forms.account_settings.submitted &&  field.name.errors?.required" class="field__error">
                            <div *ngIf="field.name.errors?.required">Name is required</div>
                        </div>
                    </div>
                    <div class="field__input">
                        <label>Role Settings</label>
                        <select  formControlName="role" [ngClass]="{invalid:  forms.account_settings.submitted && field.role.errors?.required }">
                            <option *ngFor="let role of forms.account_settings.roles" [value]="role.rid">{{role.name}}</option>
                        </select>
                        <div *ngIf=" forms.account_settings.submitted && field.role.errors?.required" class="field__error">
                            <div *ngIf="field.role.errors?.required">Role is required</div>
                        </div>
                    </div>
                </div>
                <div class="field_group" formGroupName="contact_info" >
                    <h2>Contact Information</h2>
                   
                    <div class="field__input">
                        <label>Email</label>
                        <input type="text" autocomplete="off" formControlName="email" [ngClass]="{invalid:  forms.account_settings.submitted &&  hasError('account_settings','contact_info','email')}">
                        <div *ngIf=" forms.account_settings.submitted &&  hasError('account_settings','contact_info','email')" class="field__error">
                            <div *ngIf=" hasError('account_settings','contact_info','email')?.required">Email is required</div>
                            <div *ngIf=" hasError('account_settings','contact_info','email')?.pattern">Email is invalid</div>
                        </div>
                    </div>
                    <div class="field__input">
                        <label>Phone</label>
                        <input type="text" autocomplete="off" formControlName="phone">
                        <div *ngIf=" forms.account_settings.submitted && hasError('account_settings','contact_info','phone')" class="field__error">
                            <div *ngIf="hasError('account_settings','contact_info','phone')?.required">Phone is required</div>
                        </div>
                    </div>
                    <div class="field__input">
                        <label>Primary Contact</label>
                        <input type="text" autocomplete="off" formControlName="primary_contact" >
                    </div>
                </div>
    
    
                <div class="field_group"  formGroupName="update_password">
                    <h2>Update Password</h2>
      
                    <div class="field__input">
                        <label>Password</label>
                        <input type="text" autocomplete="off" formControlName="password">
                        <div *ngIf=" forms.account_settings.submitted &&  hasError('account_settings','update_password','password') " class="field__error">
                            <div *ngIf="hasError('account_settings','update_password','password')?.required"> Password is required</div>
                        </div>
                    </div>
                    <div class="field__input">
                        <label>Confirm Password</label>
                        <input type="text" autocomplete="off" formControlName="confirm_password" [ngClass]="{invalid:  forms.account_settings.submitted &&  hasError('account_settings','update_password','confirm_password')}">
                        <div *ngIf=" forms.account_settings.submitted && hasError('account_settings','update_password','confirm_password') " class="field__error">
                            <div *ngIf="hasError('account_settings','update_password','confirm_password')?.required">Password is required</div>
                            <div *ngIf="hasError('account_settings','update_password','confirm_password')?.mustMatch">Passwords must match</div>

                        </div>
                    </div>
                </div>
    
                
                <div class="field_group" formGroupName="business_info">
                    <h2>Business Information</h2>
                      <div class="field__input">
                        <label>DBA/Ficticious Name/LLC</label>
                        <input type="text" autocomplete="off" formControlName="fictitiouse_name">
                        <div *ngIf=" forms.account_settings.submitted && hasError('account_settings','business_info','fictitiouse_name')" class="field__error">
                        </div>
                    </div>
    
                    <div class="field__input" >
                        <label>Customer ID</label>
                        <input disabled type="text" autocomplete="off" formControlName="custom_id" [ngClass]="{invalid:  forms.account_settings.submitted &&  hasError('account_settings','business_info','custom_id')}">
                        <div *ngIf=" forms.account_settings.submitted && hasError('account_settings','business_info','custom_id')" class="field__error">
                            Customer ID is required.
                        </div>
                    </div>
                </div>
                
                <div class="field_group" formGroupName="catch_all_settings">
                    <h2>Catch All Settings</h2>
                    <p>Please note if catch all domain is not specified 
                        <strong>Contact Information</strong> email domain will be used.</p>
                    <div class="field__input">
                        <label>Domain</label>
                        <input type="text" autocomplete="off" formControlName="domain" [ngClass]="{invalid:  forms.account_settings.submitted &&  hasError('account_settings','catch_all_settings','domain')}">
                        <div *ngIf=" forms.account_settings.submitted && hasError('account_settings','catch_all_settings','domain') " class="field__error">
                            Domain is required.
                        </div>
                    </div>
                    
                </div>
    
            </form>

        </div>
        <div *ngIf="pane == 'association'">
            <element-account-associator [role]="account_role_label" [aid]="account_id"></element-account-associator>
        </div>
    </div>
</div>