import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  DropDownFilterOptions,
  DropDownFilterData,
  OnChangePayload,
  TypeEnum,
} from "src/app/models";
import {
  AuthenticationService,
  AccountService,
  BatchService,
  InterfaceService,
  FilterService,
} from "src/app/services";
import { Claim, Role } from "src/app/models/account";
import { TableOptions, TableOrderByDirection } from "../table/types";
import { StateToggleOption } from "../state-toggler/state-toggler.component";
import { Subscriber, Subscription } from "rxjs";


import { Router } from '@angular/router';

@Component({
  selector: "element-search-filter",
  templateUrl: "./search-filter.component.html",
  styleUrls: ["./search-filter.component.scss"],
})
export class SearchFilterComponent implements OnInit {
  constructor(
    private _auth: AuthenticationService,
    private _as: AccountService,
    private _bs: BatchService,
    private _is:InterfaceService,
    private _fs:FilterService
    ) {}

  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter();

  Role = Role;
  UserRole = this._auth.getClaim(Claim.Role);

  importersSettings: DropDownFilterOptions = {
    placeholder: "Importers",
    keys: {
      identifier: "value",
      label: "label",
    },
  };

  suppliersSettings: DropDownFilterOptions = {
    placeholder: "Suppliers",
    keys: {
      identifier: "value",
      label: "label",
    },
  };

  truckersSettings: DropDownFilterOptions = {
    placeholder: "Truckers",
    keys: {
      identifier: "value",
      label: "label",
    },
  };

  statusSettings: DropDownFilterOptions = {
    placeholder: "Status",
    keys: {
      identifier: "bsid",
      label: "label",
    },
  };

  govStatusSettings: DropDownFilterOptions = {
    placeholder: "Gov. Statuses",
    keys: {
      identifier: "bsid",
      label: "label",
    },
  };

  courierTypeSettings: StateToggleOption = {
    group:'courier_type',
    data: [{
      value: 0,
      label: 'bol',
      icon: 'icon-freight-outline',
      color:'#4056b6',
      textColor:'#ffffff'

    },
    {
      value: '',
      label: 'na',
      icon:'icon-remove'
    },
    {
      value: 1,
      label: 'awb',
      icon: 'icon-plane-outline',
      color:'#559765',
      textColor:'#ffffff'
    }],
    selected: {
      id:1,
      value: '',
      label: 'na',
    },
  };

  importers: DropDownFilterData[] = [];
  _importers: string[] = [];

  suppliers: DropDownFilterData[] = [];
  _suppliers: string[] = [];

  truckers: DropDownFilterData[] = [];
  _truckers: string[] = [];

  status: DropDownFilterData[] = [];
  _status: string[] = [];

  gov_statuses: DropDownFilterData[] = [];
  _gov_statuses: string[] = [];

  courier_type: DropDownFilterData[] = [{value:1, label: 'AWB'},{value:0, label: 'BOL'}];
  _courier_type: any = {
    id:1,
    value: '', 
    label: 'na',
  };

  reset(){
    
    this._fs.reset();

    this._status = []
    this._importers = []
    this._suppliers = []
    this._truckers = []
    this._gov_statuses = []

    this._courier_type = {
      id:1,
      value: '', 
      label: 'na',
    }

    this.courierTypeSettings.selected =  this._courier_type;

    localStorage.removeItem('filters')

  }

  filterChange(evt: any) {
    switch (evt?.payload?.id) {
      case "Importers":
        if (evt?.payload?.type == "search") {
          this._as
            .getAccountAssociationsByRole(
              Role.Importer,
              evt.payload.search,
              this._auth.getClaim(Claim.ID)
            )
            .then((res: any) => {
              this.importers = res;
            });
        }
        // if (evt?.payload?.type == "select") {
        //   this._importers = evt?.payload?.search;
        // }
        break;
      case "Suppliers":
        if (evt?.payload?.type == "search") {
          this._as
            .getAccountAssociationsByRole(
              Role.Supplier,
              evt.payload.search,
              this._auth.getClaim(Claim.ID)
            )
            .then((res: any) => {
              this.suppliers = res;
            });
        }
        // if (evt?.payload?.type == "select") {
        //   this._suppliers = evt?.payload?.search;
        // }
        break;
      case "Truckers":
        if (evt?.payload?.type == "search") {
          this._as
            .getAccountAssociationsByRole(
              Role.Trucker,
              evt.payload.search,
              this._auth.getClaim(Claim.ID)
            )
            .then((res: any) => {
              this.truckers = res;
            });
        }
        // if (evt?.payload?.type == "select") {
        //   this._truckers = evt?.payload?.search;
        // }
        break;
      case "Status":
        // if (evt?.payload?.type == "select") {
        //   this._status = evt?.payload?.search;
        // }
        break;
      case "Gov. Statuses":
        // if (evt?.payload?.type == "select") {
        //   this._gov_statuses = evt?.payload?.search;
        // }
        break;
    }

    if (evt?.payload?.type == "select") {

      this.change.emit({
        type: TypeEnum.MultiFilterSelect,
        payload: {
          search: evt.payload.search,
          type: "select",
          id: String(evt?.payload?.id).replace(". ", "_"),
        },
      });
    }
 
  }

  IFilterSubscriber:Subscription = new Subscriber();

  ngOnInit() {

    const setting = {
      orderBy: {
        column: "name",
        direction: TableOrderByDirection.ASC,
      },
      groupBy: Role.Importer,
      filter: "",
      current: 1,
      size: 10,
    };

    this._bs.getStatusesFilters().then((res: any) => {
      this.status = res;
    });

    this._bs.getGovStatuses().then((res: any) => {
      this.gov_statuses = res;
    });

    this._as
      .getAccountAssociationsByRole(
        Role.Importer,
        "",
        this._auth.getClaim(Claim.ID)
      )
      .then((res: any) => {
        this.importers = res;
      });

    this._as
      .getAccountAssociationsByRole(
        Role.Supplier,
        "",
        this._auth.getClaim(Claim.ID)
      )
      .then((res: any) => {
        this.suppliers = res;
      });

    this._as
      .getAccountAssociationsByRole(
        Role.Trucker,
        "",
        this._auth.getClaim(Claim.ID)
      )
      .then((res: any) => {
        this.truckers = res;
      });
    
    this.IFilterSubscriber = this._is.IFilter.subscribe( (IFilterOptions:TableOptions) => {
      // if(IFilterOptions.page && IFilterOptions.page.filterables){
      //   const filterables:any = JSON.parse(IFilterOptions.page.filterables);
      //   this._status = filterables.status.split(',').filter((v:any) => v != "").map((v:any)=>parseInt(v))
      //   this._importers = filterables.importers.split(',').filter((v:any) => v != "")
      //   this._suppliers = filterables.suppliers.split(',').filter((v:any) => v != "")
      //   this._truckers = filterables.truckers.split(',').filter((v:any) => v != "")
      //   this._gov_statuses = filterables.gov_statuses.split(',').filter((v:any) => v != "").map((v:any)=>parseInt(v))
      //   this._courier_type = filterables.courier_type
      // }
    })
  }

}
