<div class="login__wrapper">
    <div class="login__splash"></div>
    <div class="login__form">
        <img src="../../../assets/branding/Logo.png" alt="Alpha Broker Corp.">
        <div class="spacer__"></div>
        <div class="spacer__"></div>
        <form [formGroup]="form" *ngIf="!forgot && !reset" (ngSubmit)="onSubmit()">
            <div class="field__input" *ngIf="reset_success">
                <p style="text-align: center;line-height: 1.5em;font-size: 1.2em;">Your password has been successfully reset! Please use the form below and login using your new password.</p>
            </div>
            <div class="field__input">
                <label>Email Address</label>
                <input type="text" autocomplete="off" formControlName="username" name="username" [ngClass]="{invalid: submitted && fields.username.errors}">
                <div *ngIf="submitted && fields.username.errors" class="field__error">
                    <div *ngIf="fields.username.errors.required">Email address is required</div>
                </div>
            </div>
            <div class="field__input"> 
                <label>Password</label>
                <input type="password" autocomplete="off" formControlName="password" name="password" [ngClass]="{invalid: submitted && fields.password.errors}">
                <div *ngIf="submitted && fields.password.errors" class="field__error">
                    <div *ngIf="fields.password.errors.required">Password is required</div>
                </div>
            </div>
            <div class="spacer__"></div>
            <div class="spacer__"></div>
            <div class="field__input row" style="justify-content: unset;">
                <button type="submit"  class="button__ primary" style="width:90%">Login</button>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <button type="button" class="button__" (click)="toggleForgot()">Forgot Password</button>
            </div>
        </form>


        
        <form [formGroup]="forgot_form" *ngIf="forgot" (ngSubmit)="onForgotSubmit()">
            <div class="field__input" *ngIf="!forgot_loading && forgot_submitted">
                <p style="text-align: center;line-height: 1.5em;font-size: 1.2em;">Your password has been successfully reset! A confirmation email was sent to {{forgot_fields.email.value}} <br><br>This email may take a few minutes to arrive in your inbox.</p>
            </div>
            
            <div *ngIf="!forgot_loading && !forgot_submitted">
                <div class="field__input">
                    <p></p>
                    <h2 style="text-align: center;">Can't log in?</h2>
                    <p style="text-align: center;line-height: 1.5em;font-size: 1.2em;margin:0;">Restore access to your account</p>
                    <p></p>
                </div>
                <div class="field__input" >
                    <label>Email</label>
                    <input type="text" autocomplete="off" formControlName="email" name="email" [ngClass]="{invalid: forgot_submitted && forgot_fields.email.errors}">
                    <div *ngIf="forgot_submitted && forgot_fields.email.errors" class="field__error">
                        <div *ngIf="forgot_fields.email.errors.required">Email is required</div>
                        <div *ngIf="forgot_fields.email?.errors?.pattern">Invalid Email Address</div>
                    </div>
                </div>
                <div class="spacer__"></div>
                <div class="spacer__"></div>
                <div class="field__input row" style="justify-content: unset;">
                    <button type="submit"  class="button__ primary" style="width:50%">Reset Password</button>
                </div>
            </div>
        </form>

        <form [formGroup]="reset_form" *ngIf="reset" (ngSubmit)="onResetSubmit()">
            <div class="field__input">
                <p></p>
                <h2 style="text-align: center;">Create new password </h2>
                <p style="text-align: center;line-height: 1.5em;font-size: 1.2em;margin:0;">Enter a new password for your account below. </p>
                <p></p>
            </div>
            <div class="field__input"> 
                <label>Password</label>
                <input type="password" autocomplete="off" formControlName="password" name="password" [ngClass]="{invalid:  reset_submitted && reset_fields?.password?.errors}">
                <div *ngIf=" reset_submitted && reset_fields?.password?.errors" class="field__error">
                    <div *ngIf="reset_fields?.password?.errors?.required">Password is required</div>
                </div>
            </div>
            <div class="field__input"> 
                <label>Confirm Password</label>
                <input type="password" autocomplete="off" formControlName="confirm_password" name="confirm_password" [ngClass]="{invalid: reset_submitted && reset_fields?.confirm_password?.errors}">
                <div *ngIf="reset_submitted && reset_fields?.confirm_password?.errors" class="field__error">
                    <div *ngIf="reset_fields?.confirm_password?.errors?.required">Password is required</div>
                    <div *ngIf="reset_fields?.confirm_password?.errors?.mustMatch">Passwords must match</div>
                </div>
            </div>
            <div class="spacer__"></div>
            <div class="spacer__"></div>
            <div class="field__input row" style="justify-content: unset;">
                <button type="submit"  class="button__ primary" style="width:50%">Reset Password</button>
            </div>
        </form>

        
    </div>
</div>