<div class="batch_details__wrapper">
  <div class="loader" [ngClass]="{ active: is_loading }">
    <img src="../../../assets/branding/loader.gif"/>
  </div>
  <div class="detail-wrapper">
    <div class="top">
      <div>
        <h1>
          Batch No. <span>#{{ batch.id }}</span>
        </h1>
      </div>
      <div class="actions">
        <button class="button__ transparent" [routerLink]="['/dashboard/batches']">
          Go Back
        </button>&nbsp;&nbsp;
        <button class="button__ primary" (click)="setRevision()" *ngIf="states.revision">
          Edit Revision
        </button>&nbsp;&nbsp;&nbsp;
        <button class="button__ primary" (click)="editBatch()" *ngIf="((
            batch?.statusLabel == 'Created' ||
            batch?.statusLabel == 'Issue' ||
            batch?.statusLabel == 'Draft') &&
            role != Role.Trucker) ||
            role == Role.Alpha ||
            role == Role.Clerk ||
            role == Role.Manager
          ">
          Edit Batch
        </button>
      </div>
    </div>
    <div class="top">
      <div class="statuses">
        <div>
          <strong>Status:</strong>
          <span class="batch_status {{ selected_status_label?.toLowerCase() }}">{{ selected_status_label }}</span>
        </div>
        <div *ngIf="batch.governmentStatuses && batch.governmentStatuses?.length > 0">
          <strong>Gov. Status:</strong>
          <span><element-tag-values [values]="batch.governmentStatuses" isStatus="true" label="Gov. Statuse"></element-tag-values></span>
        </div>
        <div>
          <strong>Updated On:</strong>
          <span>
            <element-clipboard [value]="batch.updatedOn"></element-clipboard>
          </span>
        </div>

        <div *ngFor="let a of batch.associations">
          <div *ngIf="a.data == null" class="hide-me"></div>
          <strong *ngIf="a.data != null">{{ a.type }}:</strong>
          <span *ngIf="a.data != null" >
						<element-clipboard [value]="a.data.label"></element-clipboard>
          </span>
        </div>

        <div>
          <strong *ngIf="batch.courierType">AWB Number:</strong>
          <strong *ngIf="!batch.courierType">BOL Number:</strong>
          <span style="position: relative" #awbInfoELe>
							<element-clipboard [value]="batch.awbBolNumber"></element-clipboard>
          </span>

        </div>

        <div>
          <strong>ETA:</strong>
          <span>
							<element-clipboard [value]="(batch.eta ? batch.eta+' ' : '')  + (batch.etaTime ? batch.etaTime: '')"></element-clipboard>
          </span>
        </div>

        <div>
          <strong>LFD:</strong>
          <span>
							<element-clipboard [value]="(batch.lfd ? batch.lfd+' ' : '')  + (batch.lfdTime ? batch.lfdTime: '')"></element-clipboard>
          </span>
        </div>

        <div *ngIf="batch.tags">
          <strong>Tags:</strong>
          <span><element-tag-values [values]="batch.tags" label="Tag"></element-tag-values></span>
        </div>

        <div *ngIf="batch.orderNumber">
          <strong>Order Numbers:</strong>
          <span><element-tag-values [values]="batch.orderNumber" label="Order Number"></element-tag-values></span>
        </div>

        <div>
          <strong>LOG Code:</strong>
          <span>
            <element-clipboard [value]="batch.logCode ? batch.logCode : ''"></element-clipboard>
          </span>
        </div>

        <div>
          <strong>Reference Numbers:</strong>
          <span #ele>
            <element-clipboard [value]="batch?.referenceNumber "></element-clipboard>
          </span>
        </div>

        <div>
          <strong>Container Numbers:</strong>
          <span #ele>
            <element-clipboard [value]="batch?.containerNumber"></element-clipboard>
          </span>
        </div>

      </div>
    </div>
  </div>
  <div class="documents">
    <div class="actions">
      <h2>Document List</h2>
      <div *ngIf="selectedDocuments.length > 1">
        <i class="icon icon-eye" (click)="generatePreview()"></i>
        <i class="icon icon-remove" (click)="clearSelectedDocuments()" >{{selectedDocuments.length}} selected</i>
      </div>
      <div (click)="selectAllDocument()" *ngIf="selectedDocuments.length <= 1">
           <span>select all</span>
      </div>
    </div>
    <div class="listing scrollbar__" (wheel)="onWheel($event)">

      <div (click)="previewDocument(doc)"
           *ngFor="let doc of batch?.documents"
           [ngClass]="{ active: document && document.did == doc.did }"
           class="document">

        <i [innerHtml]="doc.name | filetypeIcon : true" class="{{ doc.name | filetypeIcon }}"></i>

        <div>

          <div>
            <strong>TYPE:</strong>
            <p class="truncate">{{ doc.typeName}}</p>
            <br/>
          </div>

          <div>
            <strong>NAME:</strong>
            <p class="truncate">{{ doc.name }}</p>
          </div>

        </div>
        <i class="icon" (click)="selectDocument(doc)" [ngClass]="{'icon-checkmark': isDocumentSelected(doc.did)}" ></i>
      </div>

      <p *ngIf="batch?.documents?.length == 0" style="font-weight: bolder; color: rgb(141, 141, 141)">
        &nbsp;&nbsp;&nbsp;No documents available.
      </p>
    </div>
  </div>
  <div class="bottom">
    <div class="preview">
      <div class="title">
        <h2>
          Document Preview:&nbsp;&nbsp;<span class="truncate" [innerHTML]="previewLabel"></span>
        </h2>
        <div class="actions">
        </div>
      </div>
      <div #viewer id="viewer"></div>
    </div>
    <div class="notes">
      <div class="title">
        <h2>Notes</h2>
        <div class="actions">
          <i class="icon-revision_view" (click)="toggleRevisionMenu()" *ngIf="role != Role.Trucker"><span class="info">Revisions</span></i>
          <div class="revision__overlay" *ngIf="states.menus.revision" (click)="toggleRevisionMenu()"></div>
          <div class="revision__menu" [ngClass]="{ active: states.menus.revision }">
            <h4>Revisions</h4>
            <ul [ngClass]="{ active: states.menus.revision }" class="scrollbar__">
              <li *ngFor="let item of revisions; let i = index"
                  [ngClass]="{ active: item.bid == batch.bid }"
                  (click)="onRevisionSelect(item.bid, i)">
                {{ item.updatedOn }} <span *ngIf="i == 0">current</span>
                <i class="info icon icon-info"><span>Updated By: {{ item.name }}</span></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <textarea disabled [(ngModel)]="batch.notes" style="resize: none">{{batch.notes}}</textarea>
      <element-batch-history [batch]="batch"></element-batch-history>
    </div>
  </div>
</div>
