import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  OnChangePayload,
  TypeEnum,
  DropDownFilterData,
  DropDownFilterOptions,
} from "src/app/models";
import { Offset } from "src/app/helpers/util";

@Component({
  selector: "element-multi-filter",
  templateUrl: "./multi-filter.component.html",
  styleUrls: ["./multi-filter.component.scss"],
})
export class MultiFilterComponent implements OnInit {
  @Input() disableSearch: boolean = false;
  @Input() enableDataSearch: boolean = false;

  @Input() options: DropDownFilterOptions = {};
  @Input() loading: boolean = false;
  @Input() data: DropDownFilterData[] = [];

  prev_data: DropDownFilterData[] = [];

  @Input() value: string = "";
  //@ts-ignore
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter(null);
  //@ts-ignore
  search: string = "";
  toggle: boolean = false;

  @Input() selected: string[] = [];

  bodyOffset: any = document.querySelector("body")?.offsetHeight;
  //@ts-ignore
  @ViewChild("viewpoint") viewpoint: ElementRef;
  //@ts-ignore
  viewpointNative: HTMLDivElement;

  constructor() {}

  ngAfterViewInit() {
    this.viewpointNative = this.viewpoint.nativeElement;
    this.prev_data = this.data;

  }

  ngOnInit(): void {
    this.toggle = false;
    const defaultOptions = {
      placeholder: "Document Types",
      keys: {
        identifier: "dtid",
        label: "name",
        children: "types",
      },
    };
    
    this.options = { ...defaultOptions, ...this.options };
    
    this.prev_data = this.data;

  }

  select(item: any) {
    const exists = this.selected.find((s) => s == item);
    if (exists) {
      this.selected = this.selected.filter((s) => s != item);
    } else {
      this.selected = [...this.selected, item];
    }
    this.change.emit({
      type: TypeEnum.MultiFilterSelect,
      payload: {
        search: Object.assign(this.selected),
        type: "select",
        id: this.options.placeholder,
      },
    });
  }

  searchKeyup(search: any) {

      this.change.emit({
        type: TypeEnum.MultiFilterSearch,
        payload: { search, type: "search", id: this.options.placeholder },
      });
  }

  toggler() {
    this.toggle = !this.toggle;
    const viewpointOffset: any = Offset(this.viewpointNative);
    if (viewpointOffset.top + 300 > this.bodyOffset) {
      this.viewpointNative.style.top = "-300px";
    }
  }
}
