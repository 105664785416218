<element-batch-conflict
  *ngIf="validator.conflict.orders || validator.conflict.awb_bol"
  (event)="initConflictMerge($event)"
  [conflicts]="batchConflicts"
  [current]="draft"
  (update)="change($event)"
></element-batch-conflict>

<div class="batch_preview__wrapper">
  <div class="loader" [ngClass]="{ active: isLoading }">
    <img src="../../../assets/branding/loader.gif"/>
  </div>
  <form
    #form="ngForm"
    [formGroup]="forms.batch.form"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="batch__title">
      <div>
        <h1>
          {{ title }} <span *ngIf="batchType.revise">#{{ draft?.id }}</span>
        </h1>
        <div class="status_label" *ngIf="status_label">
          <strong>Status: </strong>
          <p>
            <span class="batch_status {{ status_label?.toLowerCase() }}">{{
              status_label
              }}</span>
          </p>
        </div>
      </div>

      <div class="batch__actions">
        <button
          class="button__ transparent"
          *ngIf="(!batchType.revise || status_label == 'Draft') && importer"
          (click)="onSaveForLater()"
        >
          Save for Later
        </button>
        <button class="button__" [routerLink]="['/dashboard/batches']">
          Cancel
        </button>
        <button
          class="button__ primary"
          (click)="onSubmit()"
          [disabled]="!forms.batch.valid
	  || forms.batch.form.controls.eta_time.hasError('pattern')
	  || forms.batch.form.controls.lfd_time.hasError('pattern')
	  "
        >
          {{ title }}
        </button>
        <button
          class="button__ primary right_icon"
          (click)="toggleStatusMenu()"
          *ngIf="
            this.batchType.revise &&
            (role == Role.Alpha || role == Role.Clerk || role == Role.Manager)
          "
          [disabled]="!forms.batch.valid
 || forms.batch.form.controls.eta_time.hasError('pattern')
	  || forms.batch.form.controls.lfd_time.hasError('pattern')

"
        >
          <span>Update Status</span>
          <i class="icon-cheveron-down"></i>
        </button>
      </div>
    </div>
    <div class="batch__info">
      <div class="field__input light">
        <label class="truncate"
        >Supplier <span style="color: red">*</span></label
        >
        <element-autocomplete
          *ngIf="!supplier"
          [autofocus]="true"
          [disableToggle]="true"
          [data]="supplier_accounts"
          (onChange)="onChange($event, Role.Supplier)"
          placeholder="Supplier Name"
        ></element-autocomplete>
        <div class="field_input_icon" *ngIf="supplier">
          <input
            *ngIf="supplier"
            type="text"
            autocomplete="off"
            [value]="supplier.label"
            disabled
          />
          <i
            class="icon-remove right cursor"
            (click)="clearAssociation(Role.Supplier)"
          ></i>
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate"
        >Importer <span style="color: red">*</span></label
        >
        <element-autocomplete
          *ngIf="(!importer && supplier) || (!importer && trucker)"
          [disableToggle]="true"
          [data]="importer_accounts"
          (onChange)="onChange($event, Role.Importer)"
          placeholder="Importer Name"
        ></element-autocomplete>
        <div
          class="field_input_icon"
          *ngIf="(importer && supplier) || (importer && trucker)"
        >
          <input
            type="text"
            autocomplete="off"
            [value]="importer.label"
            disabled
          />
          <i
            class="icon-remove right cursor"
            (click)="clearAssociation(Role.Importer)"
          ></i>
        </div>
        <div class="field_input_icon" *ngIf="!supplier && !trucker">
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select {{
              type == Role.Supplier ? 'Supplier' : 'Trucker'
            }} Before Proceeding"
          />
        </div>
      </div>

      <div class="field__input light">
        <div class="courier__type">
          <label *ngIf="courier_type == true" class="truncate"
          >AWB Number <span style="color: red">*</span></label
          >
          <label
            *ngIf="courier_type == false"
            style="color: #4056b6"
            class="truncate"
          >BOL Number <span style="color: red">*</span></label
          >
          <label *ngIf="courier_type == undefined" class="truncate"
          >AWB/BOL Number <span style="color: red">*</span></label
          >
          <div
            class="field-toggle"
            [ngClass]="{
              required:
                (courier_type == undefined &&
                  forms.batch.form.controls['awb_bol_number'].value == '' &&
                  importer &&
                  supplier) ||
                (courier_type == undefined && importer && supplier)
            }"
          >
            <element-state-toggler
              [option]="courier_option"
              (change)="change($event)"
            ></element-state-toggler>
          </div>
        </div>

        <input
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          type="text"
          autocomplete="off"
          #awb_bol_number
          formControlName="awb_bol_number"
          (keyup)="change($event, true)"
        />
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>

      <div class="field__input light" *ngIf="role != Role.Trucker">
        <label class="truncate">Trucker</label>
        <element-autocomplete
          [autofocus]="false"
          *ngIf="
            this.importer &&
            this.supplier &&
            this.courier_type != undefined &&
            !this.trucker
          "
          [disableToggle]="true"
          [data]="trucker_accounts"
          (onChange)="onChange($event, Role.Trucker)"
          placeholder="Trucker Name"
        ></element-autocomplete>
        <div class="field_input_icon" *ngIf="trucker">
          <input
            *ngIf="trucker"
            type="text"
            autocomplete="off"
            [value]="trucker.label"
            disabled
          />
          <i
            class="icon-remove right cursor"
            (click)="clearAssociation(Role.Trucker)"
          ></i>
        </div>
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Please populated all required fields"
          />
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate">Order Number</label>
        <element-multi-selector
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          [values]="forms?.batch?.form?.controls['order_number'].value"
          (change)="onChange($event)"
        ></element-multi-selector>
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Please populated all required fields"
          />
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate">Tags</label>
        <element-multi-selector
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          label="Tag"
          [values]="forms?.batch?.form?.controls['tags'].value"
          (change)="onChange($event)"
        ></element-multi-selector>
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Please populated all required fields"
          />
        </div>
      </div>


      <div class="field__input light" style="width: 25%;">
        <label class="">ETA
          <span class="error" *ngIf="forms.batch.form.controls.eta_time.hasError('pattern')">Invalid time format.</span>

        </label>

        <div
          class="date-field"
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
        >
          <mat-form-field appearance="fill">
            <input
              matInput
              [matDatepicker]="eta_picker"
              formControlName="eta"
              (dateInput)="change($event, true)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="eta_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #eta_picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              name="eta_time_picker"
              format="24"
              [pattern]="timeRegex"
              formControlName="eta_time"
              placeholder="00:00"
              (blur)="forms.batch.form.valid && selectTimeFreeInputChanged($event, 'eta')"
            />
            <span
              class="material-symbols-outlined"
              (click)="eta_time_picker.open()"
            >
              schedule
            </span>
            <ngx-mat-timepicker [disableClick]="true"
                                #eta_time_picker

                                (timeChanged)="change($event, true)"
            ></ngx-mat-timepicker>
          </mat-form-field>
        </div>

        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>


      <div class="field__input light" style="width: 25%;">
        <label class="">LFD

          <span class="error" *ngIf="forms.batch.form.controls.lfd_time.hasError('pattern')">Invalid time format.</span>
        </label>

        <div
          class="date-field"
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
        >
          <mat-form-field appearance="fill">
            <input
              matInput
              [matDatepicker]="lfd_picker"
              formControlName="lfd"
              (dateInput)="change($event, true)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="lfd_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #lfd_picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              name="lfd_time_picker"
              format="24"
              formControlName="lfd_time"
              placeholder="00:00"
              [pattern]="timeRegex"
              (blur)="forms.batch.form.valid && selectTimeFreeInputChanged($event, 'ldf')"
            />
            <span
              class="material-symbols-outlined"
              (click)="lfd_time_picker.open()"
            >
              schedule
            </span>
            <ngx-mat-timepicker
              #lfd_time_picker
              (timeChanged)="change($event, true)"
            ></ngx-mat-timepicker>
          </mat-form-field>
        </div>

        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate">Container Number</label>
        <input
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          type="text"
          autocomplete="off"
          formControlName="container_number"
          (keyup)="change($event, true)"
        />
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate">LOG Code</label>
        <input
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          type="text"
          autocomplete="off"
          formControlName="log_code"
          (keyup)="change($event, true)"
        />
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>

      <div class="field__input light">
        <label class="truncate">Reference Number</label>
        <input
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
          type="text"
          autocomplete="off"
          formControlName="reference_number"
          (keyup)="change($event, true)"
        />
        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div
        class="batch__documents"
        [ngClass]="{ disabled: draft?.bid == null }"
      >
        <element-upload
          [queue]="documents"
          [batchId]="batchId"
          (change)="onChange($event)"
        ></element-upload>
        <element-batch-history
          *ngIf="draft.parentBID"
          [bid]="draft.parentBID"
          [showBanner]="false"
        ></element-batch-history>
      </div>
      <div class="batch__notes">
        <div
          class="disabler"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        ></div>
        <div class="title"><h3>Notes</h3></div>
        <textarea
          formControlName="notes"
          #notes
          (keydown)="textareaOnKeypress($event, notes)"
        ></textarea>
      </div>
    </div>
  </form>
</div>

<element-batch-statuses
  [statuses]="statuses"
  (onChange)="onStatusChange($event)"
  (toggle)="toggleStatusMenu($event)"
  [toggled]="states.menus.status"
  [bid]="draft.bid"
  *ngIf="states.menus.status"
></element-batch-statuses>
