<element-table name="Accounts" [table]="account_table" (onChange)="onTableChange($event)" [actions]="tableActions"></element-table>
<element-modal name="Create Account"  type="create"  [toggle]="forms.create_account.toggled" *ngIf="forms.create_account.toggled">
    <form *ngIf="forms.create_account.form" [formGroup]="forms.create_account.form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="field__wrapper">
            <div class="field__input">
                <label>Customer ID</label>
                <input type="text" autocomplete="off" formControlName="customer_id" name="email" [ngClass]="{invalid:  forms.create_account.submitted && accountFields?.customer_id?.errors}">
                <div *ngIf=" forms.create_account.submitted && accountFields?.customer_id?.errors" class="field__error">
                <div *ngIf="accountFields?.customer_id?.errors?.conflict">Account with customer id already exists.</div>
                <div *ngIf="accountFields?.customer_id?.errors?.required">Customer ID is required</div>
                </div>
            </div>&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="field__input">
                <label>Role</label>
                <select formControlName="role" name="role"  autocomplete="off" [ngClass]="{invalid: forms.create_account.submitted && accountFields?.confirm_password?.errors}">
                    <option *ngFor="let role of forms.create_account.roles" [value]="role.rid">{{role.name}}</option>
                </select>
                <div *ngIf=" forms.create_account.submitted && accountFields?.role?.errors" class="field__error">
                    <div *ngIf="accountFields?.role?.errors?.required">Role is required</div>
                </div>
            </div>
        </div>
        <div class="field__input"> 
            <label>Email Address</label>
            <input type="email" autocomplete="off" formControlName="email" name="email" [ngClass]="{invalid:  forms.create_account.submitted && accountFields?.email?.errors}">
            <div *ngIf=" forms.create_account.submitted && accountFields?.email?.errors" class="field__error">
                <div *ngIf="accountFields?.email?.errors?.required">Email is required</div>
                <div *ngIf="accountFields?.email?.errors?.pattern">Email is invalid</div>
                <div *ngIf="accountFields?.email?.errors?.conflict">Account with email already exists.</div>
            </div>
        </div>
        <div class="field__input"> 
            <label>Password</label>
            <input type="password" autocomplete="off" formControlName="password" name="password" [ngClass]="{invalid:  forms.create_account.submitted && accountFields?.password?.errors}">
            <div *ngIf=" forms.create_account.submitted && accountFields?.password?.errors" class="field__error">
                <div *ngIf="accountFields?.password?.errors?.required">Password is required</div>
            </div>
        </div>
        <div class="field__input"> 
            <label>Confirm Password</label>
            <input type="password" autocomplete="off" formControlName="confirm_password" name="confirm_password" [ngClass]="{invalid: forms.create_account.submitted && accountFields?.confirm_password?.errors}">
            <div *ngIf="forms.create_account.submitted && accountFields?.confirm_password?.errors" class="field__error">
                <div *ngIf="accountFields?.confirm_password?.errors?.required">Password is required</div>
                <div *ngIf="accountFields?.confirm_password?.errors?.mustMatch">Passwords must match</div>
            </div>
        </div>
        <div class="spacer__"></div>
        <div class="spacer__"></div>
        <div class="field__input row" style="border-top: 1px solid #E2E2E2;padding: 1.5em;margin:0 -2em -2em;">
            <button type="button" class="button__" (click)="toggleCreateAccount()">Cancel</button>
            &nbsp;&nbsp;&nbsp;&nbsp; 
            <button type="submit"  class="button__ primary">Create Account</button>
        </div>
    </form>
</element-modal>


<element-modal name="Delete Account" type="delete" [toggle]="forms.delete_account.toggled" *ngIf="forms.delete_account.toggled">
    <div class="field-toggle">
        <input type="checkbox" id="account_deletion_confirmation">
        <label for="account_deletion_confirmation" (click)="deleteAccountConfirmToggle()">
            <div class="switch">
                <div class="dot"></div>
            </div>
            <span>Please toggle to confirm account deletion. </span>
        </label>
    </div>
    <div class="spacer__"></div>
    <div class="spacer__"></div>
    <div class="field__input row" style="border-top: 1px solid #E2E2E2;padding: 1.5em;margin:0 -2em -2em;">
        <button type="button" class="button__" (click)=" toggleDeleteAccount()">Cancel</button>
        &nbsp;&nbsp;&nbsp;&nbsp; 
        <button type="submit"  class="button__ primary" (click)="deleteAccountConfirmation()" [disabled]="!account_deletion_state.confirm">Delete Account</button>
    </div>
    
</element-modal>