<div class="batch__wrapper">
  <div class="batch__title">
    <div>
      <h1>Batch No. <span>#{{batch.id}}</span></h1>
      <div class="batch__info">
        <div>
          <strong>Status:</strong>
          <p><span class="batch_status {{selected_status_label?.toLowerCase()}}">{{selected_status_label}}</span></p>
        </div>
        <div *ngFor="let a of batch.associations">
          <strong *ngIf="a.data != null">{{a.type}}:</strong>
          <p  *ngIf="a.data != null">{{a.data.label}}</p>
        </div>

        <div *ngIf="batch.tags">
          <strong>Tags:</strong>
          <p #ele [innerHTML]="batch?.tags | tableColumnType:tag_column:ele"></p>
        </div>
        <div>
          <strong>Order Numbers:</strong>
          <p #ele [innerHTML]="batch?.orderNumber | tableColumnType:column:ele"></p>
        </div>
        <div>
          <strong>AWB/BOL Number:</strong>
          <p  #ele [innerHTML]="batch.awbBolNumber | tableColumnType:awbFormat:ele"></p>
        </div>
        <div>
          <strong>Updated On:</strong>
          <p>{{batch.updatedOn}}</p>
        </div>
      </div>
    </div>
    <div class="batch__actions">
      <button class="button__ transparent " [routerLink]="['/dashboard/batches']">Go Back</button>&nbsp;&nbsp;
      <button class="button__ primary " (click)="setRevision()" *ngIf="states.revision">
        Edit Revision
      </button>&nbsp;&nbsp;&nbsp;
      <button class="button__ primary " (click)="editBatch()" *ngIf=" ((batch?.statusLabel == 'Created' || batch?.statusLabel == 'Issue')  && role == Role.Alpha) || batch.status == BatchType.Draft">
        Edit Batch
      </button>

    </div>
  </div>
  <div class="batch__inner">
    <div class="batch__documents">
      <div class="title">
        <h2>Document List</h2>
        <!-- <div class="actions">
          <i class="icon-printer" *ngIf="!states.print_all_pending" (click)="downloadBatch()"></i>
          <span  *ngIf="states.print_all_pending" >Processing Documents</span><i class="icon-cog spin-animation"  *ngIf="states.print_all_pending"></i>
        </div> -->
      </div>
      <div class="listing scrollbar__">
        <div class="document" *ngFor="let doc of batch?.documents" (click)="selectDocument(doc)" [ngClass]="{active: document?.did == doc.did}">
          <i class="{{doc.name | filetypeIcon}}" [innerHtml]="doc.name | filetypeIcon:true"></i>
          <div>
            <!-- <div><strong>NAME:</strong>
              <p class="truncate">{{doc.name}}</p>
            </div> -->
            <div><strong>TYPE:</strong>
              <p class="truncate">{{doc.documentType}}</p>
              <p class="truncate">{{doc.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="batch__document_preview">
      <div class="title">
        <h2>Document Preview: <span>{{document?.name}}</span></h2>
        <div class="actions">
          <i class="icon-printer" (click)="downloadPreview()"></i>
          <i class="icon-revision_view" (click)="toggleRevisionMenu()"></i>
            <div class="revision__overlay" *ngIf="states.menus.revision" (click)="toggleRevisionMenu()"></div>
            <div class="revision__menu" [ngClass]="{active: states.menus.revision}">
              <h4>Revisions</h4>
              <ul [ngClass]="{active: states.menus.revision}" class="scrollbar__">
                <li  *ngFor="let item of revisions; let i = index" [ngClass]="{active:item.bid == batch.bid}" (click)="onRevisionSelect(item.bid, i)">{{item.updatedOn}}<span *ngIf="i==0">current</span></li>
              </ul>
            </div>
        </div>
      </div>
      <div class="preview">
        <element-batch-history *ngIf="id" [bid]='id'></element-batch-history>
      </div>
    </div>
  </div>
</div>
<div class="revision__overlay" *ngIf="states.print_all">
  <i class="icon-cog spin-animation"></i>
  <h2>Preparing Documents</h2>
</div>
