import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { TablePageOptions, TableData } from "../elements/table/types";
import { setDateTimezone } from "../helpers/util";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(private http: HttpClient) {}

  async get(pageOptions: TablePageOptions) {
    return await this.http.post<TableData>("email", pageOptions).toPromise();
  }

  async getByDate(date: any) {
    return await this.http
      .post<any[]>("email/by-date", { date: date })
      .toPromise();
  }

  async read(eid: string) {
    if (eid) return await this.http.get("email/read/" + eid).toPromise();
  }

  downloadAttachment(eid: string, aid: string) {
    return this.http.post(
      "email/attachment",
      { eid, aid },
      {
        responseType: "blob",
      }
    );
  }

  downloadAttachments(eid: string) {
    return this.http.post(
      "email/attachment/all",
      { eid },
      {
        responseType: "blob",
      }
    );
  }
}
