import { Component, OnInit } from "@angular/core";
import { config } from "src/app/app.config";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "view-alpha",
  templateUrl: "./alpha.component.html",
  styleUrls: ["./alpha.component.css"]
})
export class AlphaComponent implements OnInit {
  constructor(private http: HttpClient, private router: Router) {}

  async ngOnInit() {
    this.router.navigate(["dashboard", "batches"]);
  }
}
