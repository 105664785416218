import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {AuthenticationService} from "../../services";
import {MessageService} from "../../services";
import {MessageType} from "src/app/models";
import {MustMatch} from "src/app/helpers/util";
import {AuthenticationRpcService} from "../../services/authentication.rpc.service";

@Component({
  selector: "template-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private ms: MessageService,
    private fb: UntypedFormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private authRpc: AuthenticationRpcService
  ) {
  }

  form: UntypedFormGroup = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
  });

  forgot_form: UntypedFormGroup = this.fb.group({
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$"),
      ],
    ],
  });

  reset_form: UntypedFormGroup = this.fb.group(
    {
      password: ["", Validators.required],
      confirm_password: ["", Validators.required],
    },
    {
      validator: [MustMatch("password", "confirm_password")],
    }
  );

  loading = false;
  forgot_loading = false;
  reset_loading = false;

  submitted = false;
  forgot_submitted = false;
  reset_submitted = false;

  returnUrl = "";
  error = "";

  forgot = false;
  reset = false;

  reset_code: string | undefined;
  reset_success = false;

  async ngOnInit() {


    this.returnUrl =
      this.route.snapshot.queryParams.returnUrl || "/dashboard";
    this.route.params.subscribe(async (login) => {
      if (login.code) {
        await this.auth
          .validateResetCode(login.code)
          .then(() => {
            this.reset = true;
            this.reset_code = login.code;
          })
          .catch(() => {
          });
      }
    });
    this.route.queryParamMap.subscribe(() => {
    });
  }

  get fields() {
    return this.form.controls;
  }

  get forgot_fields() {
    return this.forgot_form.controls;
  }

  get reset_fields() {
    return this.reset_form.controls;
  }

  toggleForgot() {
    this.forgot = !this.forgot;
  }

  onForgotSubmit() {
    this.forgot_submitted = true;

    if (this.forgot_form.invalid) {
      return;
    }

    this.forgot_loading = true;

    this.auth
      .forogtPasswrd(this.forgot_fields.email.value)
      .then(() => {
        this.forgot_loading = false;
      })
      .catch(() => {
        this.forgot_loading = false;
      });

    //this.forgot = false;
    //this.reset = true;
    //@ts-ignore
  }

  onResetSubmit() {
    console.log(this.reset_code);
    if (this.reset_code) {
      this.reset_submitted = true;

      if (this.reset_form.invalid) {
        return;
      }
      this.reset_loading = true;

      this.auth
        .resetPassword(
          this.reset_fields.password.value,
          this.reset_fields.password.value,
          this.reset_code
        )
        .then((res) => {
          console.log(res);

          this.reset_loading = false;
          this.forgot = false;
          this.reset = false;
          this.reset_success = true;
        })
        .catch((err) => {
          console.log(err);
        });

      //@ts-ignore
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    //@ts-ignore
    this.auth
      .login(this.fields.username.value, this.fields.password.value)
      .pipe(first())
      .subscribe(
        async (response) => {
          this.ms.set({
            message: `Welcome ${response.username}!`,
            type: MessageType.Success,
            delay: 2500,
          });

          await this.router.navigate([this.returnUrl]);
        },
        (error) => {
          console.log(error);
          this.ms.set({
            message: error.message,
            type: MessageType.Error,
            delay: 2500,
          });

          this.error = error;
          this.loading = false;
        }
      );
  }
}
