<div [formGroup]="range">
    <div class="date-field">

	<mat-form-field appearance="fill">
	    <input matInput [matDatepicker]="start_picker" 
	        formControlName="start"
		placeholder="Choose a Start Date"  (dateInput)="addEvent('input', $event)">
	    <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
	    <mat-datepicker #start_picker></mat-datepicker>
	</mat-form-field>

	<mat-form-field *ngIf="range.value.start">
	    <input matInput
		   name="start_time_picker"
		   format="24"
		   [ngxMatTimepicker]="start_time_picker"
		   formControlName="start_time"
		   placeholder="00:00"
		   readonly />
	    <span class="material-symbols-outlined"  (click)="start_time_picker.open()">
		schedule
	    </span>
	    <ngx-mat-timepicker #start_time_picker (timeSet)="addEvent('start_time',$event)"></ngx-mat-timepicker>
	</mat-form-field>

    </div>
    <div class="date-field" *ngIf="is_range">

	<mat-form-field appearance="fill">
	    <input matInput [matDatepicker]="end_picker" 
	        formControlName="end"
		placeholder="Choose a End Date"  (dateInput)="addEvent('input', $event)">
	    <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
	    <mat-datepicker #end_picker></mat-datepicker>
	</mat-form-field>

	<mat-form-field *ngIf="range.value.end">
	    <input matInput
		   name="end_time_picker"
		   format="24"
		   [ngxMatTimepicker]="end_time_picker"
		   formControlName="end_time"
		   placeholder="23:59"
		   readonly />
	    <span class="material-symbols-outlined" (click)="end_time_picker.open()">
		schedule
	    </span>
	    <ngx-mat-timepicker #end_time_picker (timeSet)="addEvent('end_time',$event)" ></ngx-mat-timepicker>
	</mat-form-field>

    </div>
</div>

