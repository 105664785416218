import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

import {FormsModel} from "src/app/models/forms";

import {
  AccountService,
  BatchService,
  Batch,
  IDocument,
  AuthenticationService,
  MessageService,
} from "src/app/services";

import {
  TypeEnum,
  OnChangePayload,
  Role,
  IValidator,
  DropDownFilterData,
  MessageType,
} from "src/app/models";

import {
  BatchEventPayload,
  BatchEventType,
  BatchType as BatchTypes,
  IStatuses,
} from "src/app/services/batch.service";
import {Claim} from "src/app/models/account";
import {FormatDateInputValue, setSelectionRange} from "src/app/helpers/util";
import {StateToggleOption} from "src/app/elements/state-toggler/state-toggler.component";
import {EmptyGuid} from "src/app/utilities";
import {DocumentRpcService} from "../../services/document.rpc.service";


interface BatchType {
  revise: boolean;
  conflict: boolean;
  new: boolean;
}

@Component({
  selector: "view-create-batch",
  templateUrl: "./create-batch.component.html",
  styleUrls: ["./create-batch.component.scss"],
  providers: [BatchService],
})
export class CreateBatchImporterComponent implements OnInit, OnDestroy {
  title: string = "New Batch";

  batchType: BatchType = {
    revise: false,
    conflict: false,
    new: true,
  };

  status: number = 0;
  //@ts-ignore
  status_label: string;

  draftSubscription: Subscription = new Subscription();
  isLoading: boolean = false;
  draft: Batch = {
    associations: [],
    orderNumber: [],
    tags: [],
    awbBolNumber: "",
    eta: null,
    status: BatchTypes.Temporary,
    documents: [],
    documentsList: [],
    notes: "",
  };

  documentTypesSubscription: Subscription = new Subscription();
  documentTypes: DropDownFilterData = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _as: AccountService,
    private _auth: AuthenticationService,
    private _bs: BatchService,
    private _ms: MessageService,
    private doc: DocumentRpcService
  ) {
  }

  Role = Role;

  type: Role = Role.Importer;

  importer_accounts: any = [];
  trucker_accounts: any = [];
  supplier_accounts: any = [];

  supplier: any = null;
  importer: any = null;
  trucker: any = null;

  forms: FormsModel = {
    batch: {
      form: this.fb.group({
        order_number: [""],
        tags: [""],
        awb_bol_number: ["", Validators.required],
        eta: [{value: "", disabled: true}],
        notes: "",
        courier_type: true
      }),
      fields: null,
      data: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
      valid: false,
    },
  };

  documents: IDocument[] = [];

  isDocumentsValid: boolean = false;

  batchConflicts: Batch[] = [];

  conflictMerge: any = null;

  validator: IValidator = {
    orders: false,
    awb_bol: false,
    importer: false,
    supplier: false,
    trucker: false,
    documents: false,
    courier_type: false,
    conflict: {
      orders: false,
      awb_bol: false,
    },
  };

  disregardConflict: boolean = true;

  statuses: IStatuses | undefined;
  selected_status: number | null = null;
  selected_status_label: string | undefined = "";

  BatchType = BatchTypes;

  courier_type?: boolean;

  courier_option: StateToggleOption = {
    group: 'courier_type',
    data: [{
      value: '0',
      label: 'bol',
      icon: 'icon-freight-outline',
      color: '#4056b6',
      textColor: '#ffffff'
    },
      {
        value: '',
        label: 'na',
        icon: 'icon-remove'
      },
      {
        value: '1',
        label: 'awb',
        icon: 'icon-plane-outline',
        color: '#559765',
        textColor: '#ffffff'
      }],
    selected: {
      id: 1,
      value: '',
      label: 'na',
    },
  };

  async getBatchAndSet(bid: string) {
    const batch = await this._bs.getBatch(bid);
    if (batch) {
      batch.documents = Object.assign(batch?.documentsList);

      this.draft.bid = batch.bid;

      this.documents = await this.doc.getDocuments(batch.bid ?? bid, batch.parentBID) ?? [];

      batch.documents = [];
      batch.documentsList = [];

      this.draft.documents = [];
      this.draft.documentsList = [];
      this.draft.status = batch.status;
      this.draft.eta = batch.eta;
      this.draft.notes = batch.notes;
      this.selected_status = batch.status;

      if (batch.statusLabel) {
        this.status_label = batch.statusLabel;
      }

      this.status = batch.status;
    }

    return batch;
  }

  async ngOnInit() {
    this.forms.batch.form?.controls["order_number"].setValue([]);
    this.forms.batch.form?.controls["tags"].setValue([]);

    this.forms.batch.form?.controls["eta"].setValue(null);

    this.forms.batch.form.valueChanges.subscribe((res) => {
      this.draft.notes = res.notes;
    });

    this.route.params.subscribe(async (draft) => {

      this.isLoading = true;

      if (draft.bid) {
        this.title = "Revise Batch";
        this.batchType.revise = true;
        this.batchType.new = false;
        this.isLoading = true;
        this.disregardConflict = true;

        const batch = await this.getBatchAndSet(draft.bid);
        if (!batch) {
          this._ms.set({type: MessageType.Error, message: 'Invalid batch.', delay: 2000});
          this.router.navigate(['dashboard', 'batches']);
          return;
        }

        if (batch.eta) {
          this.forms.batch.form.controls.eta.setValue(
            FormatDateInputValue(new Date(batch.eta))
          );
        }

        if (batch.parentBID) {
          this.statuses = await this._bs.getBatchStatus(batch.parentBID);
        }

        this.forms.batch.form.controls.notes.setValue(batch.notes);
        this.forms.batch.form.controls.courier_type.setValue(batch.courierType);

        const courier_selected = {
          id: (batch.courierType == true) ? 2 : (batch.courierType == false) ? 0 : 1,
          value: (batch.courierType == true) ? '1' : (batch.courierType == false) ? '0' : '',
          label: (batch.courierType == true) ? 'awb' : (batch.courierType == false) ? 'bol' : '',
          color: (batch.courierType == true) ? '#559765' : (batch.courierType == false) ? '#4056b6' : '#fff',
        };

        this.courier_option.selected = courier_selected;

        this.courier_type = batch?.courierType;

        if (batch.courierType != undefined) {
          this.validator.courier_type = true;
        }

        // batch.isRevision = true;
        batch.isDraft = true;
        batch.isRevision = true;

        this.forms.batch.form.controls.awb_bol_number.setValue(
          batch.awbBolNumber
        );

        //@ts-ignore
        // const revisedBatch: Batch = await this._bs.initAutoSave({
        //   ...batch,
        //   isRevision: true,
        //   isDraft: true,
        // });


        this.draft.eta = batch.eta;

        this.status = batch.status;
        this.draft.notes = batch.notes;

        this.forms.batch.form.controls.notes.setValue(batch.notes);

        if (batch.statusLabel) {
          this.status_label = batch.statusLabel;
        }

        // revisedBatch.documents = Object.assign(revisedBatch.documentsList);
        this.documents = Object.assign(batch.documentsList);

        this._bs.updateDraft(batch);

        this.change(null, true);

        //this._bs.initAutoSaveSystem();
      } else {
        this.title = "Create Batch";
        this.batchType.revise = false;
        this.batchType.new = true;
        this.isLoading = false;

        this.status = this.draft.status;

        const notes = `***********************************************BILLING*****************************************************

03O/30O		Ocean Shipment/ Dry Cargo Entry
03C			Inbond Charge
03V			Inbond Validation
24			FDA
25                    NOAA
21			Special Messenger Fee
O6			Add'l FDA Lines
O6			Add'l NOAA Lines
03R			Remote Entry Fee
03+			10+2	                                                	YES
021			Pier Pass
06			Add'I Lines
22P			Fedex
22P			Fedex
22P			Fedex
14			Cartages and Services
03F
  Fish and Wildlife Services
021			Fish and Wildlife Transmission
O7			ISF BOND
O7			Single Entry Bond

Examination Fees (021 AND 02A) Please Specify Code

02I
02A
02A

Miscellaneous Charges: Please Specify Code

      Import Export
      Documents Transfers
      Port Security Fee
7501		Duty
      CMD FEE CSAV
      WDT	6043
      6043 VALIDATION

Escrow Account Charges / No Billing
`;
        this.forms.batch.form.controls.notes.setValue(notes);

        this._bs.initDraft([
          {
            type: "supplier",
            data: null,
          },
          {
            type: "importer",
            data: null,
          },
          {
            type: "trucker",
            data: null,
          },
        ]);

      }
    });

    this.draftSubscription = this._bs.draft.subscribe(async (res: Batch) => {
      if (this.forms.batch.form && res != null && res.bid != null) {
        this.draft = res;

        this.forms.batch.form.controls["order_number"].setValue(
          this.draft.orderNumber
        );

        this.forms.batch.form.controls["tags"].setValue(this.draft.tags);

        this.forms.batch.form.controls["notes"].setValue(this.draft.notes);

        this.forms.batch.form.controls["courier_type"].setValue(this.draft.courierType);
        this.courier_type = this.draft.courierType;

        this.supplier = this.draft.associations.find(
          (a) => a.type == "supplier"
        )?.data;

        this.importer = this.draft.associations.find(
          (a) => a.type == "importer"
        )?.data;

        this.trucker = this.draft.associations.find(
          (a) => a.type == "trucker"
        )?.data;

      }
    });
  }

  ngOnDestroy() {
    this.draftSubscription.unsubscribe();
  }

  get batchId() {
    return this._bs.draftValue?.bid;
  }

  async initConflictMerge(event: BatchEventPayload) {
    if (event) {
      if (event.type == BatchEventType.ConflictRevise) {
        const batch = await this._bs.getBatch(event.payload.bid);

        if (batch) {

          batch.documents = Object.assign(batch.documentsList);
          //@ts-ignore

          const revisedBatch: Batch = await this._bs.initAutoSave({
            ...batch,
          });

          revisedBatch.documents = Object.assign(revisedBatch.documentsList);

          this._bs.updateDraft({
            ...revisedBatch,
            documents: [...revisedBatch?.documents, ...event.payload?.documents],
            orderNumber: [
              ...revisedBatch?.orderNumber,
              ...event.payload?.orderNumber,
            ],
            tags: revisedBatch?.tags,
          });

          await this._bs.initAutoSave(revisedBatch);
          this.resetConflicts();
        }
      }
    }
  }

  isBatchValid() {
    let isValid = true;

    this.validator.importer = true;
    this.validator.supplier = this.supplier != null;
    this.validator.trucker = true;

    this.validator.awb_bol =
      this.forms.batch.form.get("awb_bol_number")?.errors == null;
    this.validator.orders =
      this.forms.batch.form.get("order_number")?.errors == null;

    if (
      this.documents.filter(
        (d) => !d.dtid || d.dtid == "00000000-0000-0000-0000-000000000000"
      ).length > 0
    ) {
      this.isDocumentsValid = false;
    } else {
      this.isDocumentsValid = true;
    }

    this.validator.documents = this.isDocumentsValid;

    const validator_keys = Object.keys(this.validator);
    const validator_conflict_keys = Object.keys(this.validator.conflict);

    for (let x = 0; x < validator_keys.length; x++) {
      const key = validator_keys[x];
      const value = this.validator[key];
      switch (typeof value) {
        case "boolean":
          if (!value) {
            return false;
          }
          break;
      }
    }

    for (let x = 0; x < validator_conflict_keys.length; x++) {
      const key = validator_conflict_keys[x];
      const value = this.validator.conflict[key];
      switch (typeof value) {
        case "boolean":
          if (value) {
            return false;
          }
          break;
      }
    }

    return isValid;
  }

  hasError(form: string, field: string) {
    if (this.forms[form].form) {
      return this.forms[form].form?.get(field)?.errors;
    }
  }

  async change(evt?: OnChangePayload | KeyboardEvent | any, disable_auto_save = false) {

    if (evt && evt.payload) {
      if (evt?.type == TypeEnum.StateToggler) {

        this.courier_type = (evt.payload.search == '1') ? true : (evt.payload.search == '0') ? false : undefined;

        this.forms.batch.form.controls.courier_type.setValue(this.courier_type);

        this.draft.courierType = this.courier_type;


        if (this.courier_type != undefined || this.courier_type != null) {
          this.validator.courier_type = true;
        } else {
          this.validator.courier_type = false;
        }

        this.isLoading = true;

        await this._bs.initAutoSave(this.draft);

        this.isLoading = false;

      }
    }

    this._bs.updateDraft({
      ...this._bs.draftValue,
      associations: [
        {
          type: "supplier",
          data: this.supplier,
        },
        {
          type: "importer",
          data: {
            Id: this._auth.getClaim(Claim.ID),
            label: this._auth.getClaim(Claim.Actor),
            value: this._auth.getClaim(Claim.ID),
          },
        },
        {
          type: "trucker",
          data: this.trucker,
        },
      ],
      eta: FormatDateInputValue(
        new Date(this.forms.batch.form?.get("eta")?.value)
      ),
      orderNumber: [...this.forms.batch.form?.get("order_number")?.value],
      tags: this.forms.batch.form?.get("tags")?.value,
      awbBolNumber: this.forms.batch.form?.get("awb_bol_number")?.value,
      courierType: this.forms.batch.form?.get("courier_type")?.value,
      status: this.status,
      isDraft: true,
    });

    this.draft = this._bs.draftValue;

    if (evt?.type == TypeEnum.DisregardConflict) {
      this.batchConflicts = [];
      this.validator.conflict.orders = false;
      this.validator.conflict.awb_bol = false;
      this.disregardConflict = true;
    }

    this.forms.batch.valid = this.isBatchValid();
    if (this.forms.batch.valid) {
      this._bs.initAutoSave(this.draft);
    }
  }

  async checkForBatchConflicts() {

    return new Promise(async (resolve, reject) => {

      const _bid: any = this.route.snapshot.paramMap.get("bid") || this.draft.bid;

      if (this.validator.orders && this.validator.awb_bol) {
        this.isLoading = true;

        try {

          this.batchConflicts = await this._bs.checkBatchConflic(
            this.forms.batch.form.get("order_number")?.value,
            this.forms.batch.form?.get("awb_bol_number")?.value,
            _bid,
            this.importer.id
          ) || [];

          if (this.batchConflicts.length > 0) {
            this.validator.conflict.orders = true;
            this.validator.conflict.awb_bol = true;
          } else {
            this.validator.conflict.orders = false;
            this.validator.conflict.awb_bol = false;
          }

          this.isLoading = false;

          resolve(this.batchConflicts);

        } catch (err) {

          reject(err);
        }


      }

    });

  }


  resetConflicts() {
    this.batchConflicts = [];
    this.validator.conflict.orders = false;
    this.validator.conflict.awb_bol = false;
  }

  clearAssociation(role: Role) {
    switch (role) {
      case Role.Supplier:
        this.supplier = null;
        this.importer = null;
        this.trucker = null;
        this.validator.supplier = false;
        this.validator.importer = false;
        this.validator.trucker = false;
        break;
      case Role.Trucker:
        this.trucker = null;
        this.validator.trucker = true;
        break;
      case Role.Importer:
        this.importer = null;
        this.validator.importer = false;
        break;
    }
  }

  today = FormatDateInputValue(new Date());

  textareaOnKeypress(evt: KeyboardEvent, textarea: HTMLTextAreaElement) {
    if (evt.keyCode == 13) {
      let original_pos = textarea.selectionStart;
      let first = textarea.value.slice(0, textarea.selectionStart);
      let second = textarea.value.slice(
        textarea.selectionStart,
        textarea.value.length
      );

      textarea.value = first + "\n" + second;

      setSelectionRange(textarea, original_pos + 1);
    }
  }

  async onSubmit(disableRedirect: boolean = false) {
    this.isLoading = true;

    if (!this.disregardConflict) {
      await this.checkForBatchConflicts();
    }

    if (this.isBatchValid()) {

      if (this.batchType.new) {
        this.draft.status = BatchTypes.Created;
      }

      if (this.selected_status) {
        this.draft.status = this.selected_status;
      }

      this.draft.eta = this.forms.batch.form.controls["eta"].value;
      this.draft.notes = this.forms.batch.form.controls["notes"].value;

      this._bs.updateDraft(this.draft);

      await this._bs.create();

      if (!disableRedirect) {
        this.router.navigate(["/dashboard/batches"]);
      }

    }


  }

  async onSaveForLater() {
    this.draft.eta = this.forms.batch.form.controls["eta"].value;
    this.draft.notes = this.forms.batch.form.controls["notes"].value;
    this._bs.updateDraft(this.draft);

    await this._bs.saveForLater();
    this.router.navigate(["/dashboard/batches"]);
  }

  async discardDraft() {
    if (this.draft.bid) {
      await this._bs.discardDraft(this.draft.bid);
    }
    this.router.navigate(["/dashboard/batches"]);
  }

  async getAccountAssociationsByRole(search: string, role: Role, aid?: string) {
    // if (search.length > 1)
    return await this._as.getAccountAssociationsByRole(role, search, aid);
    // else return [];
  }

  async onChange(data: OnChangePayload, role?: Role) {
    let check_for_conflict = true;
    switch (data.type) {
      case TypeEnum.Filter:
        switch (role) {
          case Role.Trucker:
            this.trucker_accounts = await this.getAccountAssociationsByRole(
              data.payload,
              role
            );
            break;
          case Role.Supplier:
            this.supplier_accounts = await this.getAccountAssociationsByRole(
              data.payload,
              role
            );
            break;
          case Role.Importer:
            this.importer_accounts = await this.getAccountAssociationsByRole(
              data.payload,
              role,
              this.type == Role.Supplier
                ? this.supplier.value
                : this.trucker.value
            );
            break;
        }
        break;

      case TypeEnum.Select:
        switch (role) {
          case Role.Supplier:
            this.supplier = data.payload;
            this.supplier.id = data.payload.value;
            break;
          case Role.Trucker:
            this.trucker = data.payload;
            this.trucker.id = data.payload.value;
            break;
          case Role.Importer:
            this.importer = data.payload;
            this.importer.id = data.payload.value;
            break;
        }
        this.change();
        break;

      case TypeEnum.MultiSelect:
        if (this.forms.batch.form) {
          if (data.id && data.id == "tag") {
            this.forms.batch.form.controls["tags"].setValue(data.payload);
            this.draft.tags = data.payload;
          } else {
            this.forms.batch.form.controls["order_number"].setValue(data.payload);
            this.draft.orderNumber = data.payload;
          }
          check_for_conflict = false;
        }

        if (check_for_conflict) {
          this.change();
        }
        break;


      case TypeEnum.Uploader:
        this.documents = data.payload;
        this.draft.documents = data.payload;

        if (this.documents.filter((d) => !d.dtid || d.dtid == EmptyGuid).length > 0) {

          this.isLoading = false;
          this.isDocumentsValid = false;
        } else {
          this.isDocumentsValid = true;
        }

        this.change(null, true);
        // this.change();
        break;
    }
  }
}
