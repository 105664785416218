<div class="overlay" *ngIf="toggle" (click)="toggler()"></div>
<div class="dropdownm_filter__wrapper">
    <div *ngIf="loading" class="loader"><i class="icon-cog spin-animation"></i>&nbsp;&nbsp;Loading</div>
    <div class="selected" (click)="toggler()" >
        <div><p>{{options.placeholder}}</p> <p>{{selected.length}}</p></div>
        <i class="icon-cheveron-down"></i>
    </div>
    <div #viewpoint class="values field__input light" [ngClass]="{active: toggle}">
        <div class="search field_input_icon" *ngIf="!disableSearch">
            <input [(ngModel)]="search" name="dropdown-filter-search" (keyup)="searchKeyup(search)">
            <i class="icon-search left"></i>
        </div>
        <div class="items scrollbar__" >
            <div class="item" *ngFor="let item of data" #parentEle>
               <label><input type="checkbox" name="group" (click)="select(item[options?.keys?.identifier])" [checked]="selected.indexOf(item[options?.keys?.identifier]) != -1"> <p>{{item[options?.keys?.label]}}</p></label>
            </div>
        </div>
    </div>
</div>