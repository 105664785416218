<div class="wrapper">
  <span *ngIf="_values.length == 0">
    <div class="text-placeholder w20"></div>&nbsp;
    <div class="text-placeholder w70"></div>&nbsp;
    <div class="text-placeholder w40"></div>
  </span>
  <element-clipboard  *ngIf="_values.length == 1" [value]="_values[0]"></element-clipboard>
  <i *ngIf="_values.length > 1" class="icon-cheveron-down"><span>{{label}}s</span></i>
  <ul *ngIf="_values.length > 1">
    <li *ngFor="let value of _values">
      <element-clipboard [value]="value" color="black"></element-clipboard>
    </li>
  </ul>
</div>
