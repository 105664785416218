import { Component, OnInit, Input, ɵConsole } from "@angular/core";
import { OnChangePayload, TypeEnum, Role, MessageType } from "src/app/models";
import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
  TableAction,
  TablePagerLocation,
  TableGroups,
} from "src/app/elements/table/types";

import { Router } from "@angular/router";

import {
  AccountService,
  MessageService,
  AuthenticationService,
  InterfaceService,
} from "src/app/services";
import { Claim } from "src/app/models/account";
import { InterfacePayloadType } from "src/app/services/interface.service";

@Component({
  selector: "element-account-associator",
  templateUrl: "./account-associator.component.html",
  styleUrls: ["./account-associator.component.scss"],
})
export class AccountAssociatorComponent implements OnInit {
  constructor(
    private _as: AccountService,
    private _auth: AuthenticationService,
    private router: Router,
    private _is: InterfaceService,

    private _ms: MessageService
  ) {}
  Role = Role;

  //@ts-ignore
  @Input() role: Role;
  //@ts-ignore

  @Input() aid: string;

  loaded: boolean = false;

  groups: TableGroups[] = [];

  //@ts-ignore
  account_table: TableData;

  account_association_data: any = [];

  //@ts-ignore
  authenticatedRole: Role;

  //@ts-ignore
  account_association_role: Role;

  tableActions: TableAction[] = [];

  ngOnInit(): void {
    console.log(this.role);
    this.setTableRole();
    this.authenticatedRole = this._auth.getClaim(Claim.Role);

    if (this.authenticatedRole == Role.Alpha) {
      this.tableActions = [
        {
          onClick: false,
          name: "delete",
          icon: "icon-trash",
          function: this.deleteAccount,
          router: this.router,
          scope: this,
        },
      ];
    }
  }

  setTableRole() {
    switch (this.role) {
      case Role.Importer:
        this.groups = [
          { id: Role.Supplier, label: Role.Supplier },
          { id: Role.Trucker, label: Role.Trucker },
        ];
        this.account_association_role = Role.Supplier;
        break;
      case Role.Supplier:
        this.groups = [{ id: Role.Importer, label: Role.Importer }];
        this.account_association_role = Role.Importer;
        break;
      case Role.Trucker:
        this.groups = [{ id: Role.Importer, label: Role.Importer }];
        this.account_association_role = Role.Importer;
        break;
    }

    this.account_table = {
      data: [],
      options: {
        layout: {
          pagerLocation: TablePagerLocation.Bottom,
          footer: false,
          header: true,
        },
        columns: [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
        ],
        groups: this.groups,
        page: {
          orderBy: {
            column: "name",
            direction: TableOrderByDirection.ASC,
          },
          groupBy: this.account_association_role,
          filter: "",
          current: 1,
          size: 10,
        },
      },
    };
  }

  deleteAccount(
    event: any,
    item: any,
    that: {
      _as: { remvoeAccountAssociation: (arg0: any) => Promise<any> };
      _ms: {
        set: (arg0: { type: MessageType; message: any; delay: number }) => void;
      };
      reloadTable: () => void;
    }
  ) {
    that._as
      .remvoeAccountAssociation(item)
      .then((res: any) => {
        that._ms.set({
          type: MessageType.Success,
          message: "Account has been removed.",
          delay: 2500,
        });
        that.reloadTable();
      })
      .catch((err: { message: any }) => {
        that._ms.set({
          type: MessageType.Error,
          message: err.message,
          delay: 2500,
        });
      });
  }

  async onTableChange(event: OnTableChangePayload) {
    if (
      event.type == TablePayloadType.Next ||
      event.type == TablePayloadType.Previous
    ) {
      if (this.loaded) {
        this.account_table.options.page.current =
          event.type == TablePayloadType.Next
            ? event.payload.page.current + 1
            : event.payload.page.current - 1;
      }
    }

    if (event.type == "groupBy") {
      this.account_association_role = event.payload.page.groupBy;
    }

    console.log(event);

    this.loaded = true;

    this.reloadTable();
  }

  async reloadTable() {
    const payload = await this._as.getAccountAssociations(
      this.aid,
      this.account_table.options.page
    );
    if(payload){
	this.account_table.data = payload.data;
	this.account_table.options.page = payload.options.page;
    }
    this._is.interfaceBS.next({type: InterfacePayloadType.Loading});

  }

  async getAccountsByRole(search: string) {
    return await this._as.getAccountsByRole(
      this.account_association_role,
      search
    );
  }

  async onChange(data: OnChangePayload) {
    switch (data.type) {
      case TypeEnum.Filter:
        this.account_association_data = await this.getAccountsByRole(
          data.payload
        );
        break;
      case TypeEnum.Select:
        const accountAssocitation = this._as
          .addAccountAssociation({
            aid: this.aid,
            aida: data.payload.value,
          })
          .then((res) => {
            const account_exists = this.account_table.data?.find(
              (a: { aid: any }) => a.aid == res.AID
            );
            if (account_exists) {
              this._ms.set({
                type: MessageType.Error,
                message: "Account has already been associated. ",
                delay: 2500,
              });
            } else {
              this.reloadTable();

              this._ms.set({
                type: MessageType.Success,
                message: "Account has been associated.",
                delay: 2500,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this._ms.set({
              type: MessageType.Error,
              message: "Account was not associated.",
              delay: 2500,
            });
          });

        break;
    }
  }
}
