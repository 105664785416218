import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component,  ComponentFactoryResolver,  
    ComponentRef,  ElementRef,  Input,  OnInit,  QueryList,  
    ViewChild,  ViewChildren,  ViewContainerRef} from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { DropDownFilterOptions,  OnChangePayload,  Role} from 'src/app/models';
import { Claim } from 'src/app/models/account';
import { AccountService, AuthenticationService, BatchService, WorkspaceService } from 'src/app/services';
import { InterfacePayload,  InterfacePayloadType,  InterfaceService} from 'src/app/services/interface.service';
import { MultiFilterComponent } from '../multi-filter/multi-filter.component';
import { StateTogglerComponent } from '../state-toggler/state-toggler.component';
import { AvailableColumns, Column, Fields, FilterOptions  } from './list-filter.settings';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { TextFieldComponent } from '../text-field/text-field.component';

@Component({
  selector: 'element-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
  currentRoute: string;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver, 
    private _is: InterfaceService, 
    private _bs:BatchService, 
    private _as:AccountService,
    private _auth: AuthenticationService,
    private _ws:WorkspaceService,
    private changeDetectorRef:ChangeDetectorRef,
    public router: Router

  ) {
    this.currentRoute = "";

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show progress spinner or progress bar
      }

      if (event instanceof NavigationEnd) {
          // Hide progress spinner or progress bar

          const that = this;
          this.currentRoute = event.url;
          if(event.url == '/dashboard/batches'){

            setTimeout(()=>{

              const saved_filter = localStorage.getItem('filters');

              if(saved_filter){

                const parsed_filter = JSON.parse(saved_filter);

                const active_filter = that.filters.find((c:any)=>c.awid == parsed_filter.page.groupBy);

                that._is.interfaceBS.next({
                  type: InterfacePayloadType.ReloadWorkspace ,
                  payload: {
                    filter:active_filter,
                    filters:that.filters.filter(f=> f.active )
                  }
                });
              }else{
                
                that._is.interfaceBS.next({
                  type: InterfacePayloadType.ReloadWorkspace ,
                  payload: {
                    filter:that.filters[0],
                    filters:that.filters.filter(f=> f.active )
                  }
                });
              }

            },500);

          }
      }

      if (event instanceof NavigationError) {
          // Hide progress spinner or progress bar
          // Present error to user
          console.log(event.error);
      }
    });
    
  }
    
  @ViewChildren('dynamic', {
    read: ViewContainerRef
  }) public elementTargets!: QueryList < ViewContainerRef > ;
  
  available_columns:AvailableColumns[] = [{columns: [] }];
    
  filters: FilterOptions[] = [];
  
  fields:Fields[] = [];

  is_drop_event = false;
  
  @Input() filter: FilterOptions = {
    name: '',
    keyword: '',
    columns: [],
    filters: {}
  }; 

  states = {
    panel: false,
    adjust: true,
    saved: false,
    is_new: false,
    is_saving: false,
    active_filter_count: 0,
    filter_just_added: '',
    idx_filter_just_added: 0,
    change_detect: false,
  };

  add_column_option: DropDownFilterOptions = {
    placeholder: "Add Columns",
    icon: "icon-add",
    hide_label: true,
    keys: {
      identifier: "value",
      label: "name",
      children: "columns",
    }
  };

  is_observer: any = null;
  initial_load = false;
  @ViewChild('name') name:ElementRef | undefined;

  get has_filter_changed(){
    return this.filter?.columns?.filter(c=> c.changed).length > 0;
  }

  async ngOnInit() {

    let filters = await this._ws.list();
    const default_filters:FilterOptions[] = await this._ws.list_defaults() || [];
    
    if(!filters){
      filters = default_filters;
    }else{
      filters = [ ...default_filters,...filters,];
    }

    if(filters){
      this.filters = [...filters];

      this.filter =   Object.assign(this.filters[0]);
      
      this.states.is_new = false;

      this.states.active_filter_count = this.filter?.columns?.length;

      this.reload();

      this._is.interfaceBS.next({
        type: InterfacePayloadType.SetFilters,
        payload: {
          toggled: this.states.panel,
          filter: this.filter,
          filters: this.filters.filter(f=> f.active )
        }

      });

    }

    if(this.filter.columns.length > 0 && Object.keys(this.filter.filters).length > 0){
      this.is_drop_event = true;
    }

    this.is_observer = this._is.interface.subscribe( async (data: InterfacePayload) => {
        
      switch (data.type) {
        case InterfacePayloadType.Keyword:
          this.filter.keyword = data.payload.search;
          await this.update_filter(true);
          break;
        case InterfacePayloadType.WorkspaceTabClick:
          if(data.payload.type == 'on-tab-click' && data.payload.filter){
            this.filter = data.payload.filter;
            this.states.is_new = false;

            if(data.payload.groupBy){
              const filter = this.filters.find(f=> f.awid == data.payload.groupBy);
              if(filter){
                this.filter = filter;
              }
            }
          }
          break;
        case InterfacePayloadType.OnInit:
          if(data.payload.type == 'on-tab-click' && data.payload.filter){
            if(data.payload.filter.groupBy){
              const filter = this.filters.find(f=> f.awid == data.payload.filter.groupBy);
	      if(filter){
                this.filter = filter;
                
              }
            }
          }
          break;
        case InterfacePayloadType.ListFilter:
	    if(data.payload.filter){
	      this.filter = data.payload.filter;
	    }

	    if(data.payload.toggled !== undefined){
	      this.states.panel = data.payload.toggled;
	      if(data.payload.toggled){
		this.load_fields();
	      }
	    }
	    
	    this.reload();
	    break;
        case InterfacePayloadType.CreateWorkspaceToggle:
          if (data.payload.is_new) {
            this.states.is_new = true;
            this.states.panel = true;

            this.filter = {
              name: '',
              keyword: '',
              columns: [],
              filters: {}
            };
            
            this.load_fields();
          }
          break;
        case InterfacePayloadType.Filter:
          if(data.payload.filter){
            this.filter = data.payload.filter;
          }
          break;
      }
// console.log(this.filter)
    });

    await this.load_fields();

  }


  async load_fields(){
     
    const fields = await this._ws.get_fields();

    if(fields && fields.length > 0){
      this.available_columns = [ 
        {
          columns: Object.assign(fields),
          name: 'Add Columns',
          value: 'add_columns' 
        }
      ];
    }
  }
  
  ngOnDestroy() {
    this.is_observer.unsubscribe();
  }

   ngAfterViewInit() {

    this.elementTargets.changes.subscribe((list: QueryList < ViewContainerRef > ) => {

      if(this.filter &&  this.filter.columns){

        const data = this.filter.columns.find(c => c.value == this.states.filter_just_added);
        const data_idx = this.filter.columns.findIndex(c => c.value == this.states.filter_just_added);
    
        const comp = list.toArray()[data_idx];

        if (data && comp) {
          
          this.loadFilterElement(data, comp, this.is_drop_event);

          if(this.is_drop_event){
            this.is_drop_event = false;
          }
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  
  }

  async duplicate_workspace(awid:string){

	const filter =  await this._ws.duplicate(awid);
	
	if(filter){
	    this.filters.push(filter);

	    this._is.interfaceBS.next({
		type: InterfacePayloadType.CreateWorkspace,
		payload: {
		  is_new: false,
		  filter: this.filter,
		  filters: this.filters.filter(f=> f.active )
		}
	    });

	    this._is.interfaceBS.next({
		type: InterfacePayloadType.GroupBy,
		payload: {
		  filter,
		}
	    });
	}

  }
  clear_filter(){
    if(this.filter){
	this.filter.keyword = '';
	Object.keys(this.filter.filters).forEach((key)=>{
	    this.filter.filters[key] = [];		
	});

	this._is.interfaceBS.next({
	    type: InterfacePayloadType.ListFilter,
	    payload: {
	      filter: this.filter,
	      filters: this.filters.filter(f=> f.active )
	    }
	});

	this.update_filter();
    }
  }

  reload_dynamic_lists(){
    setTimeout(()=>{

      const comp:any =  this.elementTargets;
      
      for(let i = 0; i < this.filter.columns.length; i++){
        
        const data = this.filter.columns[i];

        if((data.value == 'tags') && data && comp &&  comp._results[i]){
            this.loadFilterElement(data, comp._results[i], true);
            this.changeDetectorRef.detectChanges();
        }

      }
      
    },10);
  }

  reload(){
      setTimeout(()=>{

        const comp:any =  this.elementTargets;
        
        for(let i = 0; i < this.filter.columns.length; i++){
          
          const data = this.filter.columns[i];

          if(data && comp &&  comp._results[i]){
            this.loadFilterElement(data, comp._results[i], true);
            this.changeDetectorRef.detectChanges();
          }
          
        }
        
      },10);
  }



  drop(event: CdkDragDrop < string[] > ) {

    this.is_drop_event = true;

    moveItemInArray(this.filter.columns, event.previousIndex, event.currentIndex);

    this.filter.columns.forEach((c) => {c.changed=true;});

    this._is.interfaceBS.next({
      type: InterfacePayloadType.ListFilter,
      payload: {
        filter: this.filter,
        filters: this.filters.filter(f=> f.active )
      }
    });

  }

  drop_saved(event: CdkDragDrop < string[] > ) {

    moveItemInArray(this.filters, event.previousIndex, event.currentIndex);

    if(this.filters && this.filters.length > 0){

      const order_request:any[] = this.filters.map((f)=>f.awid);

      if(order_request){
        this._ws.order_workspaces(order_request);
      }

      this._is.interfaceBS.next({
        type: InterfacePayloadType.ListFilter,
        payload: {
          filter: this.filter,
          filters: this.filters.filter(f=> f.active )
        }
      });

    }
 
  }

  async toggle_workspace_active_flag(_event:Event | undefined, filter:FilterOptions){
    if(filter.awid){

      const filter_obj = this.filters.find(f=> f.awid == filter.awid);

      if(filter_obj){
        filter_obj.active = !filter_obj.active;
        await this._ws.toggle_workspace(filter.awid, filter_obj?.active);
      }

      this._is.interfaceBS.next({
        type: InterfacePayloadType.ListFilter,
        payload: {
          filter: this.filter,
          filters: this.filters.filter(f=> f.active )
        }
      });
    }
  }


  async loadFilterElement(filter: any, target: ViewContainerRef, reload: boolean = false) {

    target.clear();

    let active_type:any = null;

    switch (filter.typeId) {
      case 'text_field':
        active_type = TextFieldComponent;
        break;
      case 'date_filter':
        active_type = DatePickerComponent;
        break;
      case 'multi_filter':
        active_type = MultiFilterComponent;
        break;
      case 'state_toggler':
        active_type = StateTogglerComponent;
        break;
      default:
        active_type = MultiFilterComponent;
    }

    const widgetComponent = this.componentFactoryResolver.resolveComponentFactory(active_type);
    const componentRef:ComponentRef<typeof filter.type> = target.createComponent<typeof active_type>(widgetComponent);
    
    if(filter.options){
      componentRef.instance.options = filter.options;
    }

    if(this.available_columns && this.available_columns.length > 0 && this.available_columns[0].columns){

      const claim = this._auth.getClaim(Claim.ID);
      const type:Fields = {...this.available_columns[0].columns.find(o=>o.value = filter.value)};

      if(type){
        let data:any = null;
        let pre_data:any = null;
        let role = Role.Supplier;
        
        switch(filter.value){
          case 'tags':
	    componentRef.instance.loading = true;
	    data = await this._bs.getTags(this.filter);
	    if(data){
		pre_data = [...data];
	    }
	    componentRef.instance.enableDataSearch = true;
	    componentRef.instance.loading = false;
	    break;
          case 'status':
	    componentRef.instance.loading = true;
	    data = await this._bs.getStatusesFilters();
	    componentRef.instance.loading = false;
	    break;
          case 'gov_status':
            componentRef.instance.loading = true;
            data = await this._bs.getGovStatuses();
            componentRef.instance.loading = false;
            break;
          case 'suppliers':
            role = Role.Supplier;
            break;
          case 'truckers':
            role = Role.Trucker;
            break;
          case 'importers':
            role = Role.Importer;
            break;
          case 'courier_type':
            if(type.options){
		componentRef.instance.options = type.options.data;
            }
            break;
        }

        if(filter.value == 'suppliers' || filter.value == 'truckers' || filter.value == 'importers'){
          componentRef.instance.loading = true;
          data = await this._as.getAccountAssociationsByRole(role,"",claim);
          componentRef.instance.loading = false;
          
        }
  
        type.data = data;

        if(type && type.data){
          //ts-ignore
            componentRef.instance.data = type.data;
        }

        if(componentRef?.instance?.change?.subscribe){

          type.onChange = componentRef.instance.change.subscribe( async (res:any)=>{
          
            let value = res.payload.search;
 
            //Normailize the value to the correct type
            if(!Array.isArray(value)){
              value = [String(value)];
            }else{
              value = value.map(v=>String(v));
            }

            this.filter.filters[filter.value] = value;
        
            filter.changed = true;
            
            if(filter.value == 'status' || filter.value == 'gov_status'){
              componentRef.instance.disableSearch = true;
            }
    
            if(res.payload.type == 'search'){

              if(filter.value == 'tags'){
                const filter_data = pre_data.filter((o:any)=> {
                  return o[filter.options.keys.label].includes(res.payload.search);
                });
                componentRef.instance.data = filter_data;
              }else{
                this._as.getAccountAssociationsByRole(role,res.payload.search,claim)
                .then((response: any) => {
                  componentRef.instance.data = response;
                });
              }

            }

            if(res.payload.type == 'select'){
   
              if(filter.value == 'gov_status' || filter.value == 'status'){
               value =  value.map((v:any) => parseInt(v, undefined));
              }

              componentRef.instance.selected = value;

              // this._fs.setFilterables( this.filter.filters[filter.value], filter.value);

              this._is.interfaceBS.next({
                type: InterfacePayloadType.Filter,
                payload: {
                  filter: this.filter,
                }
              });
             
              this.reload_dynamic_lists();
            }
  
            componentRef.changeDetectorRef.detectChanges();
  
          });

          if(filter.value == 'status' || filter.value == 'gov_status'){
            componentRef.instance.disableSearch = true;
          }

        }

        // Detect changes on injected component
        if(reload){
          if(filter.value == 'gov_status' || filter.value == 'status'){
            //Normalize status ids to ints to match server response. 
	    //TODO: Remove this when server response is fixed. Response should return a string array.
	    if(this.filter.filters[filter.value]){
            componentRef.instance.selected = this.filter.filters[filter.value].map(v=>parseInt(v, undefined));
	    }
          }else{
            componentRef.instance.selected = this.filter.filters[filter.value];
          }
        }
      
      }
    } 
    componentRef.changeDetectorRef.detectChanges();
  }

  add_column_change(event: OnChangePayload) {
    this.load_fields();
    if (!this.filter.columns.find(c => c.value == event.payload.value)) {
      const column:Column = {
        name: event.payload.name, 
        value: event.payload.value, 
        typeId: event.payload.typeId
      };

      if(event.payload.options){
        column.options = event.payload.options;
      }

      this.filter.columns.push(column);

      this.filter.filters[event.payload.value] = [];
      this.states.filter_just_added = event.payload.value;
      this.states.idx_filter_just_added = this.states.active_filter_count;
      this.states.active_filter_count++;

      this._is.interfaceBS.next({
        type: InterfacePayloadType.SetUpFilterColumns,
        payload: {
          filter: this.filter,
        }
      });

    }
  }

  async update_filter(ignore_query:boolean = false){
      
    if(this.filter.awid != 'ed51b2ea-7dea-47c1-b6e6-0ac4e404e4d0' && this.filter.awid != 'ed51b2ea-7dea-47c1-b6e6-0ac4e404e4d1'){
	const filter = await this._ws.update(this.filter);
	if(filter){
	    this.filter = filter; 
	} 
    }

    this.filter.columns.forEach((c) => {c.changed=false;});
    this.reload();

    if(!ignore_query){
      this._is.interfaceBS.next({
        type: InterfacePayloadType.QueryFilter
      });
    }


    this._is.interfaceBS.next({
      type: InterfacePayloadType.ToggleOffFilter
    });
  }



  async create_filter(_event:any){
    if(this.filter.name != '' && this.filter.name != null ){
	  
	this.states.panel = false;
	this.states.saved = false;
	this.states.adjust = true;
	 
	this.states.is_saving = true;

	const filter =  await this._ws.create(this.filter);
	
	if(filter){
	    this.filters.push(filter);

	    await this.toggle_workspace_active_flag(undefined,  filter);

	    this._is.interfaceBS.next({
		type: InterfacePayloadType.CreateWorkspace,
		payload: {
		  is_new: false,
		  filter: this.filter,
		  filters: this.filters.filter(f=> f.active )
		}
	    });
	      
	    this._is.interfaceBS.next({
		type: InterfacePayloadType.GroupBy,
		payload: {
		  filter,
		}
	    });
	}

	this.reload();
	this.states.is_saving = false;

	if(this.name){
	    this.name.nativeElement.style.borderColor = 'transparent';
	}
    }else{
	if(this.name){
	    this.name.nativeElement.style.borderColor = 'red';
	}
    }
  }

  async delete_filter(){
    const filter = await this._ws.delete(this.filter); 
    if(filter){
	this.filter = filter;
    }
  }

  async get_filter(){
    const filter = await this._ws.delete(this.filter); 
    if(filter){
	this.filter = filter;
    }
  }

  async get_filters(){
    this.filters = await this._ws.list() || [];
    const defaults = await this._ws.list_defaults() || [];
    this.filters = [...defaults, ...this.filters];
  }

  remove_column(column: any) {
    this.filter.columns = this.filter.columns.filter(c => c.value != column.value);
    delete this.filter.filters[column.value];
    this.states.active_filter_count--;
    this._is.interfaceBS.next({
      type: InterfacePayloadType.ListFilter,
      payload: {
        filter: this.filter,
        filters: this.filters.filter(f=> f.active )
      }
    });
  } 

  update_name(input: HTMLInputElement) {
    this.filter.name = input.value;
    if(!this.states.is_new){
      this.filter.columns.forEach((c) => {c.changed=true;});
      this._is.interfaceBS.next({
        type: InterfacePayloadType.ListFilter,
        payload: {
          filter: this.filter,
          filters: this.filters.filter(f=> f.active )
        }
      });
    }
  }

  update_keyword(input: HTMLInputElement) {
    this.filter.keyword = input.value;
    if(!this.states.is_new){
      this.filter.columns.forEach((c) => {c.changed=true;});
      this._is.interfaceBS.next({
        type: InterfacePayloadType.ListFilter,
        payload: {
          filter: this.filter,
          filters: this.filters.filter(f=> f.active )
        }
      });
    }
  }

  async onUpdate() {
    this.states.panel = false;
    this.states.saved = false;
    this.states.adjust = true;
    this.is_drop_event = true;
    this.states.is_saving = true;
    
    // this._is.interfaceBS.next({
    //   type: InterfacePayloadType.ListFilter,
    //   payload: {
    //     toggled:false,
    //     filter: this.filter,
    //     filters: this.filters.filter(f=> f.active )
    //   }
    // });
    
    await this.update_filter();
    
    this.states.is_saving = false;
  }

  async onCreate(event: any) {


    this.create_filter(event);

  }

  previewWorkspace(filter:FilterOptions){
    this._is.interfaceBS.next({
      type: InterfacePayloadType.CreateWorkspace,
      payload: {
        toggled:true,
        filter: this.filters.find(f=> f.awid == filter.awid ),
        filters: this.filters
      }
    });
    this.states.adjust = true;
    this.states.saved = false;
    this.reload();

  }

  toggleDeleteWorkspace(filter:FilterOptions){
      filter.toggle_delete = !filter.toggle_delete;
  }

  async deleteWorkspace(filter:FilterOptions){
    await this._ws.delete(filter);
    this.filters = this.filters.filter(f=> f.awid != filter.awid);
    this._is.interfaceBS.next({
      type: InterfacePayloadType.ListFilter,
      payload: {
        filter:this.filter,
        filters:this.filters.filter(f=> f.active )
      }
    });
  }

  trigger_saved_pane() {
    this.states.saved = true;
    this.states.adjust = false;
  }

  trigger_adjust_pane() {
    this.states.adjust = true;
    this.states.saved = false;
  }

  trigger() {
    this.states.panel = !this.states.panel;
    this._is.interfaceBS.next({
      type: InterfacePayloadType.ListFilter,
      payload: {
        toggled: this.states.panel
      }
    });
  }

}

