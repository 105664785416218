<div class="multi_selector__input_wrapper cursor" (click)="toggler()">
    <p>{{(values.length == 0)? label:(values.length>1)? values.length+' '+label+'s': values[0]}}   </p><i class="icon-edit" ></i>
</div>

<div class="overlay cursor" *ngIf="toggle" (click)="toggler()"></div>

<div class="multi_selector__wrapper" [ngClass]="{active: toggle}">
    <label>{{label}}</label>
    <div class="info"><i class="icon-info"></i><p>Press comma, enter or tab to add the entry below.</p></div>
    <input  type="text" #input  autocomplete="off" (keyup)="keyup($event)" (keydown)="keydown($event)" [placeholder]="(values.length > 0)? 'Add another order number': 'Add a order number'">
    <div class="multi_selector_values scrollbar__">
        <span *ngFor="let value of values">{{value}}<i class="icon-remove" (click)="remove(value)"></i></span>
    </div>
    <div class="multi_selector_actions">
        <button class="button__ primary" (click)="toggler()">COMPLETE</button>
    </div>
</div>