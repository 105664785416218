<div class="overlay"  *ngIf="states.modal"></div>
<div class="notice__wrapper" *ngIf="states.notice">
  <div class="message">
    <i class="icon-warning"></i>
    <p>Batches with the same {{(validator.awb_bol)?'AWB/BOL Number':''}}{{(validator.awb_bol && validator.orders)?' and ':''}}{{(validator.orders)?'Order Number':''}} already exists.</p>
  </div>
  <div class="actions">
    <button class="button__ outline" (click)="toggleModal()">Resolve Conflict</button>
  </div>
</div>
<div class="modal__wrapper__outer" *ngIf="states.modal">
  <div class="modal__wrapper__inner disable-select " *ngIf="states.modal">
    <div class="header">
      <div class="title">
        <h2>Batch Exists</h2>
        <p>Batches with the same {{(validator.awb_bol)?'AWB/BOL Number':''}}{{(validator.awb_bol && validator.orders)?' and ':''}}{{(validator.orders)?'Order Number':''}} already exists.</p>
      </div>
      <i class="icon-warning"></i>
      <i class="icon-remove" (click)="toggleModal()"></i>
    </div>
    <div class="content">
      <div class="current">
        <div class="title">
            <strong>NEW BATCH</strong>
        </div>
        <div class="batch_info">
          <div>
            <strong>IMPORTER</strong>
            <p>{{getAssociations('importer', current) }}</p>
          </div>
          <div>
            <strong [ngClass]="{conflict: validator.awb_bol}">AWB/BOL NUMBER</strong>
            <p>{{current?.awbBolNumber}}</p>
          </div>
          <div>
            <strong [ngClass]="{conflict: validator.orders}">ORDER NUMBERS</strong>
            <p #ele [innerHTML]="current?.orderNumber | tableColumnType:column:ele:conflict"></p>
          </div>

        </div>
        <div class="batch_documents scrollbar__">
            <div class="document" *ngFor="let doc of current?.documents">
                <i class="{{doc.name | filetypeIcon}}" [innerHtml]="doc.name | filetypeIcon:true"></i>
                <div>
                    <div><strong>NAME:</strong><p class="truncate">{{doc.name}}</p></div>
                    <div><strong>TYPE:</strong><p class="truncate">{{doc.documentType}}</p></div>
                </div>
            </div>
        </div>
      </div>
      <div class="conflict">
        <div class="title">
            <strong>CONFLICTING BATCH </strong>
            <div class="pager">
                <div>
                    <strong>Conflicting Batches</strong>
                    <p>{{this.states.conflict.index+1}} of {{this.conflicts.length}}</p>
                </div>
                <i class="icon-cheveron-left" (click)="pager('back')"></i>
                <i class="icon-cheveron-right" (click)="pager('next')"></i>
            </div>
        </div>
        <div class="batch_info">
          <div>
            <strong>IMPORTER</strong>
            <p>{{getAssociations('importer', conflict)}}</p>
          </div>
          <div>
            <strong [ngClass]="{conflict: validator.awb_bol}">AWB/BOL NUMBER</strong>
            <p>{{conflict?.awbBolNumber}}</p>
          </div>
          <div>
            <strong [ngClass]="{conflict: validator.orders}">ORDER NUMBERS</strong>
            <p #ele [innerHTML]="conflict?.orderNumber | tableColumnType:column:ele:current"></p>
          </div>
        </div>
        <div class="batch_documents scrollbar__">

            <div class="document" *ngFor="let doc of conflict?.documents">
                <i class="{{doc.name | filetypeIcon}}" [innerHtml]="doc.name | filetypeIcon:true"></i>
                <div>
                    <div><strong>NAME:</strong><p class="truncate">{{doc.name}}</p></div>
                    <div><strong>TYPE:</strong><p class="truncate">{{doc.documentType}}</p></div>
                </div>
            </div>
            
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="left">
          <!-- <label>
              <input type="checkbox"> Check this box to confirm your action.
          </label> -->
      </div>
      <div class="rigth">
            <button class="button__ " (click)="newBatch()" *ngIf="canNewBatch()">Continue batch</button>
            <button class="button__ primary" (click)="reviseDocument()">Revise Batch</button>
      </div>
    </div>
  </div>
</div>
