import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "element-simple-menu",
  templateUrl: "./simple-menu.component.html",
  styleUrls: ["./simple-menu.component.scss"],
})
export class SimpleMenuComponent implements OnInit {
  constructor() {}
  @Input() hover: boolean = false;
  active: boolean = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.active = true;
    }, 1000);
  }
}
