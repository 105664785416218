<div class="clicker" (click)="onClickCapture()"></div>
<div class="wrapper">
  <span *ngIf="value && value.length > 16" [innerHTML]="value | truncateText" (click)="onClickCapture()"></span>
  <span *ngIf="value && value.length < 17 && value.length > 0" [innerHTML]="value" (click)="onClickCapture()"></span>
  <span *ngIf="!value || value.length == 0">
    <div class="text-placeholder w20"></div>&nbsp;
    <div class="text-placeholder w70"></div>&nbsp;
    <div class="text-placeholder w40"></div>
  </span>
  <span *ngIf="value && value.length > 0" class="icon-copy" [ngClass]="{'green': color == 'green'}"
        (click)="copy()"></span>
  <span class="notice" [ngClass]="{'green': color == 'green'}" *ngIf="copied">Copied!</span>
</div>
