import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FilterOptions, Filter, Fields } from "../elements/list-filter/list-filter.settings";
import { Claim } from '../models/account';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  constructor(private http: HttpClient) {}

  async create(filter:FilterOptions){
    return await this.http.post<FilterOptions>("workspace", filter).toPromise();
  }
  
  async duplicate(awid:string){
    return await this.http.get<FilterOptions>(`workspace/duplicate/${awid}`).toPromise();
  }

  async update(filter:FilterOptions){
    return await this.http.put<FilterOptions>(`workspace/${filter.awid}`, filter).toPromise();
  }

  async delete(filter:FilterOptions){
    return await this.http.delete<FilterOptions>(`workspace/${filter.awid}`).toPromise();
  }
  
  async get(filter:FilterOptions){
    return await this.http.get<FilterOptions>(`workspace/${filter.awid}`).toPromise();
  }

  get_fields(){
    return  this.http.post<Fields[]>(`workspace/fields`,{}).toPromise(); 
  } 
 
  async list(){
    return await this.http.get<FilterOptions[]>(`workspace/list`).toPromise(); 
  } 
  
  async list_defaults(){
    return await this.http.get<FilterOptions[]>(`workspace/defaults`).toPromise(); 
  } 
 
  async order_workspaces(awids:string[]){
    return await this.http.post(`workspace/order`, {awids}).toPromise(); 
  } 
  
  async toggle_workspace(awid:string, toggle:boolean){
    return await this.http.post(`workspace/active`, {awid, active:toggle}).toPromise(); 
  } 
}
