import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Batch, BatchService } from "src/app/services";
import { TableColumnType, TableColumn } from "../table/types";
import { Router } from "@angular/router";
import { OnChangePayload, TypeEnum } from "src/app/models";
import {DocumentRpcService} from "../../services/document.rpc.service";

export interface BatchConflictState {
  notice: boolean;
  modal: boolean;
  button: boolean;
  conflict: {
    index: number;
  };
}

@Component({
  selector: "element-batch-conflict",
  templateUrl: "./batch-conflict.component.html",
  styleUrls: ["./batch-conflict.component.scss"],
})
export class BatchConflictComponent implements OnInit {
  constructor(private router: Router, private _bs: BatchService, private doc:DocumentRpcService) {}
  //@ts-ignore
  @Input() conflicts: Batch[];
  //@ts-ignore
  @Input() current: Batch;

  @Output() update: EventEmitter<OnChangePayload> = new EventEmitter();

  column: TableColumn = {
    id: "",
    type: TableColumnType.MoreInfo,
    label: "Order Number",
  };

  states: BatchConflictState = {
    notice: true,
    modal: false,
    button: true,
    conflict: {
      index: 0,
    },
  };

  validator = {
    orders: false,
    awb_bol: false,
  };

  get conflict() {
    if (this.conflicts.length > 0) {
      return this.conflicts[this.states.conflict.index];
    }
  }

  async ngOnInit() {

    this.checkConflicts();

    await this.getCurrentDocuments();
    await this.getConflictDocuments();
  }

  async getConflictDocuments(){
    if(this.conflict && this.conflict.bid){
     this.conflict.documents = await this.doc.getBatchDocuments(this.conflict.bid, this.conflict.parentBID) ?? [];
    }
  }

  async getCurrentDocuments(){
    if (this.current && this.current.bid) {
      this.current.documents = await this.doc.getBatchDocuments(this.current.bid, this.current.parentBID) ?? [];
    }
  }
  toggleModal() {
    this.states.modal = !this.states.modal;

    this.checkConflicts();
  }

  newBatch() {
    this.states.notice = false;
    this.states.modal = false;
    this.states.conflict.index = 0;
    this.update.emit({ type: TypeEnum.DisregardConflict, payload: true });
  }

  async pager(direction: string) {
    switch (direction) {
      case "back":
        if (this.states.conflict.index > 0) {
          this.states.conflict.index--;
        } else {
          this.states.conflict.index = this.conflicts.length - 1;
        }
        break;
      case "next":
        if (this.states.conflict.index < this.conflicts.length - 1) {
          this.states.conflict.index++;
        } else {
          this.states.conflict.index = 0;
        }
        break;
    }
    await this.getConflictDocuments();
    this.checkConflicts();
  }


  checkConflicts() {
    this.states.button = true;
    this.validator.orders = false;
    this.validator.awb_bol =
      this.conflict?.awbBolNumber == this.current.awbBolNumber ? true : false;

    let awb = false;
    let orders = false;

    for (let i = 0; i < this.conflicts.length; i++) {
      const batch = this.conflicts[i];

      if (batch.awbBolNumber == this.current.awbBolNumber) {
        awb = true;
        this.states.button = false;
      }

      if (batch.orderNumber){
        for (let x = 0; x < batch.orderNumber.length; x++) {
          const order = batch.orderNumber[x];
          if (this.current) {
            const conflict = this.current?.orderNumber?.filter(
              (o) => o == order
            );
            if (conflict.length > 0) {
              orders = true;
            }
          }
        }
      }
    }

    if (awb && orders) {
      this.states.button = false;
    }

    for (let x = 0; x < this.current.orderNumber.length; x++) {
      const order = this.current.orderNumber[x];
      if (this.conflict) {
        const conflict = this.conflict?.orderNumber.filter((o) => o == order);
        if (conflict && conflict.length > 0) {
          this.validator.orders = true;
        }
      }
    }
  }

  canNewBatch() {
    return (
      !(this.validator.orders && this.validator.awb_bol) && this.states.button
    );
  }

  getAssociations(type: string, batch: any | undefined) {
    if (batch){
      return batch.associations.find((a:any) => a.type == type)?.label;
    }
  }

  async reviseDocument() {
    if (this.current.bid && this.conflict?.bid){
      this._bs
        .batchConflictMerge(
          this.current.bid,
          this.current.orderNumber,
          this.conflict.bid
        )
        .then((res) => {
          this._bs.resetTimer();
          this.router.navigate([
            "dashboard",
            "batch",
            "revise",
            this.conflict?.bid,
          ]);
        });
    }
  }
}
