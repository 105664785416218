import { Component, OnInit, Input } from "@angular/core";
import { ModalService, InterfaceService } from "../../services";
@Component({
  selector: "element-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  constructor(private _ms: ModalService, private _is: InterfaceService) {}

  @Input() name: string = "";
  @Input() toggle: boolean = false;
  @Input() type: string = "modal";

  ngOnInit(): void {}
  toggleModal() {
    this._is.toggle({ type: this.type });
  }
  toggleCreateAccount() {
    this._is.toggleCreateAccountModal();
  }
}
