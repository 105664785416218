import { UntypedFormGroup } from "@angular/forms";

export const Newid = () =>
  (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

// custom validator to check that two fields match
export function HasConflict(controlName: string, isTrue: boolean) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.errors && !control.errors.conflict) {
      return;
    }
    if (isTrue) {
      control.setErrors({ conflict: true });
    } else {
      control.setErrors(null);
    }
  };
}

export function Offset(el: HTMLElement) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function FormatDate(
  date: Date,
  formated: boolean = false,
  disableTime: boolean = false
) {
  var hours = date.getHours();
  var minutes: string | number = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  if (formated) {
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  <br>" +
      strTime
    );
  } else {
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " "// +
      // strTime
    );
  }
}

export async function Wait(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, seconds * 1000);
  });
}

export function FormatDateInputValue(d: Date, format: number = 1) {
  var month: any = d.getMonth();
  var day: any = d.getDate().toString();
  var year: any = d.getFullYear();

  year = year.toString(); //.substr(-2);

  month = (month + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  if (day.length === 1) {
    day = "0" + day;
  }

  if (format == 1) {
    return `${year}-${month}-${day}`;
  }

  if (format == 2) {
    return `${month}/${day}/${year}`;
  }
}

export function setSelectionRange(elem: any, selectionStart: number) {
  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move("character", selectionStart);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(selectionStart, selectionStart);
      } else elem.focus();
    }
  }
}

export function setDateTimezone(date: Date) {
  // create Date object for current location
  const d = new Date(date);

  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  // obtain and add destination's UTC time offset
  const offset = 7;
  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * offset);

  // return time as a string
  return nd;
}

export function DateToSQLDatetime(dateInput: Date) {
  var pad = function (num: any) {
    return ("00" + num).slice(-2);
  };
  var date;
  date = new Date(dateInput);
  date =
    date.getUTCFullYear() +
    "-" +
    pad(date.getUTCMonth() + 1) +
    "-" +
    pad(date.getUTCDate()) +
    " " +
    pad(date.getUTCHours()) +
    ":" +
    pad(date.getUTCMinutes()) +
    ":" +
    pad(date.getUTCSeconds());

  return date;
}
