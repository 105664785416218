<div class="batch_status__wrapper" *ngIf="toggled">
    <div class="batch_status__overlay" (click)="toggler()"></div>
    <div class="batch_status__modal">
        <form [formGroup]="form"  (ngSubmit)="onSubmit()">
            <div class="title">
                <div class="left">
                    <h2>Batch Status</h2>
                </div>
                <div class="right">

                    <div class="stepper">
                        <ul>
                            <li>Statuses</li>
                            <li>Message</li>
                        </ul>
                        <div class="progress">
                            <div class="bar" [ngStyle]="{'width': (state.message)?'100%':'50%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="context_wrapper">
                <div class="left">
                    <h3>Status</h3>
                    <div class="statuses">
                        <ul>
                            <li class="run">
                                <input type="radio" name="status" id="status_run" value="run" formControlName="status">
                                <label for="status_run">
                                    <i class="icon-release"></i>
                                    <div>
                                        <strong>Run</strong>
                                        <p>Entry has been transmitted to CBP</p>
                                    </div>
                                </label>
                            </li>
                            <li class="accepted">
                                <input type="radio" name="status" id="status_accepted" value="accepted" formControlName="status">
                                <label for="status_accepted">
                                    <i class="icon-checkmark" ></i>
                                    <div>
                                        <strong>Accepted</strong>
                                        <p>Entry has been accepted by CBP</p>
                                    </div>
                                </label>
                            </li>
                            <li  class="released">
                                <input type="radio" name="status" id="status_released" value="released" formControlName="status">
                                <label for="status_released">
                                    <i class="icon-process"></i>
                                    <div>
                                        <strong>Released</strong>
                                        <p>Entry has been released by CBP</p>
                                    </div>
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li  class="billing">
                                <input type="radio" name="status" id="status_billing" value="billing" formControlName="status">
                                <label for="status_billing">
                                    <i class="icon-invoice"></i>
                                    <div>
                                        <strong>Billing</strong>
                                        <p>Batch has been sent to billing for processing.</p>
                                    </div>
                                </label>
                            </li>
                            <li  class="completed">
                                <input type="radio" name="status" id="status_completed" value="completed" formControlName="status">
                                <label for="status_completed">
                                    <i class="icon-finished"></i>
                                    <div>
                                        <strong>Complete</strong>
                                        <p>Batch has been proccessed and completed. No further action required.</p>
                                    </div>
                                </label>
                            </li>
                            <li  class="issue">
                                <input type="radio" name="status" id="status_issue" value="issue" formControlName="status">
                                <label for="status_issue">
                                    <i class="icon-warning"></i>
                                    <div>
                                        <strong>Issue</strong>
                                        <p>Notify the client of something missing or keeping us from moving forward.</p>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
        
                </div>

                <div class="right" [ngClass]="{active: state.status}">
                    <div class="content">
                        <div>
                            <h3>Review</h3>

                            <div class="field__group">
                                <div class="field-toggle">
                                    <input type="radio" name="fda" id="fda_review" #fda_review  value="fda_review"  formControlName="fda">
                                    <label for="fda_review" (click)="radioToggler($event, fda_review)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>FDA Review</span>
                                    </label>
                                </div>
                    
                                <div class="field-toggle">
                                    <input type="radio" name="noaa" id="noaa_review" #noaa_review  value="noaa_review"  formControlName="noaa">
                                    <label for="noaa_review" (click)="radioToggler($event, noaa_review)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>NOAA Review</span>
                                    </label>
                                </div>
                    
                                <div class="field-toggle">
                                    <input type="radio" name="usda" id="usda_review" #usda_review  value="usda_review"  formControlName="usda">
                                    <label for="usda_review" (click)="radioToggler($event, usda_review)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>USDA Review</span>
                                    </label>
                                </div>
                      
                                <div class="field-toggle">
                                    <input type="radio" name="cbp" id="cbp_review" #cbp_review value="cbp_review"  formControlName="cbp">
                                    <label for="cbp_review" (click)="radioToggler($event, cbp_review)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>CBP Review</span>
                                    </label>
                                </div>
                            </div>

                        
                        </div>
                        <div>
                            <h3>Sample</h3>
                            <div class="field__group">
                                <div class="field-toggle">
                                    <input type="radio" name="fda" id="fda_sample" #fda_sample  value="fda_sample"  formControlName="fda">
                                    <label for="fda_sample" (click)="radioToggler($event, fda_sample)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>FDA Sample</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>         
                            <h3>Hold</h3>
                            <div class="field__group">
                                <div class="field-toggle">
                                    <input #fda_holds type="radio" name="fda" id="fda_hold" value="fda_hold" formControlName="fda" >
                                    <label for="fda_hold" (click)="radioToggler($event, fda_holds)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>FDA Hold</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input type="radio" name="noaa" id="noaa_hold" #noaa_hold value="noaa_hold" formControlName="noaa">
                                    <label for="noaa_hold" (click)="radioToggler($event, noaa_hold)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>NOAA Hold</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input type="radio" name="usda" id="usda_hold" #usda_hold value="usda_hold" formControlName="usda">
                                    <label for="usda_hold" (click)="radioToggler($event, usda_hold)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>USDA Hold</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input type="radio" name="cbp" value="cbp_hold" #cbp_hold id="cbp_hold" formControlName="cbp">
                                    <label for="cbp_hold" (click)="radioToggler($event, cbp_hold)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>CBP Hold</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3>Proceed</h3>
                            <div class="field__group">
                                <div class="field-toggle">
                                    <input #fda_proceed type="radio" name="fda" id="fda_may_proceed"   value="fda_may_proceed" formControlName="fda">
                                    <label for="fda_may_proceed" (click)="radioToggler($event, fda_proceed)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>FDA May Proceed</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input #noaa_proceed type="radio" name="noaa" id="noaa_may_proceed" value="noaa_may_proceed" formControlName="noaa">
                                    <label for="noaa_may_proceed" (click)="radioToggler($event, noaa_proceed)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>NOAA May Proceed</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input FDA Hold
                                    #usda_proceed type="radio" name="usda" id="usda_may_proceed"  value="usda_may_proceed" formControlName="usda" >
                                    <label for="usda_may_proceed" (click)="radioToggler($event, usda_proceed)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>USDA May Proceed</span>
                                    </label>
                                </div>
                                <div class="field-toggle">
                                    <input #cbp_proceed type="radio" name="cbp" value="cbp_may_proceed" id="cbp_may_proceed" formControlName="cbp">
                                    <label for="cbp_may_proceed" (click)="radioToggler($event, cbp_proceed)">
                                        <div class="switch">
                                            <div class="dot"></div>
                                        </div>
                                        <span>CBP May Proceed</span>
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div class="confirm_proceed"  [ngClass]="{active: state.proceed.toggle}">
                            <div class="actions">
                                <div class="context">
                                    <h2>{{state.proceed.label}}</h2>
                                    <div class="field-toggle">
                                        <input #proceeder type="checkbox" id="proceeder" (click)="canProceed(proceeder)">
                                        <label for="proceeder">
                                            <div class="switch">
                                                <div class="dot"></div>
                                            </div>
                                            <span>Are you sure you want to proceed?</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="field__input row">
                                    <button type="button" class="button__ cancel" (click)="previous()">Cancel</button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="button" class="button__ primary" [disabled]="!state.proceed.canProceed" (click)="confirmProceed()">Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="field__input row">
                            <button type="button" class="button__ cancel" (click)="toggler()">Cancel</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="button" class="button__ primary" (click)="next()">Next</button>
                        </div>
                    </div>
                </div>
                <div class="right"  [ngClass]="{active: state.message}">
                    <div class="content">
                        <div style="width:100%;height:100%;display: flex;flex-direction: column;">
                            <h3>Message </h3>
                            <div class="spacer__"></div>
                            <p>Status message details will appear in Batch History.</p>
                            <div class="spacer__"></div>
                            <div class="field__input" style="height: 100%;">
                                <textarea autocomplete="off" style="height: 100%;"  formControlName="message" [ngClass]="{invalid: form.controls['message'].errors}"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="field__input row">
                            <button type="button" class="button__ cancel" (click)="previous()" style="width: 110px;">Previous</button>
                            <button type="submit" class="button__ outline" [disabled]="form.invalid" style="width:250px;" (click)="setPriority()">Update Status & Notify</button>
                            <button type="submit" class="button__ primary outline" [disabled]="form.invalid" style="width:200px;">Update Status</button>
                        </div>
                    </div>
                </div>
           
            </div>
        </form>
    </div>
</div>