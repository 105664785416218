<element-batch-conflict *ngIf="validator.conflict.orders || validator.conflict.awb_bol" (event)="initConflictMerge($event)" [conflicts]="batchConflicts" [current]="draft"></element-batch-conflict>

<div class="batch_preview__wrapper">
  <form #form="ngForm" [formGroup]="forms.batch.form" (keydown.enter)="$event.preventDefault()">
    <div class="batch__title">
      <div>
        <h1>
        {{title}} <span *ngIf="batchType.revise">#{{draft?.id}}</span>
        </h1> 
        <div class="status_label" *ngIf="status_label">
          <strong>Status:</strong>
          <p><span class="batch_status {{status_label?.toLowerCase()}}">{{status_label}}</span></p>
        </div>
      </div>
      <div class="batch__actions" >
        <button class="button__ transparent" *ngIf="(!batchType.revise || status_label == 'Draft') && importer" (click)="onSaveForLater()">Save for Later</button>&nbsp;&nbsp;&nbsp;
        <button class="button__ "  [routerLink]="['/dashboard/batches']">Cancel</button>&nbsp;&nbsp;&nbsp;
        <button class="button__ primary" (click)="onSubmit()" [disabled]="!forms.batch.valid">{{title}}</button>
      </div>
    </div>
    <div class="batch__info">
      
      <div class="field__input light" style="width:60%"> 
        <label  class="truncate">Importer</label>
        <element-autocomplete *ngIf="!importer" [disableToggle]="true" [data]="importer_accounts" (onChange)="onChange($event, Role.Importer)" placeholder="Importer Name"></element-autocomplete>
        <div class="field_input_icon" *ngIf="importer" >
          <input  type="text" autocomplete="off"  [value]="importer.label"  disabled>
          <i class="icon-remove right cursor" (click)="clearAssociation(Role.Importer)"></i>
        </div>
       
      </div>
 
      <div class="field__input light">
        <label  class="truncate">Trucker</label>
        <element-autocomplete [autofocus]=false *ngIf="this.importer && this.supplier && this.courier_type != undefined && !this.trucker"  [disableToggle]="true" [data]="trucker_accounts" (onChange)="onChange($event, Role.Trucker)" placeholder="Trucker Name"></element-autocomplete>
        <div class="field_input_icon" *ngIf="trucker">
          <input *ngIf="trucker" type="text" autocomplete="off" [value]="trucker.label" disabled>
          <i class="icon-remove right cursor" (click)="clearAssociation(Role.Trucker)"></i>
        </div>
        <div class="field_input_icon" *ngIf="(!this.importer || !this.supplier || this.courier_type == undefined) " >
          <input  type="text" autocomplete="off"    disabled placeholder="Please populated all required fields">
        </div>
      </div>


      <div class="field__input light" style="width:20%">
        <label  class="truncate">Order Number</label>
        <element-multi-selector *ngIf="importer"  [values]='forms?.batch?.form?.controls["order_number"].value' (change)="onChange($event)"></element-multi-selector>
        <div class="field_input_icon" *ngIf="!importer" >
          <input  type="text" autocomplete="off"    disabled placeholder="Select Importer Before Proceeding">
        </div>
      </div>


      <div class="field__input light" >
        <div class="courier__type">
          <label *ngIf="courier_type == true" class="truncate">AWB Number <span style="color: red;">*</span></label>
          <label *ngIf="courier_type == false" style="color: #4056b6;" class="truncate">BOL Number <span style="color: red;">*</span></label>
          <label *ngIf="courier_type == undefined"  class="truncate">AWB/BOL Number <span style="color: red;">*</span></label>
          <div class="field-toggle" [ngClass]="{required: (courier_type == undefined && forms.batch.form.controls['awb_bol_number'].value == ''  && importer && supplier) || (courier_type == undefined && importer && supplier) }" >
            <element-state-toggler [option]="courier_option"  (change)="change($event)"></element-state-toggler>
          </div>
        </div>

        <input *ngIf="this.importer && this.supplier && this.courier_type != undefined" type="text" autocomplete="off" #awb_bol_number formControlName="awb_bol_number"  (keyup)="change($event,true)" >
        <div class="field_input_icon" *ngIf="!this.importer || !this.supplier || this.courier_type == undefined" >
          <input type="text" autocomplete="off" disabled placeholder="Select Toggle Courier Type Before Proceeding">
        </div>
      </div>

      
      <div class="field__input light" style="width:20%">
        <label  class="truncate">Tags</label>
        <element-multi-selector *ngIf="importer" label="Tag" [values]='forms?.batch?.form?.controls["tags"].value' (change)="onChange($event)"></element-multi-selector>
        <div class="field_input_icon" *ngIf="!importer" >
          <input  type="text" autocomplete="off"    disabled placeholder="Select Importer Before Proceeding">
        </div>
      </div>


 <div class="field__input light" style="width: 25%;">
        <label class="truncate">ETA</label>

        <div
          class="date-field"
          *ngIf="
            this.importer && this.supplier && this.courier_type != undefined
          "
        >
          <mat-form-field appearance="fill">
            <input
              matInput
              [matDatepicker]="eta_picker"
              formControlName="eta"
              (dateInput)="change($event, true)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="eta_picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #eta_picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              name="eta_time_picker"
              format="24"
              [ngxMatTimepicker]="eta_time_picker"
              formControlName="eta_time"
              placeholder="00:00"
              readonly
            />
            <span
              class="material-symbols-outlined"
              (click)="eta_time_picker.open()"
            >
              schedule
            </span>
            <ngx-mat-timepicker
              #eta_time_picker
              (timeChanged)="change($event, true)"
            ></ngx-mat-timepicker>
          </mat-form-field>
        </div>

        <div
          class="field_input_icon"
          *ngIf="
            !this.importer || !this.supplier || this.courier_type == undefined
          "
        >
          <input
            type="text"
            autocomplete="off"
            disabled
            placeholder="Select Toggle Courier Type Before Proceeding"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="batch__documents" [ngClass]="{disabled: draft?.bid == null}">
        <element-upload [queue]="documents" [batchId]="batchId" (change)="onChange($event)"></element-upload>
        <element-batch-history *ngIf="draft.bid" [bid]='draft.bid'  [showBanner]="false"></element-batch-history>
      </div>
      <div class="batch__notes">
        <div class="title"><h3>Notes</h3></div>
        <textarea formControlName="notes" #notes (keydown)="textareaOnKeypress($event, notes)"></textarea>
      </div>
    </div>
  </form>
</div>
