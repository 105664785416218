
    <h3 *ngIf="name">{{name}}</h3>
<div class="table__top">
    <div>
        <div class="table__groups" #table_group_ele *ngIf="table?.options?.groups?.length > 0">
            <div *ngFor="let group of table?.options?.groups" (click)="groupBy(group.id)" [ngClass]="{active: (table?.options?.page?.groupBy == group.id)}" [innerHTML]="group.label" [ngStyle]="{'width': (group.tabWidth) ? group.tabWidth + 'px':'', 'background-color':(group.bgColor) ? group.bgColor:'', 'color':(group.textColor) ? group.textColor:''}" (click)="onClickCapture(group?.onClick)" > 

            </div>
        </div>
    </div>

    <div class="table__pagination" *ngIf=" !table?.options?.layout || !table?.options?.layout?.pagerLocation || table.options.layout?.pagerLocation == 'top' && table?.options?.page?.count != 1">
        <div class="table__pager" (click)="pagerPrevious()" ><i class="icon-arrow_left"></i></div>
        <div class="table__pager__details" >
             {{table?.options?.page?.current}} of {{table?.options?.page?.count}}
        </div>
        <div class="table__pager" (click)="pagerNext()"  [ngClass]="{active: table?.options?.page?.current != table?.options?.page?.count}"><i class="icon-arrow_right"></i></div>
    </div>
</div>
<div class="table__wrapper" #tableWrapper [ngClass]="{'scroller':(tableWrapper.offsetWidth < (columnWidth * table?.options?.columns?.length))}">
    <div class="table__header" *ngIf="table?.options?.layout?.header">
        <div *ngFor="let column of table?.options?.columns"  [ngStyle]="{'min-width': (column.width)? column.width+'px': minWidth+'px', 'width': (column.width)? column.width+'px':columnWidth+'px','max-width': (column.width)? column.width+'px':maxWidth+'px', 'display':( column.group && column.group != table?.options?.page?.groupBy)? 'none':'block' }" (click)="orderBy(column.id)" [ngClass]="{hide: ( column.group && column.group != table?.options?.page?.groupBy)}" >   <span [innerHTML]="column.label"></span>
            <i *ngIf="table?.options?.page?.orderBy?.column == column.id" class="icon-sort-{{table?.options?.page?.orderBy?.direction}}"></i> 
        </div>
        <div class="actions" #actionsWrapper [ngStyle]="{'min-width': minWidth+'px', 'width': columnWidth+'px' , 'max-width': maxWidth+'px' }"  *ngIf="!table?.options?.layout?.hideActions">
            actions
        </div>
    </div>
    <div class="table__body scrollbar__" #table_body [ngClass]="{__footer_pager: table?.options?.layout?.pagerLocation == 'bottom', 'overflow_none': isLoading}"  >
        <div class="loader" [ngClass]="{active: isLoading}">
            <img src="../../../assets/branding/loader.gif">
        </div>
        <div class="table__item" *ngFor="let item of table?.data" >
            <div  [ngStyle]="{'min-width': (column.width)? column.width+'px':minWidth+'px', 'width': (column.width)? column.width+'px':columnWidth+'px' , 'max-width': (column.width)? column.width+'px':maxWidth+'px', 'display':( column.group && column.group != table?.options?.page?.groupBy)? 'none':'block' }"
              #parentEle 
              *ngFor="let column of table?.options?.columns" 
              [ngClass]="{hide: ( column.group && column.group != table?.options?.page?.groupBy)}" 
              [innerHTML]="item[column?.id] | tableColumnType:column:parentEle"
             (click)="columnClick(column, $event, item, actions[actions.length-1].scope, parentEle)" 
             ></div>
            <div class="actions" #actionsWrapper style="display: flex;flex-direction: row-reverse;" [ngStyle]="{'min-width': minWidth+'px', 'width': columnWidth+'px' , 'max-width': maxWidth+'px' }" *ngIf="!table?.options?.layout?.hideActions">
                <span *ngFor="let action of actions">
                    <i *ngIf="!action.onClick && ((action?.hideCondition)? action?.hideCondition(item, action.scope) : true) " [class]="action.icon"(click)="action?.function($event, item, action.scope)"><span class="info">{{action.name}}</span></i>
                </span>
            </div>
            
        </div>
    </div>
    <div class="table__footer" *ngIf="table.options.layout?.footer">
        <div  [ngStyle]="{'min-width': (column.width)? column.width+'px':minWidth+'px', 'width': (column.width)? column.width+'px':columnWidth+'px' , 'max-width': (column.width)? column.width+'px':columnWidth+'px', 'display':( column.group && column.group != table?.options?.page?.groupBy)? 'none':'block' }" *ngFor="let column of table.options.columns" (click)="orderBy(column.id)" [ngClass]="{hide: column.group && column.group != table.options.page.groupBy}" >
            <span [innerHTML]="column.label"></span>
            <i *ngIf="table.options.page.orderBy.column == column.id" class="icon-sort-{{table.options.page.orderBy.direction}}"></i> 
        </div>
        <div class="actions" #actionsWrapper  [ngStyle]="{'min-width': minWidth+'px', 'width': columnWidth+'px' , 'max-width': maxWidth+'px' }" *ngIf="!table?.options?.layout?.hideActions">
            actions
        </div>
    </div>
    <div class="table__pagination" *ngIf="table.options.layout?.pagerLocation == 'bottom'">
        <div class="table__pager__details" >
            {{table.options.page.current}} of {{table.options.page.count}}
       </div>
        <div class="table__pager_wrapper">
            <div class="table__pager" (click)="pagerPrevious()" [ngClass]="{active: table.options.page.current != 1}"><i class="icon-arrow_left"></i></div>
            <div class="table__pager" (click)="pagerNext()" [ngClass]="{active: table.options.page.current != table.options.page.count}"><i class="icon-arrow_right"></i></div>
        </div>
    

    </div>
</div>
