import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(string: string, type: string = "url") {
    switch (type) {
      case "html":
        return this.sanitizer.bypassSecurityTrustHtml(string);
        break;
      default:
        return this.sanitizer.bypassSecurityTrustResourceUrl(string);
        break;
    }
  }
}
