import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from "@angular/forms";
import { IStatuses, BatchService } from "src/app/services/batch.service";
import { MessageService } from "src/app/services";
import { MessageType } from "src/app/models";
import { Router } from "@angular/router";
@Component({
  selector: "element-batch-statuses",
  templateUrl: "./batch-statuses.component.html",
  styleUrls: ["./batch-statuses.component.scss"],
})
export class BatchStatusesComponent implements OnInit {
  constructor(
    private _fb: UntypedFormBuilder,
    private _bs: BatchService,
    private _ms: MessageService,
    private router: Router
  ) {}

  state: any = {
    status: true,
    message: false,
    proceed: {
      toggle: false,
      element: null,
      label: "",
      canProceed: false,
      proceedElement: null,
    },
  };

  @Input() statuses: IStatuses = {
    status: null,
    fda: null,
    noaa: null,
    usda: null,
    cbp: null,
  };

  @Input() toggled: boolean | undefined = true;
  @Input() bid: string | undefined;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onChange: EventEmitter<IStatuses> = new EventEmitter();

  form: UntypedFormGroup = this._fb.group({ ...this.statuses, message: null });

  ngOnInit(): void {
    this.form = this._fb.group({
      ...this.statuses,
      message: null,
    });

    this.form.controls.status.valueChanges.subscribe((res) => {
      this.setValidator();
    });
  }

  reactiveToggler(evt: MouseEvent | null, checkbox: HTMLInputElement) {
    const field_group = checkbox.getAttribute("name");
    if (evt) evt.preventDefault();
    if (field_group) this.form.controls[field_group].setValue((checkbox.checked)?null:checkbox.value);
  }

  canProceed(evt: HTMLInputElement) {
    this.state.proceed.canProceed = evt.checked;
    this.state.proceed.proceedElement = evt;
  }

  radioToggler(evt: MouseEvent, checkbox: HTMLInputElement) {
    if (checkbox.value.indexOf("_proceed") != -1) {
      if (checkbox.checked) {
        this.reactiveToggler(evt, checkbox);
      } else {
        evt.preventDefault();
        this.state.proceed.toggle = true;
        this.state.proceed.element = checkbox;
        this.state.proceed.label = checkbox.nextElementSibling?.querySelector(
          "span"
        )?.innerText;
      }
    } else {
      this.reactiveToggler(evt, checkbox);
    }
  }

  confirmProceed() {
    const checkbox: HTMLInputElement = this.state.proceed.element;
    const untoggler: HTMLInputElement = this.state.proceed.proceedElement;

    const field_group = checkbox.getAttribute("name");

    if (field_group) this.form.controls[field_group].setValue(checkbox.value);

    this.state.proceed.canProceed = false;

    this.state.proceed.toggle = false;
    this.state.proceed.element = null;
    
    checkbox.checked = true;
    untoggler.checked = false;
  }

  clearReview() {
    this.form.controls.review.setValue(null);
  }

  toggler() {
    this.toggle.emit(!this.toggled);
  }

  next() {
    this.state.proceed.toggle = false;
    this.state.proceed.element = null;

    this.state.status = false;
    this.state.message = true;
    this.setValidator();
  }

  previous() {
    const untoggler: HTMLInputElement = this.state.proceed.proceedElement;
    this.state.proceed.canProceed = false;

    if (untoggler) {
      untoggler.checked = false;
    }

    this.state.proceed.toggle = false;
    this.state.proceed.element = null;
    this.state.status = true;
    this.state.message = false;
  }

  setValidator() {
    if (this.form.controls.status.value == "issue") {
      this.form.controls.message.setValidators([Validators.required]);
    } else {
      this.form.controls.message.setValidators([]);
    }
    this.form.controls.message.updateValueAndValidity();
  }
  isPriority:boolean = false;
  setPriority(){
    this.isPriority = true;
  }
  onSubmit() {
    if (this.bid && !this.form.invalid) {
      this._bs
        .updateBatchStatuses({
          bid: this.bid,
          statuses: this.form.getRawValue(),
          message: this.form.controls["message"].value,
          priority:this.isPriority
        })
        .then((statuses: any) => {
          this.statuses = statuses;
          this.onChange.emit(this.statuses);

          this._ms.set({
            type: MessageType.Success,
            message: "Batch status has been updated.",
            delay: 2500,
          });

          this.router.navigate(["/dashboard/batches"]);
        })
        .catch((err: any) => {
          this._ms.set({
            type: MessageType.Error,
            message: "Batch status was not updated.",
            delay: 2500,
          });
          this.toggler();
        });
    }
  }
}
