<div class="dashboard__wrapper">
    <div class="dashboard__sidebar">
        <img class="logo"  [routerLink]="['batches']" src="../../../assets/branding/Logo_sm.png">
        <ul>
            <li [routerLink]="['batches']" routerLinkActive="active">
                <i class="icon-batch"></i>
                <p><span>Batches</span></p>
            </li>
            <li [routerLink]="['accounts']" routerLinkActive="active"  *ngIf="role == Role.Alpha || role == Role.Manager || role == Role.Clerk">
                <i class="icon-accounts"></i>
                <p><span>Accounts</span></p>
            </li>
            <li  [routerLink]="['emails']" routerLinkActive="active" *ngIf="role == Role.Alpha || role == Role.Manager || role == Role.Clerk">
                <i class="icon-email"></i>
                <p><span>Emails</span></p>
            </li>
        </ul>
    </div>
    <div class="dashboard__inner">
        <element-top-bar></element-top-bar>
        <div class="dashboard__content">
            <element-list-filter></element-list-filter>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

