import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'view-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
