import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";

import {
  TableData,
  OnTableChangePayload,
  TablePayloadType,
  TableOrderByDirection,
  TableAction,
  TablePagerLocation,
  TableColumn,
} from "./types";

import { FilterService, InterfaceService } from "../../services";
import { Subscription } from "rxjs";
import { InterfacePayload, InterfacePayloadType } from "src/app/services/interface.service";

@Component({
  selector: "element-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private fs: FilterService, private is:InterfaceService) {}

  @Input() name: string = "";
  //@ts-ignore
  @Input() table: TableData = {};

  //@ts-ignore
  onClick: TableAction = null;

  @Output() onChange: EventEmitter<OnTableChangePayload> = new EventEmitter();

  @Input() actions: TableAction[] = [];
  @Input() view_details = () => {
    return;
  };

  //@ts-ignore
  @ViewChild("table_group_ele") tableGroups: ElementRef;
  
  //@ts-ignore
  @ViewChild("table_body") tableBody: ElementRef;
  //@ts-ignore
  @ViewChild("tableWrapper") tableWrapper: ElementRef;

  minWidth = 100;
  maxWidth = 600;

  //@ts-ignore
  filterSubscription: Subscription;
  //@ts-ignore
  filterablesSubscription: Subscription;
  //@ts-ignore
  filter: string = "";
  filterables: string = "";

  is_observer:any = null;

  isLoading:boolean = false;

  initHorizontalScroll() {
      const scrollContainer = this.tableGroups.nativeElement
      scrollContainer.addEventListener("wheel", (evt:any) => {
          evt.preventDefault();
          scrollContainer.scrollLeft += evt.deltaY;
      });
  }

  ngOnInit(): void {
    this.actions.forEach((a) => {
      if (a.onClick) {
        this.onClick = a;
      }
    });

    this.fs.setFilter(null);

    this.filterSubscription = this.fs.filter.subscribe((filter) => {

      if (filter != null) {
        this.table.options.page.current = 1;

        this.filter = filter;
        this.table.options.page.filter = filter;

        this.onChange.emit({
          type: TablePayloadType.Filter,
          payload: { ...this.table.options },
        });
      }

    });

    this.filterablesSubscription = this.fs.filterables.subscribe((filter) => {
      if (filter != null) {
        this.table.options.page.current = 1;
        // this.filterables = filter;
        // this.table.options.page.filterables = filter;
        this.onChange.emit({
          type: TablePayloadType.Filter,
          payload: { ...this.table.options },
        });
      }
    });

    this.initColumns();
  }

  columnClick(tableColumn:TableColumn, evt:any, item:any, scope:any, parentEle:HTMLElement ){
      if(tableColumn.onClick && tableColumn.function){
        tableColumn?.function(item, parentEle);
      }else{
        this.onClick?.function(evt, item, scope, parentEle)
      }
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
    this.filterablesSubscription.unsubscribe();
    this.is_observer.unsubscribe();
  }

  ngAfterViewInit() {

    this.is_observer = this.is.interface.subscribe((data:InterfacePayload)=>{
      if(data){
        if(data.type == InterfacePayloadType.ListFilter){
          this.filter_toggles = data.payload.toggled;
        }
        if(data.type == InterfacePayloadType.Loading){
          const that = this;
          setTimeout(function(){
            that.isLoading = false;
          }, 500);
        }

        if(data.type == InterfacePayloadType.StartLoading){
          this.isLoading = true;
        }
      }
    });

    
    this.resize();
    
    this.onChange.emit({
      type: TablePayloadType.Filter,
      payload: { ...this.table.options },
    });
    
    if(window.location.href.indexOf('/dashboard/batches') == -1){
      this.onChange.emit({
        type: TablePayloadType.Previous,
        payload: { ...this.table.options },
      });
    };

    window.onresize = () => {
      this.resize();
    };

    this.initHorizontalScroll();

  }

  rowCount: number = 0;
  columnWidth: number = 0;

  resize() {
    setTimeout(() => {
      const tableWrapper: HTMLElement = this.tableWrapper.nativeElement;
      this.rowCount = tableWrapper.querySelectorAll(
        ".table__header > div:not(.hide)"
      ).length;
      this.columnWidth = Math.ceil(tableWrapper.offsetWidth / this.rowCount);
    });
  }

  groupBy(group: string) {
    this.isLoading = true;
    this.table.options.page.groupBy = group;
    this.table.options.page.current = 1;
    this.resize();
    this.onChange.emit({
      type: TablePayloadType.GroupBy,
      payload: { ...this.table.options },
    });
  }

  orderBy(column: string) {
    if (this.table.options.page.orderBy.column == column) {
      this.table.options.page.orderBy.direction =
        this.table.options.page.orderBy.direction == TableOrderByDirection.ASC
          ? TableOrderByDirection.DESC
          : TableOrderByDirection.ASC;
    } else {
      this.table.options.page.orderBy.column = column;
      this.table.options.page.orderBy.direction = TableOrderByDirection.ASC;
    }

    this.onChange.emit({
      type: TablePayloadType.OrderBy,
      payload: { ...this.table.options },
    });
  }

  onClickCapture(func:Function){
    if(func){
      func();
    }
  }
  
  initColumns() {
    if (this.table.options.columns?.length == 0 && this.table.data.length > 0) {
      //this.table.options.columns = Object.keys(this.table.data[0]);
    }
  }

  pagerNext() {
    if (this.table.options.page.current != this.table.options.page.count &&  this.table.options.page.count && !this.isLoading){
      this.isLoading = true;
      if (this.table.options.page.current < this.table.options.page.count) {
        this.onChange.emit({
          type: TablePayloadType.Next,
          payload: { ...this.table.options },
        });
      }
    }
  }

  pagerPrevious() {
    
    if (this.table.options.page.current > 1 && !this.isLoading) {
      this.isLoading = true;
      this.onChange.emit({
        type: TablePayloadType.Previous,
        payload: { ...this.table.options },
      });
    }
  }

  filter_toggles:boolean = false;

  toggleFilter(){
    this.filter_toggles = !this.filter_toggles;
    this.is.interfaceBS.next({type: InterfacePayloadType.ListFilter, payload: {toggled:this.filter_toggles, is_new:true}});
  }
}
