import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services";
import { Account, Role } from "src/app/models";
import { Claim } from "src/app/models/account";
import {DocumentRpcService} from "../../services/document.rpc.service";
@Component({
  selector: "template-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  Role = Role;
  role: Role;
  constructor(private _as: AuthenticationService, private doc:DocumentRpcService) {
    this.role = this._as.getClaim(Claim.Role);
  }
  async ngOnInit() {
  }
}
