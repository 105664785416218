import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { Routes, RouterModule } from "@angular/router";
import { Templates } from "./templates";
import { ReactiveFormsModule } from "@angular/forms";

import { AuthenticationService } from "./services";
import { Claim, Role } from "./models/account";
import { CanLoadDashboard } from "./guards";
import {DocumentPreviewComponent} from "./views/document-preview/document-preview.component";
import {AsciMessagePreviewComponent} from "./views/asci-message-preview/asci-message-preview.component";

const routes: Routes = [
  {
    path: "",
    component: Templates.Login,
  },
  { path: "reset/:code", component: Templates.Login },
  {
    path: "document/asci/messages",
    component: AsciMessagePreviewComponent,
  },
  {
    path: "document/:bid/:did",
    component: DocumentPreviewComponent,
  },
  {
    path: "dashboard",
    canLoad: [CanLoadDashboard],
    //@ts-ignore
    loadChildren: () => {
      const _auth = new AuthenticationService();
      const role = _auth.getClaim(Claim.Role);
      switch (role) {
        case Role.Alpha:
          return import("./modules/dashboard/alpha/alpha.module").then(
            (m) => m.AlphaModule
          );
          break;
        case Role.Clerk:
          return import("./modules/dashboard/alpha/alpha.module").then(
            (m) => m.AlphaModule
          );
          break;
        case Role.Manager:
          return import("./modules/dashboard/alpha/alpha.module").then(
            (m) => m.AlphaModule
          );
          break;
        case Role.Supplier:
          return import("./modules/dashboard/supplier/supplier.module").then(
            (m) => m.SupplierModule
          );
          break;
        case Role.Importer:
          return import("./modules/dashboard/importer/importer.module").then(
            (m) => m.ImporterModule
          );
          break;
        case Role.Trucker:
          return import("./modules/dashboard/trucker/trucker.module").then(
            (m) => m.TruckerModule
          );
          break;
      }
    },
  },
];

@NgModule({
  declarations: [Templates.Login],
  imports: [RouterModule.forRoot(routes, {}), ReactiveFormsModule, CommonModule],
  exports: [RouterModule],
  providers: [CanLoadDashboard],
})
export class AppRoutingModule {}
