import "./app.prototypes";
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, enableProdMode} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Routes, RouterModule} from "@angular/router";

import {BrowserModule} from "@angular/platform-browser";
import {DashboardComponent} from "src/app/templates";

import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";

import {AppRoutingModule} from "./app-routing.module";

import {Helpers} from "./helpers";

import {AuthGuard} from "./guards";

import {AppComponent} from "./app.component";

import {MessageComponent} from "./elements";

import {AuthenticationService} from "./services";
import {DropDownFilterPipe} from "./pipes/drop-down-filter.pipe";
import {
  AccountSettingsComponent,
  BatchesComponent,
  BatchesSupplierComponent,
  EmailsComponent,
  AccountsComponent,
  CreateBatchComponent,
  CreateBatchSupplierComponent,
  BatchDetailsComponent,
  AccountSettingsAlphaComponent,
  BatchesImporterComponent,
  BatchesTruckerComponent,
  CreateBatchImporterComponent,
} from "src/app/views";
import {
  FilterService,
  InterfaceService,
  DocumentService,
  WorkspaceService,
} from "src/app/services";

import {
  TopBarComponent,
  TableComponent,
  SimpleMenuComponent,
  ModalComponent,
  AccountAssociatorComponent,
  AutocompleteComponent,
  DocumentComponent,
  DropdownFilterComponent,
  UploadComponent,
  MultiSelectorComponent,
  BatchConflictComponent,
} from "src/app/elements";

import {UploadDirective} from "src/app/directives";

import {
  FilesizePipe,
  FiletypeIconPipe,
  FilterStatusPipe,
  FilterPipe,
  TruncateTextPipe,
  SafePipe,

} from "src/app/pipes";

import {
  TableColumnTypePipe,
  AwbBolPipe,
  OrderNumbersPipe,
  GovStatusesPipe,
  BatchStatusPipe,
  TagsPipe,
  AwbBolNumberPipe
} from "src/app/pipes/table-column-type.pipe";

import {AlphaComponent} from "./modules/dashboard/alpha/alpha.component";
import {BatchHistoryComponent} from "./elements/batch-history/batch-history.component";
import {SearchFilterComponent} from "./elements/search-filter/search-filter.component";
import {MultiFilterComponent} from "./elements/multi-filter/multi-filter.component";
import {EmailComponent} from "./elements/email/email.component";
import {BatchStatusesComponent} from './elements/batch-statuses/batch-statuses.component';
import {BatchDetailsMainComponent} from './views/batch-details-main/batch-details-main.component';
import {StateTogglerComponent} from './elements/state-toggler/state-toggler.component';
import {ListFilterComponent} from './elements/list-filter/list-filter.component';
import {ElementDirective} from './directives';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from "@angular/material/core";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatSortModule} from "@angular/material/sort";

import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {DatePickerComponent} from './elements/date-picker/date-picker.component';
import {TextFieldComponent} from './elements/text-field/text-field.component';
import {TableNgComponent} from './elements/table-ng/table-ng.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatBadgeModule} from '@angular/material/badge';
import {QuickEditComponent} from './elements/quick-edit/quick-edit.component';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {MatIconModule} from "@angular/material/icon";
import {GatewayRpcService} from "./services/gateway.rpc.service";
import {AuthenticationRpcService} from "./services/authentication.rpc.service";
import { ClipboardComponent } from './elements/clipboard/clipboard.component';
import { TagValuesComponent } from './elements/tag-values/tag-values.component';
import { DocumentPreviewComponent } from './views/document-preview/document-preview.component';
import { AsciMessagePreviewComponent } from './views/asci-message-preview/asci-message-preview.component';


@NgModule({
  declarations: [
    AppComponent,
    AlphaComponent,
    DashboardComponent,
    BatchesComponent,
    BatchesSupplierComponent,
    BatchesImporterComponent,
    BatchesTruckerComponent,
    AccountSettingsComponent,
    AccountSettingsAlphaComponent,
    AccountsComponent,
    EmailsComponent,
    CreateBatchComponent,
    CreateBatchSupplierComponent,
    CreateBatchImporterComponent,
    BatchDetailsComponent,
    MessageComponent,
    TopBarComponent,

    BatchConflictComponent,

    SimpleMenuComponent,

    AccountAssociatorComponent,

    AutocompleteComponent,
    TableComponent,
    ModalComponent,
    DocumentComponent,
    DropdownFilterComponent,
    MultiSelectorComponent,
    BatchConflictComponent,
    UploadComponent,

    UploadDirective,

    FilesizePipe,
    FiletypeIconPipe,
    DropDownFilterPipe,
    FilterPipe,
    FilterStatusPipe,
    SafePipe,
    TruncateTextPipe,
    TableColumnTypePipe,


    OrderNumbersPipe,
    GovStatusesPipe,
    AwbBolPipe,
    BatchStatusPipe,
    TagsPipe,
    AwbBolNumberPipe,

    BatchHistoryComponent,
    SearchFilterComponent,
    MultiFilterComponent,
    EmailComponent,
    BatchStatusesComponent,
    BatchDetailsMainComponent,
    StateTogglerComponent,
    ListFilterComponent,

    ElementDirective,

    DatePickerComponent,

    TextFieldComponent,

    TableNgComponent,

    QuickEditComponent,
      ClipboardComponent,
      TagValuesComponent,
      DocumentPreviewComponent,
      AsciMessagePreviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatBadgeModule,
    NgxMatTimepickerModule,
    MatIconModule
  ],

  providers: [
    FilterService,
    WorkspaceService,
    InterfaceService,
    DocumentService,
    GatewayRpcService,
    AuthenticationRpcService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Helpers.AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Helpers.ErrorInterceptor,
      multi: true,
    },
    AuthenticationService,
    AuthGuard,
    MatDatepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
}

enableProdMode();
