<div class="autocomplete__wrapper" [ngClass]="{active:active || disableToggle, light:disableToggle}">
    <div class="autocomplete__input">
        <input #input type="text" [(ngModel)]="search" (keyup)="keyEvents($event)" (keydown)="keyDownEvents($event)" (focus)="active=true" [placeholder]="placeholder">
        <button *ngIf="!disableToggle" (click)="toggle()">+</button>
    </div>
    <div #viewpoint class="autocomplete__results" *ngIf="data.length > 0 && search != ''" [ngClass]="{'scrollbar__': data.length > 4 }"> 
        <div class="autocomplete__item" *ngFor="let item of data; let idx = index" [ngClass]="{'active': highlighted == idx}" (click)="onSelect(item)"> 
            <p>{{item.label}}</p>
        </div>
        <div class="autocomplete__item" *ngIf="data.length == 0">
            <p>Sorry no entry matches your query.</p>
        </div>
    </div>
</div> 