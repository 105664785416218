import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,} from "@angular/core";
import {DropDownFilterData, DropDownFilterOptions, MessageType, Role,} from "src/app/models";
import {AuthenticationService, BatchService, InterfaceService, MessageService} from "src/app/services";
import {InterfacePayloadType} from "../../services/interface.service";
import {Claim} from "../../models/account";

@Component({
  selector: "element-quick-edit",
  templateUrl: "./quick-edit.component.html",
  styleUrls: ["./quick-edit.component.scss"],
})
export class QuickEditComponent implements OnInit, OnChanges {
  @ViewChild("input") input: ElementRef = new ElementRef(null);

  @Input() toggle = false;
  @Input() selection: any[] = [];
  @Input() filter: any = {};
  @Output() onChange: EventEmitter<OnChange> = new EventEmitter();

  current_role:Role;

  constructor(private _bs: BatchService, private _ms: MessageService, private _is:InterfaceService, _as:AuthenticationService) {
    this.current_role = _as.getClaim(Claim.Role);
  }

  states = {
    panel: false,
    adjust: true,
    saved: false,
    is_saving: false,
    change_detect: false,
  };
  statusSettings: DropDownFilterOptions = {
    placeholder: "Status",
    icon: "icon-cheveron-down",
    keys: {
      children: "statuses",
      identifier: "bsid",
      label: "label",
    },
  };

  role = Role;

  status: DropDownFilterData[] = [];

  _status: string[] = [];

  isLoading = false;

  selected_status: number | null = null;

  eta: string | null = null;

  values: string[] | any = [];



  remove(item: string) {
    this.values = this.values.filter((v: any) => v != item);
  }

  keyup(_event: KeyboardEvent) {
    if (!!this.input.nativeElement.value) {
      this.input.nativeElement.value = this.input.nativeElement.value
        .replace(/ /g, "")
        .replace(/-/g, "");
    }
  }

  add() {
    const input_value = this.input.nativeElement.value;
    if (input_value != "") {
      if (!this.values.find((v: any) => v == input_value)) {
        this.values = [...this.values, input_value];
      } else {
        this._ms.set({
          type: MessageType.Warning,
          message: `This tag already exists.`,
          delay: 2500,
        });
      }
      this.input.nativeElement.value = "";
    }
  }

  keydown(event: KeyboardEvent) {
    if (event.key === "Tab" || event.key === "," || event.key === "Enter") {
      event.preventDefault();
      this.add();
      this.toggle = true;
    }
  }

  async onUpdate(_e: any) {
    this.isLoading = true;
    console.log(_e);
    if (this.selection.length == 1 && this.values.length > 0) {
      await this._bs.updateBatchTags(this.selection[0], this.values);
    }

    if (this.selected_status) {
      await this._bs.bulkUpdateBatchStatus(
        this.selection,
        this.selected_status
      );
    }

    if (this.eta) {
      await this._bs.bulkUpdateBatchETAs(this.selection, this.eta);
    }

    this.onChange.emit({type: OnChangeType.Reload, data: this.states.panel});
    this.isLoading = false;
    this.trigger();
  }

  trigger() {
    this.states.panel = !this.states.panel;
    this.onChange.emit({type: OnChangeType.Toggle, data: this.states.panel});
  }

  loadBatch() {
    if (this.states.panel) {
      this.values = [];
      if (this.selection.length == 1) {
        const that = this;
        this._bs.getBatch(this.selection[0]).then((res) => {
          if (res && res.tags) {
            that.values = res.tags;
          }
        });
      }
    }
  }


  exportSelected() {
    console.log(this.selection);
  }

  filterChange(evt: any) {
    console.log(evt);
    if (evt.type == "dropdown-filter") {
      this.selected_status = evt.payload.bsid;
    }

    if (evt.type == "date-filter") {
      if (evt.payload.search.length > 0) {
        this.eta = evt.payload.search[0];
      }
    }
  }

  async exportSelection(evt:any){
    if(this.selection.length > 0 && this.filter){
      const notice = await this._bs.exportQuickEditSelection(this.selection, this.filter);
      this._is.interfaceBS.next({
        type: InterfacePayloadType.SetNotice,
        payload: notice,
      });
      this.trigger();
    }
  }
  ngOnInit(): void {
    this._bs.getStatusesFilters().then((res: any) => {
      this.status = [{statuses: res, label: "Statuses"}];
    });
    this.states.panel = this.toggle;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggle) {
      this.states.panel = changes.toggle.currentValue;
      this.loadBatch();
    }
  }

  protected readonly Role = Role;
}

export enum OnChangeType {
  Toggle,
  Reload,
}

export interface OnChange {
  type: OnChangeType;
  data: any;
}
