
<div class="batch__history_issue" *ngIf="issueNotice && showBanner">
    <div class="message">
      <i class="icon-warning"></i>
    <p *ngIf="!isIncomplete">There's an issue with the current batch.</p>
    <p *ngIf="isIncomplete">Your batch is incomplete. Please review the issue before proceesing.</p>
    </div>
    <div class="actions">
      <button class="button__ outline" (click)="toggle()">Review Issue</button>
    </div>
  </div>
<div class="batch__history" *ngIf="history.length > 0">
    <div class="label" [ngClass]="{'active': toggled}" (click)="toggle()">
        <p>Batch History</p>
        <i class="icon-cheveron-up"></i>
    </div>    
    <div class="content scrollbar__" [ngClass]="{'active': toggled}">
        
        <div class="item" *ngFor="let item of history; let i = index" [ngClass]="{'single': history.length == 1, 'error': item.type == 'Issue'}">
                <i *ngIf="item.type != 'Issue' &&  item.type != 'Incomplete'" class="icon-info"></i>
                <i *ngIf="item.type == 'Issue' || item.type == 'Incomplete'" class="icon-warning"></i>
                <p class="message">{{item.message}}</p>
                <p class="date">{{item.createdOn}}</p>
        </div>

    </div>    
</div>