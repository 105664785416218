import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpEvent,
  HttpProgressEvent,
  HttpSentEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";

import { IDocument } from "./batch.service";

import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { config } from "../app.config";
import {DocumentRpcService} from "./document.rpc.service";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private http: HttpClient, private doc:DocumentRpcService) {
    this.getDocumentTypes();
  }

  documentsBS: BehaviorSubject<IDocument[] | any> = new BehaviorSubject([]);
  documents: Observable<IDocument[]> = this.documentsBS.asObservable();

  documentTypesBS: BehaviorSubject<any> = new BehaviorSubject([]);
  documentTypes: Observable<any> = this.documentTypesBS.asObservable();

  get documentsValue() {
    return this.documentsBS.getValue();
  }

  get documentTypesValue() {
    return this.documentTypesBS.getValue();
  }

  add(doc: IDocument) {
    this.documentsBS.next([...this.documentsValue, doc]);
  }

  remove(doc: IDocument) {
    this.http
      .delete(`document/${doc.did}`)
      .toPromise()
      .then(() => {
        this.documentsBS.next(
          this.documentsValue.filter((d: IDocument) => d.did != doc.did)
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
      });
  }

  async getDocumentTypes() {
    this.documentTypesBS.next(
      await this.http.get(`document/types`).toPromise()
    );
  }

  updateDocumentType(documentId: string, documentTypeId: string) {
    return this.http
      .put(`document/${documentId}`, { dtid: documentTypeId })
      .toPromise();
  }

  upload(batchId: string, file: File) {
    const form = new FormData();
    form.append("file", file);

    this.doc.upload(batchId,file).then(res=>console.log(res)).catch(err=>console.log(err));
    this.doc.progress = (per)=>
    {
      return per;
    };
    return this.http
      .post(`document/${batchId}`, form, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event: any | HttpProgressEvent) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (event.total) {
                const progress = Math.round((100 * event.loaded) / event.total);
                return { status: "progress", message: progress };
              }
            case HttpEventType.Response:
              if (event.body){
                return { status: "complete", message: event.body };
              }
            default:
              return `Unhandled Event:${event.type}`;
          }
        })
      );
  }

  getDocumentToken(documentId: string) {
    return this.http.get<any>(`document/${documentId}/link`).toPromise();
  }

  getDocumentDownloadLink(documentToken: string) {
    return `${config.apiUrl}document/${documentToken}/direct`;
  }

  printAll(bid: string) {
    return this.http.get<any>(`document/batch/${bid}`).toPromise();
  }
}
