import { Router } from "@angular/router";
import { Role } from "src/app/models";
import { FilterOptions } from "../../list-filter/list-filter.settings";

export enum TablePayloadType {
  Next = "next",
  Previous = "previous",
  OrderBy = "orderBy",
  GroupBy = "groupBy",
  Filter = "filter",
  Filterables = "filterables",
  Reload = "reload"
}

export interface OnTableChangePayload {
  type: TablePayloadType;
  payload: any;
}

export interface TableData {
  data: any;
  options: TableOptions;
}
export interface TableOptions {
  columns?: TableColumn[];
  groups?: TableGroups[];
  page: TablePageOptions;
  layout?: TableLayout;
}

export interface TableLayout {
  pagerLocation: TablePagerLocation;
  header: boolean;
  footer: boolean;
  hideActions?: boolean;
}

export enum TablePagerLocation {
  Top = "top",
  Bottom = "bottom",
}

export interface TableColumn {
  id: string;
  label: string;
  type?: TableColumnType;
  group?: Role;
  width?:number;
  onClick?: boolean;
  function?: Function;
  defaultValue?:string;
}
export enum TableColumnType {
  General = "general",
  DateTime = "datetime",
  Date = "date",
  BatchStatus = "batch-status",
  Status = "status",
  MoreInfo = "more-info",
  GovStatuses = "gov-statuses",
  BookBookmark = "batch-bookmark",
  TrackerLink = "tracker-link",
  AWBBOL = "awb-bol",
  Dummy = "dummy",
}

export interface TableGroups {
  id: string;
  label: string;
  tabWidth?: number;
  bgColor?: string;
  textColor?: string;
  onClick?: Function;
}
export interface TablePageOptions {
  count?: number;
  orderBy: TableOrderBy;
  groupBy?: string;
  columns?:any
  filter: FilterOptions | string | null | FilterOptions[];
  filterables?: string | null;
  size: number;
  current: number;
}

export interface TableOrderBy {
  column: string;
  direction: TableOrderByDirection;
}

export enum TableOrderByDirection {
  DESC = "desc",
  ASC = "asc",
}

export interface TableAction {
  onClick: boolean;
  name: string;
  icon: string;
  // tslint:disable-next-line:ban-types
  function: Function;
  router?: Router;
  scope?: any;
  // tslint:disable-next-line:ban-types
  hideCondition?: Function;
}
