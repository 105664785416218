import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filetypeIcon",
})
export class FiletypeIconPipe implements PipeTransform {
  transform(filename: string, isHtml: boolean = false): string {
    if (!filename) return "";

    const ext = filename.split(".")[filename.split(".").length - 1];

    let icon = "icon-doc-";
    let paths = 1;

    switch (ext) {
      case "txt":
        icon += "txt";
        paths = 18;
        break;
      case "doc" || "docx":
        icon += "document";
        paths = 18;
        break;
      case "rtf":
        icon += "rtf";
        paths = 18;
        break;
      case "csv":
        icon += "csv";
        paths = 7;
        break;
      case "pdf":
        icon += "pdf";
        paths = 7;
        break;
      case "jpg" || "jpeg" || "gif":
        icon += "jpg";
        paths = 8;
        break;

      case "png":
        icon += "png";
        paths = 8;
        break;
      case "mp3":
        icon += "mp3";
        paths = 7;
        break;
      case "avi" || "mp4":
        icon += "avi";
        paths = 7;
        break;
      case "xls" || "xlsx":
        icon += "excel";
        paths = 7;
        break;
      case "zip" || "rar" || "7z":
        icon += "zip";
        paths = 8;
        break;
      default:
        icon += "document";
        paths = 18;
        break;
    }

    const wrapper = document.createElement("div");

    for (let index = 1; index <= paths; index++) {
      wrapper.innerHTML += `<i class="path${index}"></i>`;
    }

    return !isHtml ? icon : wrapper.innerHTML;
  }
}
