import {Injectable} from '@angular/core';
import {GatewayRpcService, Namespace, Version} from "./gateway.rpc.service";

@Injectable({
  providedIn: 'root'
})
export class BatchRpcService {

  constructor(private rpc: GatewayRpcService) {

  }

  async createBatchDocuments(did: string[], bid: string) {
    try {
      return await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Batch, action: BatchActions.CreateBatchDocuments, data: {bid, did}});
    } catch (e) {
      return e;
    }
  }

  async updateBatchDocuments(bid: string, newBid: string) {
    try {
      return await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Batch, action: BatchActions.UpdateBatchDocuments, data: {bid, newBid}});
    } catch (e) {
      return e;
    }
  }

}

export enum BatchActions {
  UpdateBatchDocuments = "UpdateBatchDocuments",
  CreateBatchDocuments = "CreateBatchDocuments"
}
