const isTesting = false;
export const Log = (...args: any) => {
  if (isTesting) {
    console.log(...args);
  }
};
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const getMime = (ext:string)=>{
  return mime[ext] ?? "application/octet-stream";
};
export const downloadData = (data: Uint8Array, filename: string, ext:string) => {


  // tslint:disable-next-line:one-variable-per-declaration
  let blob: any, url: string;

  blob = new Blob([data], {
    type: getMime(ext)
  });

  url = window.URL.createObjectURL(blob);

  downloadURL(url, filename);

  setTimeout(() => {
    return window.URL.revokeObjectURL(url);
  }, 1000);

};
export const TextToClipboard = (text:string) => {
  // Create a new clipboard object
  const clipboard = navigator.clipboard;
  // Write the text to the clipboard
  clipboard.writeText(text)
    .then(() => {
      // console.log('Text copied to clipboard');
    })
    .catch((error) =>{
      console.error('Failed to copy text: ', error);
    });
};

const downloadURL = (data: any, filename: string) => {
  let a: any;
  a = document.createElement('a');
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
};

export const mime:any = {
  html: "text/html",
  htm: "text/html",
  shtml: "text/html",
  css: "text/css",
  xml: "text/xml",
  gif: "image/gif",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  js: "application/x-javascript",
  atom: "application/atom+xml",
  rss: "application/rss+xml",
  mml: "text/mathml",
  txt: "text/plain",
  jad: "text/vnd.sun.j2me.app-descriptor",
  wml: "text/vnd.wap.wml",
  htc: "text/x-component",
  png: "image/png",
  tiff: "image/tiff",
  tif: "image/tiff",
  wbmp: "image/vnd.wap.wbmp",
  ico: "image/x-icon",
  jng: "image/x-jng",
  bmp: "image/x-ms-bmp",
  svg: "image/svg+xml",
  webp: "image/webp",
  jar: "application/java-archive",
  war: "application/java-archive",
  ear: "application/java-archive",
  hqx: "application/mac-binhex40",
  doc: "application/msword",
  pdf: "application/pdf",
  ps: "application/postscript",
  eps: "application/postscript",
  ai: "application/postscript",
  rtf: "application/rtf",
  xls: "application/vnd.ms-excel",
  ppt: "application/vnd.ms-powerpoint",
  wmlc: "application/vnd.wap.wmlc",
  kml: "application/vnd.google-earth.kml+xml",
  kmz: "application/vnd.google-earth.kmz",
  "7z": "application/x-7z-compressed",
  cco: "application/x-cocoa",
  jardiff: "application/x-java-archive-diff",
  jnlp: "application/x-java-jnlp-file",
  run: "application/x-makeself",
  pl: "application/x-perl",
  pm: "application/x-perl",
  prc: "application/x-pilot",
  pdb: "application/x-pilot",
  rar: "application/x-rar-compressed",
  rpm: "application/x-redhat-package-manager",
  sea: "application/x-sea",
  swf: "application/x-shockwave-flash",
  sit: "application/x-stuffit",
  tcl: "application/x-tcl",
  tk: "application/x-tcl",
  der: "application/x-x509-ca-cert",
  pem: "application/x-x509-ca-cert",
  crt: "application/x-x509-ca-cert",
  xpi: "application/x-xpinstall",
  xhtml: "application/xhtml+xml",
  zip: "application/zip",
  bin: "application/octet-stream",
  exe: "application/octet-stream",
  dll: "application/octet-stream",
  deb: "application/octet-stream",
  dmg: "application/octet-stream",
  eot: "application/octet-stream",
  iso: "application/octet-stream",
  img: "application/octet-stream",
  msi: "application/octet-stream",
  msp: "application/octet-stream",
  msm: "application/octet-stream",
  mid: "audio/midi",
  midi: "audio/midi",
  kar: "audio/midi",
  mp3: "audio/mpeg",
  ogg: "audio/ogg",
  ra: "audio/x-realaudio",
  "3gpp": "video/3gpp",
  "3gp": "video/3gpp",
  mpeg: "video/mpeg",
  mpg: "video/mpeg",
  mov: "video/quicktime"
};
