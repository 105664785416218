
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  Input,
} from "@angular/core";
import { OnChangePayload, TypeEnum } from "src/app/models";

@Component({
  selector: 'view-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  //@ts-ignore
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter(null);
  
  @Input() selected: string = '';

  addEvent(element: HTMLInputElement) {
    this.select(element.value);
  }

  select(value: any) {
    const res = (value === '')?[]:[value]
    this.change.emit({
      type: TypeEnum.TextFieldFilter,
      payload: {
        search:res ,
        type: "select",
      },
    });
  }
}
