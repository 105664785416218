import { Component, OnInit } from "@angular/core";
import { FormsModel } from "src/app/models/forms";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  AccountService,
  AuthenticationService,
  MessageService,
} from "src/app/services";
import { MustMatch } from "src/app/helpers/util";
import { Role, MessageType } from "src/app/models";
import { Claim } from "src/app/models/account";

@Component({
  selector: "view-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsAlphaComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _as: AccountService,
    private _ms: MessageService,
    private _auth: AuthenticationService
  ) {}

  pane: string = "general";
  roles: Role[] = [];
  Role = Role;

  account_id: string | null = "";
  account_name: string = "";
  account_role_label: string = "";

  forms: FormsModel = {
    account_settings: {
      form: this.fb.group({
        contact_info: this.fb.group({
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
            ],
          ],
          phone: [""],
          primary_contact: [""],
        }),
        update_password: this.fb.group(
          {
            password: [""],
            confirm_password: [""],
          },
          {
            validator: MustMatch("password", "confirm_password"),
          }
        ),
        notification_settings: this.fb.group({
          batch_issue: [""],
        }),
        business_info: this.fb.group({
          fictitiouse_name: [""],
          custom_id: ["", Validators.required],
        }),
        catch_all_settings: this.fb.group({
          domain: ["", Validators.required],
        }),
        role: ["", Validators.required],
        name: ["", Validators.required],
      }),
      fields: null,
      data: null,
      roles: [],
      error: false,
      loading: false,
      submitted: false,
      toggled: false,
    },
  };

  get field() {
    return this.forms.account_settings.form.controls;
  }

  async ngOnInit() {
    this.setForms();

    this.forms.account_settings.roles = await this._as.getRoles() || [];

    this.account_id =
      this.route.snapshot.paramMap.get("aid") || this._auth.getClaim(Claim.ID);
    if (this.account_id) {
      this.forms.account_settings.data = await this._as.getAccount(
        this.account_id
      );

      this.account_name = this.forms.account_settings.data.name;

      this.account_role_label = this.forms.account_settings.roles.find(
        (r) => r.rid == this.forms.account_settings.data.role.toLowerCase()
      ).name;

      this.setFormData(this.forms.account_settings.data);

      this.forms.account_settings.form?.controls.role.patchValue(
        this.forms.account_settings.data.role.toLowerCase()
      );
    }
  }

  getRoleName() {
    return this.forms.account_settings.roles.find(
      (r) => r.rid == this.forms.account_settings.data.role
    );
  }

  async onSubmit() {
    this.forms.account_settings.submitted = true;

    if (this.forms.account_settings.form?.invalid) {
      return;
    }

    this.forms.account_settings.loading = true;

    const data = this.forms.account_settings.form?.getRawValue();
    console.log(data);
    await this._as.updateAccount(this.account_id, data);

    this._ms.set({
      message: "Account Updated",
      type: MessageType.Success,
      delay: 3000,
    });
  }

  hasError(
    form: string,
    formGroup: string,
    field: string | (string | number)[]
  ) {
    return this.forms[form].form?.controls[formGroup].get(field)?.errors;
  }

  setFormData(data: any) {
    this.forms.account_settings.form = this.fb.group({
      contact_info: this.fb.group({
        email: [
          data?.contact_info?.email ? data?.contact_info?.email : "",
          [
            Validators.required,
            Validators.pattern(
              "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
            ),
          ],
        ],
        phone: data?.contact_info?.phone ? data?.contact_info?.phone : "",
        primary_contact: data?.contact_info?.primary_contact
          ? data?.contact_info?.primary_contact
          : "",
      }),
      update_password: this.fb.group(
        {
          password: [
            data?.update_password?.password
              ? data?.update_password?.password
              : "",
          ],
          confirm_password: [
            data?.update_password?.confirm_password
              ? data?.update_password?.confirm_password
              : "",
          ],
        },
        {
          validator: MustMatch("password", "confirm_password"),
        }
      ),
      notification_settings: this.fb.group({
        batch_issue: [
          data?.notification_settings?.batch_issue
            ? data?.notification_settings?.batch_issue
            : "",
        ],
      }),
      business_info: this.fb.group({
        fictitiouse_name: [
          data?.business_info?.fictitiouse_name
            ? data?.business_info?.fictitiouse_name
            : "",
        ],
        custom_id: [
          data?.business_info?.custom_id ? data?.business_info?.custom_id : "",
          Validators.required,
        ],
      }),
      catch_all_settings: this.fb.group({
        domain: [
          data?.catch_all_settings?.domain
            ? data?.catch_all_settings?.domain
            : "",
          Validators.required,
        ],
      }),
      role: [data?.role ? data?.role : "", Validators.required],
      name: [data?.name ? data?.name : "", Validators.required],
    });

    this.forms.account_settings.fields = this.forms.account_settings.form.controls;
  }

  setForms() {
    this.forms.account_settings.fields = this.forms.account_settings.form.controls;
  }
}
