import { Pipe, PipeTransform } from "@angular/core";
import { TableColumn, TableColumnType } from "../elements/table/types";
import { FormatDate, FormatDateInputValue } from "../helpers/util";
import { Batch } from "../services";
import { DomSanitizer } from "@angular/platform-browser";
import {IBatch} from "../elements/table-ng/table-ng.component";
import {config} from "../app.config";

@Pipe({
  name: "order_numbers",
})
export class OrderNumbersPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string[]):string|null{
	
	let html = "";

	const orders = typeof value == "string" ? JSON.parse(value) : value;
	
	if ( orders.length == 0) {
	    return ` 
	    <div class="text-placeholder w20" ></div> 
	    <div class="text-placeholder w70" ></div> 
	    <div class="text-placeholder w40" ></div> 
	    `;
	}

	html += `<div class="more_items_wrapper">`;
	html += `<div class="more_info__content">`;
	    
	for (let x = 0; x < orders.length; x++) {
	    const order = orders[x];
	    html += `<span>${order}</span>`;
	}
       
	html += `</div>`;
	html += `<i class="icon-cheveron-down"><span>Order Numbers</span></i>`;
	html += `</div>`;

	return html;

    }   
}

@Pipe({
  name: "awb_bol_number",
})
export class AwbBolNumberPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string, item:IBatch):any{
    	if (value == null || value == undefined || value.length == 0) {
	  return ` 
	  <div class="text-placeholder w20" ></div> 
	  <div class="text-placeholder w70" ></div> 
	  <div class="text-placeholder w40" ></div> 
	  `;
	}
	
	let link = "#";

	if(item.courier_type != undefined){
	    //@ts-ignore
	    if(item.courier_type == 1){
		link = "https://connect.track-trace.com/for/"+config.trackingID+"/aircargo/"+item.awb_bol_number,'_blank';
	    }
	    //@ts-ignore
	    if(item.courier_type == 0){
		link = "https://connect.track-trace.com/for/"+config.trackingID+"/billoflading/"+item.awb_bol_number,'_blank';
	    }
	}
	return this.sanitizer.bypassSecurityTrustHtml(
	    `<div class="truncate_wrapper"><p class="truncate"><a href="${link}" target="_blank">${value}</p></div>`
	);
    }   
}
@Pipe({
  name: "tags",
})
export class TagsPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string[]):string|null{
	
	let html = "";

	const orders = typeof value == "string" ? JSON.parse(value) : value;
		
	if ( orders.length && orders.length == 0) {
	    return ` 
	    <div class="text-placeholder w20" ></div> 
	    <div class="text-placeholder w70" ></div> 
	    <div class="text-placeholder w40" ></div> 
	    `;
	}

	html += `<div class="more_items_wrapper">`;
	html += `<div class="more_info__content">`;
	    
	for (let x = 0; x < orders.length; x++) {
	    const order = orders[x];
	    html += `<span>${order}</span>`;
	}
   
	html += `</div>`;
	html += `<i class="icon-cheveron-down"><span>Tags</span></i>`;
	html += `</div>`;

	return html;

    }   
}


@Pipe({
  name: "batch_status",
})
export class BatchStatusPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string):string{
        return `<span class="batch_status ${value.toLowerCase().replace('*','')}">${value.replace('*','<span>*</span>')}</span>`;
    }   
}



@Pipe({
  name: "awb_bol",
})
export class AwbBolPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: boolean ):string{
	if(value){
	    return '<strong  class="green-text"><i class="icon-plane-outline"></i> <span>&nbsp;&nbsp;AWB</span></strong>';
	}else{
	    return '<strong class="blue-text"><i class="icon-freight-outline"></i> <span>&nbsp;&nbsp;BOL</span></strong>';
	}
    }   
}

@Pipe({
  name: "gov_statuses",
})
export class GovStatusesPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string):string|null{
	
	if(!value) return null;

	let gov_html = "";

	const statuses = typeof value == "string" ? JSON.parse(value) : value;

	if (statuses.length == 1) {
	    return statuses[0].label;
	} else {
	    
	    if (statuses.length == 0) {
		return ` 
		<div class="text-placeholder w20" ></div> 
		<div class="text-placeholder w70" ></div> 
		<div class="text-placeholder w40" ></div> 
		`;
	    }
	    
	    gov_html += `<div class="more_items_wrapper">`;
	    gov_html += `<div class="more_info__content">`;
	    
	    for (let x = 0; x < statuses.length; x++) {
	      const status = statuses[x];
	      gov_html += `<span>${status.label}</span>`;
	    }
	    
	    gov_html += `</div>`;
	    gov_html += `<i class="icon-cheveron-down"><span>Gov. Statused</span></i>`;
	    gov_html += `</div>`;
	}
	return gov_html;
    }   
}

@Pipe({
  name: "placeholder",
})
export class PlaceholderPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
 
    transform(value:any|undefined|null){
	if (!value){
	    return ` 
              <div class="text-placeholder w20" ></div> 
              <div class="text-placeholder w70" ></div> 
              <div class="text-placeholder w40" ></div> 
            `;
	}

	return value;

    }
}

@Pipe({
  name: "tableColumnType",
})
export class TableColumnTypePipe implements PipeTransform {
  bodyOffset: any = document.querySelector("body")?.offsetHeight;
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    value: any,
    column: TableColumn,
    parentEle: HTMLDivElement,
    conflict?: Batch | undefined
  ): unknown {
    
    if(column.type === TableColumnType.AWBBOL){
      if(value){
        return '<strong  class="green-text"><i class="icon-plane-outline"></i> <span>&nbsp;&nbsp;AWB</span></strong>';
      }

      if(value == false){
        return '<strong class="blue-text"><i class="icon-freight-outline"></i> <span>&nbsp;&nbsp;BOL</span></strong>';
      }
    }

    if (column.type && value) {
      switch (column.type) {
        case TableColumnType.BookBookmark:
          return `<i class='icon-bookmark bookmark-active'></i>`;
          break;
        case TableColumnType.BatchStatus:
          return `<span class="batch_status ${value.toLowerCase().replace('*','')}">${value.replace('*','<span>*</span>')}</span>`;
          break;
        case TableColumnType.Status:
          return Boolean(value)
            ? '<span class="status_icon active"></span>'
            : '<span class="status_icon inactive"></span>';
          break;
        case TableColumnType.DateTime:
          let datetime = FormatDate(new Date(value));
          value = datetime
          break;
        case TableColumnType.Date:
          return FormatDateInputValue(new Date(value), 2);
          break;
        case TableColumnType.GovStatuses:
          let gov_html = "";
          const statuses = typeof value == "string" ? JSON.parse(value) : value;

          if (statuses.length == 1) {
            return statuses[0].label;
          } else {
            if (statuses.length == 0) {
              return ` 
              <div class="text-placeholder w20" ></div> 
              <div class="text-placeholder w70" ></div> 
              <div class="text-placeholder w40" ></div> 
              `;
            }
            gov_html += `<div class="more_items_wrapper">`;
            gov_html += `<div class="more_info__content">`;
            for (let x = 0; x < statuses.length; x++) {
              const status = statuses[x];
              gov_html += `<span>${status.label}</span>`;
            }
            gov_html += `</div>`;
            gov_html += `<i class="icon-cheveron-down"><span>${column.label}s</span></i>`;
            gov_html += `</div>`;
          }
          return gov_html;
          break;
        case TableColumnType.MoreInfo:
          let html = "";

          const orders = typeof value == "string" ? JSON.parse(value) : value;

          if (orders.length == 1) {


            parentEle.innerHTML = `<span class="truncate">${orders[0]}</span><span>${orders[0]}</span>`;

            const trunc_ele: HTMLElement | null = parentEle.querySelector(".truncate");
            const span_element: HTMLElement | null = parentEle.querySelector(
              ".truncate + span"
            );
        
            if (span_element && trunc_ele) {
              if (trunc_ele?.offsetWidth <= span_element?.offsetWidth) {
                trunc_ele.style.width = "unset";
                span_element.classList.add("hover");
              } else {
                trunc_ele.style.width = parentEle?.offsetWidth + `px`;
                span_element.style.display = "none";
              }
              return this.sanitizer.bypassSecurityTrustHtml(
                `${trunc_ele.outerHTML}${span_element.outerHTML}`
              );
            } else {
              return parentEle.innerHTML;
            }

          } else {
            if (orders.length == 0) {
              return ` 
              <div class="text-placeholder w20" ></div> 
              <div class="text-placeholder w70" ></div> 
              <div class="text-placeholder w40" ></div> 
              `;
            }
            html += `<div class="more_items_wrapper">`;
            html += `<div class="more_info__content">`;
            for (let x = 0; x < orders.length; x++) {
              const order = orders[x];
              if (conflict && conflict.orderNumber.length > 0) {
                html += `<span class="${
                  conflict.orderNumber.indexOf(order) != -1 ? "conflict" : ""
                }">${order}</span>`;
              } else {
                html += `<span>${order}</span>`;
              }
            }
            html += `</div>`;
            html += `<i class="icon-cheveron-down"><span>${column.label}s</span></i>`;
            html += `</div>`;
          }
          return html;
          break;
        case TableColumnType.Dummy:
          return `<span class="batch_status opened dummy-input">COMMING SOON</span>`;
          break;
        case TableColumnType.BookBookmark:
          return `<i class='icon-bookmark'></i>`;
          break;
        case TableColumnType.TrackerLink:
        
          break;
      }
    } else {
      if (column.type && !value) {
        switch (column.type) {
          case TableColumnType.Dummy:
            return `<span class="batch_status dummy-input">COMING SOON</span>`;
            break;
          case TableColumnType.BookBookmark:
            return `<i class='icon-bookmark'></i>`;
            break;
        }
      }
    }

    if (value == 0 || !value || value == null || value == undefined) {
      return ` 
      <div class="text-placeholder w20" ></div> 
      <div class="text-placeholder w70" ></div> 
      <div class="text-placeholder w40" ></div> 
      `;
    }

    parentEle.innerHTML = `<span class="truncate">${value}</span><span>${value}</span>`;

    const trunc_ele: HTMLElement | null = parentEle.querySelector(".truncate");
    const span_element: HTMLElement | null = parentEle.querySelector(
      ".truncate + span"
    );

    if (span_element && trunc_ele) {
      if (trunc_ele?.offsetWidth <= span_element?.offsetWidth) {
        trunc_ele.style.width = "unset";
        span_element.classList.add("hover");
      } else {
        trunc_ele.style.width = parentEle?.offsetWidth + `px`;
        span_element.style.display = "none";
      }
      return this.sanitizer.bypassSecurityTrustHtml(
        `${trunc_ele.outerHTML}${span_element.outerHTML}`
      );
    } else {
      return parentEle.innerHTML;
    }
  }
}
