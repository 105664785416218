<div class="document__wrapper">
  <div class="title">
    <h3>Documents</h3>
    <span (click)="browseFiles()">Browse files</span>
  </div>
  <div class="document__content scrollbar__">

    <div class="uploader__wrapper">
      <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;"/>
      <div class="uploader__drop_zone" UploadDirective (fileDropped)="onFileDrop($event)">
        <h3 *ngIf="queue.length == 0"><span>Drag and Drop files here to upload.</span></h3>
        <div class="document__listing">
          <div class="document__item" *ngFor="let document of queue" #file>
            <div class="document__progress">
              <div class="progress__bar" [style.width]="document.progress+'%'"></div>
            </div>
            <div class="document__filetype">
              <i class="{{document.name | filetypeIcon}}" [innerHtml]="document.name | filetypeIcon:true">
              </i>
            </div>

            <div class="document__name truncate">{{document.name}} </div>
            <div class="document__type" [ngClass]="{error: (!document.dtid || document.dtid == EmptyGuid)}">
              <element-dropdown-filter [value]="document.dtid" (change)="onChange($event, document)"
                                       [data]="documentTypes"></element-dropdown-filter>
            </div>
            <div class="document__size" [innerHtml]="document.size | filesize"></div>
            <span class="material-symbols-outlined" (click)="openPreview(document)">open_in_new</span>
            <i class="icon-trash" (click)="remove(document)"></i>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
