import { Injectable } from "@angular/core";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { TablePageOptions, TableData } from "../elements/table/types";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Log } from "../utilities";
import { IHistory } from "../elements/batch-history/batch-history.component";
import { OutlookCallback } from "../models";
import { FilterOptions } from "../elements/list-filter/list-filter.settings";
import { switchMap } from "rxjs/operators";
import {BatchRpcService} from "./batch.rpc.service";

@Injectable({ providedIn: "root" })
export class BatchService {
  constructor(private http: HttpClient , private batch:BatchRpcService ) {}

  /**
   * Inits auto save system
   * Only init auto save if 3+ keystrokes are detected and wait for 3-second timer
   * before sending auto-save request. If keys are pressed before 3-second timer ends, reset timer.
   */

  draftBS: BehaviorSubject<Batch | any> = new BehaviorSubject(null);
  draft: Observable<Batch> = this.draftBS.asObservable();
  eventBS: BehaviorSubject<BatchEventPayload | any> = new BehaviorSubject(null);
  event: Observable<BatchEventPayload> = this.eventBS.asObservable();

  draftSubscription: Subscription = new Subscription();

  timer: Timer = {
    timeout: null,
    delay: {
      timer: 3,
      change: 3,
    },
    current: 0,
    changes: 0,
  };
  async getAll(pageOptions: TablePageOptions) {
    return await this.http
      .post<TableData>("batch/list", { page: pageOptions })
      .toPromise();
  }

  async exportWorkspace(pageOptions: TablePageOptions) {
    return await this.http
      .post<TableData>("batch/list/export", { page: pageOptions })
      .toPromise();
  }

  async getNotices() {
    return await this.http
      .get<any[]>("notice/list")
      .toPromise();
  }

  async dismissNotice(notice:any) {
    return await this.http
      .put(`notice/dismiss/${notice.nid}`, {})
      .toPromise();
  }

  async readNotice(notice:any) {
    return await this.http
      .put(`notice/read/${notice.nid}`, {})
      .toPromise();
  }

  async getTags(filter: FilterOptions) {
    return await this.http
      .post<TableData>("batch/tags", { page: { filter: filter } })
      .toPromise();
  }
  async getOrderNumbers(filter: FilterOptions) {
    return await this.http
      .post<TableData>("batch/ordernumbers", { page: { filter: filter } })
      .toPromise();
  }


  getPendingBatchTask(type: string, bid: string) {
    return this.http
      .post<ITask>(`batch/task`, {
        typeId: bid,
        type,
      })
      .toPromise();
  }

  getBatch(batchId: string) {
    return this.http.get<Batch>(`batch/${batchId}`).toPromise();
  }
  getBatchDraft(batchId: string) {
    return this.http.get<Batch>(`batch/${batchId}/draft`).toPromise();
  }
  getBatchRevision(batchId: string) {
    return this.http.get<Batch>(`batch/revision/${batchId}`).toPromise();
  }

  setBatchRevision(batchId: string) {
    return this.http
      .post<Batch>(`batch/revision/${batchId}/set-current`, {})
      .toPromise();
  }

  getBatchRevisions(batchId: string) {
    return this.http
      .get<BatchRevision[]>(`batch/${batchId}/revisions`)
      .toPromise();
  }

  bookmarkBatch(batchId: string) {
    return this.http
      .get<BatchRevision[]>(`batch/bookmark/${batchId}`)
      .toPromise();
  }

  getStatuses() {
    return this.http.get<IStatuses[]>(`batch/statuses`).toPromise();
  }

  getStatusesFilters() {
    return this.http.get<Status[]>(`batch/statuses/filters`).toPromise();
  }
  getGovStatuses() {
    return this.http.get<Status[]>(`batch/gov/statuses`).toPromise();
  }

  checkBatchConflic(
    orderNumbers: string[],
    awbBolNumber: string,
    bid?: string,
    importer: string | null = null
  ) {
    return this.http
      .post<Batch[]>("batch/conflict", {
        orderNumbers,
        awbBolNumber,
        bid,
        importer,
      })
      .toPromise();
  }

  batchConflictMerge(currentBid: string, orderNumbers: string[], bid: string) {
    return this.http
      .post<Batch[]>("batch/conflict/merge", {
        currentBid,
        bid,
        orderNumbers,
      })
      .toPromise();
  }

  initDraft(associations: BatchAssociations[] = []) {
    this.resetTimer();
    this.updateDraft({
      associations,
      orderNumber: [],
      awbBolNumber: "",
      status: BatchType.Temporary,
      isDraft: true,
      // isRevision: true,
      documentsList: [],
      documents: [],
      courierType: true,
    });

    //   this.initAutoSaveSystem();
  }

  async create() {
    this.resetTimer();
    this.updateDraft({
      ...this.draftValue,
      status:
        this.draftValue.status == BatchType.Draft
          ? BatchType.Created
          : this.draftValue.status,
      // isDraft: false,
    });

    return this.initAutoSave(this.draftValue);
  }

  async saveForLater() {
    this.resetTimer();
    this.updateDraft({
      ...this.draftValue,
      status: BatchType.Draft,
      isDraft: true,
    });
    await this.initAutoSave(this.draftValue);
  }

  async discardDraft(bid: string) {
    this.resetTimer();
    return this.http.get("batch/discard/" + bid).toPromise();
  }

  async deleteBatch(bid: string) {
    this.resetTimer();
    return this.http.get("batch/delete/" + bid).toPromise();
  }

  async createOutlookBatch(hash: string) {
    const outlook_callback: OutlookCallback = JSON.parse(atob(hash));
    return this.http.post("batch/create/outlook", outlook_callback).toPromise();
  }

  cancel() {
    //TODO: Add remove temporary batch draft endpoint service if batch is a draft.
  }

  get draftValue() {
    return this.draftBS.getValue();
  }

  updateDraft(draft: Batch) {
    this.draftBS.next(draft);
  }

  deleteBatchDraft(bid: string) {
    return this.http.get("batch/discard/" + bid).toPromise();
  }

  initAutoSaveSystem() {
    let loaded: boolean;

    this.draftSubscription = this.draft.subscribe(async (draft: Batch) => {
      Log("Draft Change Detected: Loaded: " + loaded);

      if (loaded) {
        // Auto-increase current time
        this.timer.changes++;
        // Check if changes meet timer criteria (changes:3)
        if (this.timer.changes >= this.timer.delay.change) {
          // Reset timer everytime change is detected
          this.resetTimer();
          // Re-instantiate timer
          await this.setTimer();

          // Await for timer to meet timer criteria (current:3 seconds)
          // before initializing Auto Save function
          if (this.timer.current == this.timer.delay.timer) {
            this.resetTimer();
            //await this.initAutoSave(draft);
          }
        }
      }

      Log(`Current Changes: ${this.timer.changes}`);
      Log(`Changes Needed: ${this.timer.delay.change}`);

      loaded = true;
    });
  }

  resetTimer() {
    if (this.timer.timeout) { clearInterval(this.timer.timeout); }
    this.timer.current = 0;
    this.timer.changes = 0;
  }

  setTimer() {
    return new Promise((resolver) => {
      Log(`Init Timmer: ${this.timer.current}`);

      this.timer.timeout = setInterval(() => {
        this.timer.current++;

        Log(`Timer: ${this.timer.current}`);

        if (this.timer.current == this.timer.delay.timer) {
          resolver(null);
        }
      }, this.timer.delay.timer * 1000);
    });
  }

  async initAutoSave(draft: Batch) {
    // console.log("Auto Save Triggered.");
    this.timer.current = 0;
    this.timer.changes = 0;
    try {
      const res = await this.http.post<Batch>("batch", draft).toPromise();

      this.draftBS.next({
        ...this.draftValue,
        ...res,
      });

      return res;
    } catch (err) {
      console.log(err);
    }
  }
  bulkUpdateBatchStatus(batches: string[], status: number) {
    return this.http.post(`batch/status/bulk`, { batches, status }).toPromise();
  }
  exportQuickEditSelection(batches: string[], filter: any) {
    return this.http.post(`batch/export/bulk`, { batches, page:filter}).toPromise();
  }
  bulkUpdateBatchETAs(batches: string[], eta: string) {
    return this.http.post(`batch/eta/bulk`, { batches, eta}).toPromise();
  }

  updateBatchTags(bid: string, tags: string[]) {
    return this.http.post(`batch/${bid}/tags`, { tags }).toPromise();
  }

  updateBatchStatuses(batchStatus: BatchStatusRequest) {
    return this.http.post<Batch[]>("batch/statuses", batchStatus).toPromise();
  }

  getBatchStatus(bid: string) {
    return this.http.get<IStatuses>("batch/status/" + bid).toPromise();
  }

  getBatchHistory(bid: string) {
    return this.http.get<IHistory[]>("batch/history/" + bid).toPromise();
  }

  updateBatchStatus(bid: string, status: number) {
    return this.http.post("batch/status/", { bid, status }).toPromise();
  }

  markBatchAsRead(bid: string) {
    return this.http.get("batch/read/" + bid).toPromise();
  }
}

export interface Timer {
  timeout: any;
  delay: {
    timer: number;
    change: number;
  };
  current: number;
  changes: number;
}

export interface Batch {
  associations: BatchAssociations[];
  orderNumber: string[];
  tags?: string[] ;
  awbBolNumber: string;
  referenceNumber?: string;
  containerNumber?: string;
  logCode?: string;
  awb_bol_number?: string;
  status: number;
  statusLabel?: string;
  governmentStatuses?: Status[];
  documentType?: string;
  documentsList: IDocument[];
  documents: IDocument[];
  bid?: string;
  id?: string;
  eta?: string | null;
  etaTime?: string | null;
  lfd?: string | null;
  lfdTime?: string | null;
  parentBid?: string;
  parentBID?: string;
  updatedOn?: string;
  createdBy?: string;
  isDraft?: boolean;
  isRead?: boolean;
  isRevision?: boolean;
  pendingDocuments?: number;
  notes?: string;
  courierType?: boolean;
}

export interface BatchRevision {
  bid: string;
  updatedOn: string;
}

export interface Status {
  bsid: number;
  label: string;
  checked: boolean;
  requiredStatus: number[];
}

export interface BatchAssociations {
  type: string;
  data: {
    id: string;
    label: string;
    primary: boolean;
  } | null;
}

export interface IDocument {
  did: string;
  dtid: string;
  fileExtension: string;
  name: string;
  size: number;
  documentType?: string;
  typeName?: string;
  progress?: number;
}

export enum BatchType {
  Temporary = 2,
  Draft = 3,
  Run = 4,
  Accepted = 5,
  Released = 6,
  Issue = 19,
  Created = 24,
  Completed = 33,
  Opened = 25,
}

export enum BatchEventType {
  ConflictRevise = "conflict-revision",
}

export interface BatchStatusRequest {
  bid: string;
  statuses: number | any;
  message: string | null;
  priority?: boolean;
}
export interface BatchEventPayload {
  type: BatchEventType;
  payload: any;
}

export interface ITask {
  type: string;
  typeId: string;
  completedOn: string;
}
export interface IStatuses {
  status: string | null;
  fda: string | null;
  noaa: string | null;
  usda: string | null;
  cbp: string | null;
}
