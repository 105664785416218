import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: "truncateText",
})
export class TruncateTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(
    value: string,
    style: string = "",
    disableHover: boolean = false
  ): unknown {
    if (value == null || !value.length || value.length == 0) {
      return `
      <div class="text-placeholder w20" ></div>
      <div class="text-placeholder w70" ></div>
      <div class="text-placeholder w40" ></div>
      `;
    }
    if (!disableHover) {
      const truncate_dom = document.createElement('div');
      truncate_dom.classList.add('truncate_wrapper');
      truncate_dom.innerHTML = `<p class="truncate" style="${style}">${value}</p>
									<span>
										${value}

                  </span>`;

      return truncate_dom.outerHTML;

    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        `<div class="truncate_wrapper"><p class="truncate" style="${style}">${value}</p></div>`
      );
    }
  }
}
