export interface Message {
  message: string;
  type: MessageType;
  delay: number;
}

export enum MessageType {
  Error = "error",
  Success = "success",
  Info = "information",
  Warning = "warning"
}
