<!-- <div class="header">
    <div class="left">
        <div class="filter">
            <div class="field__input">
                <select (change)="orderBy($event, filter)" [(ngModel)]="filter">
                    <option value="Recent">Recent</option>
                    <option value="Unread">Unread</option>
                    <option value="Read">Read</option>
                </select>
            </div>
        </div>
    </div>
    <div class="right">
        <div class="info">
            <p class="subject">{{active?.subject}}</p>
            <p class="from">{{active?.from}}</p>
        </div>
        <div class="timestamp" [innerHTML]="active?.receivedOn"></div>
    </div>
</div> -->
<div class="email__wrapper">
    <iframe src="https://email.alphabrokers.com/owa/" style="position: absolute; top:0;bottom:0;left:0;right: 0;width: 100%;height: 100%;"></iframe>
    <!-- <div class="sidebar">
        <div class="listing scrollbar__"  >
            <div class="item" *ngFor="let item of options.data" [ngClass]="{active: active?.eid == item.eid, 'unread':!item.read, 'attachment':item.attachmentCollection.length > 0}" (click)="setActive(item)">
                <div class="info">
                    <p class="subject" [innerHtml]="item.subject | truncateText:'width:265px;':true"></p>
                    <p class="from">{{item.from}}</p>
                </div>
                <div class="timestamp" [innerHTML]="item.receivedOn"></div>
            </div>
            <div class="load_more" (click)="loadMore = !loadMore" [ngClass]="{'loading': loadMore}" *ngIf="options.options?.page?.count > 1 && options.options?.page?.count != options.options?.page?.current" (click)="loadMoreEmails()">
                <p>Load more</p>
                <div class="anime">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
                <div class="anime">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
                <div class="anime">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </div>
      
        </div>
    </div>
    <div class="preview">
        <div class="content scrollbar__" [innerHTML]="active?.body | safe:'html'" *ngIf="active"></div>
        <div class="content inactive"  *ngIf="!active">
            <i class="icon-email"></i>
            <p>No email is active, please select one to preview.</p>
        </div>
        <div class="attachments" *ngIf="active?.attachmentCollection.length > 0">
            <h3>Attachements</h3>
            <div class="listing scrollbar__">
                <div class="item" *ngFor="let item of active?.attachmentCollection" (click)="downloadAttachment(active.eid, item)">
                    <i class="{{item.name | filetypeIcon}}" [innerHtml]="item.name | filetypeIcon:true"></i>
                    <div class="info">
                        <p class="filename" [innerHtml]="item.name |  truncateText:'width:165px;font-size:0.8em;'"></p>
                        <p class="size" [innerHtml]="item.size | filesize:0:false"></p>
                    </div>
                    <div class="actions">
                        <i class="icon-download"></i>
                    </div>
                    <div class="spinner" *ngIf="item.isDownloading">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                      </div>
                </div>


                <div class="item" *ngIf="active?.attachmentCollection.length > 1">
                    <i class="{{active.subject+'.zip' | filetypeIcon}}" [innerHtml]="active.subject+'.zip' | filetypeIcon:true"></i>
                    <div class="info">
                        <p class="filename" [innerHtml]="'Download All' | truncateText:'width:165px;font-size:0.8em;':true"></p>
                        <p class="size" [innerHtml]="active.size | filesize:0:false"></p>
                    </div>
                    <div class="actions">
                        <i class="icon-download"  (click)="downloadAttachments(active.eid)"></i>
                    </div>
                    <div class="spinner" *ngIf="currentDownloadAll.indexOf(active.eid) != -1">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                      </div>
                </div>

            </div>
        </div>
    </div> -->
</div>