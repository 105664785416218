import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { NgxMatTimepickerRef } from "ngx-mat-timepicker/lib/models/ngx-mat-timepicker-ref.interface";
import { OnChangePayload, TypeEnum } from "src/app/models";

@Component({
  selector: "view-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"],
})
export class DatePickerComponent implements OnInit {
  constructor() {}

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    start_time: new FormControl<string | null>(null),
    end: new FormControl<Date | null>(null),
    end_time: new FormControl<string | null>(null),
  });

  //@ts-ignore
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter(null);

  @Input() selected: string[] = [];
  @Input() is_range = true;

  ngOnInit(): void {
    if (this.selected.length > 0) {
      const start = new Date(this.selected[0]);

      if (this.selected.length == 1) {
        this.range.setValue({
          start,
          start_time: start.getHours() + ":" + start.getMinutes(),
          end: null,
          end_time: null,
        });
      }

      if (this.selected.length == 2) {
        const end = new Date(this.selected[1]);
        this.range.setValue({
          start,
          start_time: start.getHours() + ":" + start.getMinutes(),
          end,
          end_time: end.getHours() + ":" + end.getMinutes(),
        });
      }
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type == "start_time" || type == "end_time") {
      this.select(event,type);
    } else {
      this.select(event.value, 'date');
    }
  }

  select(value: any, type:string) {
    const range = this.range.controls;
    let start_date = range.start.value?.toLocaleDateString();
    let end_date = range.end.value?.toLocaleDateString();

    const res: any = [];
    
    if(type == "start_time"){
	range.start_time.setValue(value)
    }else if(type == "end_time"){
	range.end_time.setValue(value)
    }

    if (start_date) {
      if (range.start_time.value) {
        start_date += " " + range.start_time.value;
      } else {
        start_date += " 00:00";
      }
      res.push(start_date);
    }

    if (end_date) {
      if (range.end_time.value) {
        end_date += " " + range.end_time.value;
      } else {
        end_date += " 23:59";
      }
      res.push(end_date);
    }

    this.change.emit({
      type: TypeEnum.DateFilter,
      payload: {
        search: res,
        type: "select",
      },
    });
  }
}
