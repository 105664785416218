import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor() {}

  filterBS: BehaviorSubject<string | any> = new BehaviorSubject(null);
  filter: Observable<string> = this.filterBS.asObservable();
  filterablesBS: BehaviorSubject<string | any> = new BehaviorSubject(null);
  filterables: Observable<string> = this.filterablesBS.asObservable();

  _filterables: any = {
    // suppliers: "",
    // importers: "",
    // truckers: "",
    // status: "",
    // gov_statuses: "",
    // courier_type:""
  };

  get value() {
    return this.filterBS.getValue();
  }

  reset(){
    // this.filterablesBS.next(JSON.stringify({
    //   suppliers: "",
    //   importers: "",
    //   truckers: "",
    //   status: "",
    //   gov_statuses: "",
    //   courier_type:""
    // }));
  }

  setFilter(filter: string | null) {
     this.filterBS.next(filter);
  }

  setFilterables(filterables: string[] = [], _type: string = "supplier") {
    // if(_type != 'courier_type')
    //   this._filterables[_type] = filterables.join(",");
    // else
    //   this._filterables[_type] = filterables;

    // this.filterablesBS.next(JSON.stringify(this._filterables));
  }
}
