import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { OnChangePayload, TypeEnum, MessageType } from "src/app/models";
import { MessageService } from "src/app/services";

@Component({
  selector: "element-multi-selector",
  templateUrl: "./multi-selector.component.html",
  styleUrls: ["./multi-selector.component.scss"],
})
export class MultiSelectorComponent implements OnInit {
  constructor(private _ms: MessageService) {}

  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter();
  //@ts-ignore
  @ViewChild("input") input: ElementRef;

  ngOnInit(): void {}

  @Input() values: string[] = [];
  @Input() title: string = "Order Numbers";
  @Input() label: string = "Order Number";

  toggle: boolean = false;

  toggler() {
    this.add();
    this.toggle = !this.toggle;
  }

  remove(item: string) {
    this.values = this.values.filter((v) => v != item);
    this.change.emit({
      type: TypeEnum.MultiSelect,
      id:  this.label.replace(/ /g, "_").toLocaleLowerCase(),
      payload: this.values,
    });
  }

  keyup(event: KeyboardEvent) {
    if (!!this.input.nativeElement.value) {
      this.input.nativeElement.value = this.input.nativeElement.value
        .replace(/ /g, "")
        .replace(/-/g, "");
    }
  }
  add(){
    const input_value = this.input.nativeElement.value;
    if (input_value != "") {
      if (!this.values.find((v) => v == input_value)) {
        this.values = [...this.values, input_value];
        this.change.emit({
          type: TypeEnum.MultiSelect,
      id:  this.label.replace(/ /g, "_").toLocaleLowerCase(),

          payload: this.values,
        });
      } else {
        this._ms.set({
          type: MessageType.Warning,
          message: `This order number already exists.`,
          delay: 2500,
        });
      }
      this.input.nativeElement.value = "";
    }
  }

  keydown(event: KeyboardEvent) {
    if (event.key === "Tab" || event.key === "," || event.key === "Enter") {
      event.preventDefault();
      this.add();
      this.toggle = true;
    }

    if (event.key == "Escape") {
      this.toggler();
    }
  }
}
