// String.prototype.Camelize = Camelize;

// interface String {
//   Camelize: typeof Camelize;
// }

// function Camelize() {
//   return this.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
//     return index === 0 ? word.toLowerCase() : word.toUpperCase();
//   }).replace(/\s+/g, "");
// }
