import {Injectable} from '@angular/core';
import {GatewayClient, GatewayRequest} from '../../protos/';
import {config} from "../app.config";
import {grpc} from "@improbable-eng/grpc-web";
import Metadata = grpc.Metadata;
import {DocumentActions} from "./document.rpc.service";
import {GatewayStreamRequest} from "../../protos/gateway_pb";
import {str} from "ajv";
import {Status} from "../../protos/gateway_pb_service";

@Injectable({
  providedIn: 'root'
})
export class GatewayRpcService {

  client;

  constructor() {
    this.client = new GatewayClient(config.rpcUrl);
  }

  progress(percentage: number): number {
    return percentage;
  }

  readFile(file: File) {
    return new Promise<Uint8Array>((resolve, reject) => {
      // Create file reader
      const reader = new FileReader();

      // Register event listeners
      //@ts-ignore
      reader.addEventListener("loadend", e => resolve(e.target.result));
      reader.addEventListener("error", reject);

      // Read file
      reader.readAsArrayBuffer(file);
    });
  }

  streamRequest<T, M>(req: GatewayReq<T, M>, file: File | null, authenticate = true): any {

    return new Promise((resolve, reject) => {

      if (!config.enableRpc) {
        reject("RPC is disabled.");
      }

      const reader = new FileReader();

      if(file){
        reader.readAsArrayBuffer(file);
      }

      const data = JSON.stringify(req.data);

      const request = new GatewayStreamRequest();
      request.setAction(String(req.action));
      request.setNamespace(req.namespace);
      request.setVersion(req.version);
      request.setData(data || ``);

      if(file){
        const filename_split = file.name.split(".");
        request.setFilename(file.name);
        request.setFileExtension(filename_split[filename_split.length - 1]);
      }

      const rpc_access_token = localStorage.getItem("rpc.access_token");

      const metadata = new Metadata();

      if (authenticate && rpc_access_token) {
        try {
          const access_token: AccessToken = JSON.parse(rpc_access_token);
          metadata.append("Authorization", `Bearer ${access_token.token}`);
        } catch (e) {
          console.error("Invalid rpc authentication object.");
        }
      }

      const stream = this.client.streamRequest(metadata);
      stream.on("status", (status)=>{
        switch (status.code){
          case StatusCode.Unauthenticated:
            window.location.href = '/';
            break;
        }
      });
      stream.on("data",(response)=>{

        const data_object = response?.toObject();

        try {

          if (!data_object) {
            throw new Error("Cannot parse response");
          }

          const parsed = JSON.parse(data_object.response);

          switch (data_object.status) {
            case StatusCode.OK:
              resolve(parsed);
              break;
            default:
              console.log(data_object.status);
              reject({status: 'error', response: `code: ${data_object.status} message: ${data_object.response}`});
          }

        } catch (e) {
          reject({status: 'error', response: `${e}`});
        }
      });

      reader.onprogress = (event) => {
        this.progress(Math.floor(event.loaded / event.total * 100));
      };

      reader.onloadend = (e) => {
        stream.end();
      };

      reader.onload = (event) => {
        const arrayBuffer: any = event?.target?.result;

        if (arrayBuffer != null) {
          const chunk = new Uint8Array(arrayBuffer);

          request.setFile(chunk);

          stream.write(request);
        }

        // tslint:disable-next-line:no-shadowed-variable
        stream.on("end", (event) => {
        });

      };

      if(!file){
        console.log(request);
        stream.write(request);
      }

    });

  }

  request<T, M>(req: GatewayReq<T, M>, authenticate = true): any {
    return new Promise((resolve, reject) => {

      if (!config.enableRpc) {
        reject("RPC is disabled.");
      }

      const data = JSON.stringify(req.data);

      const request = new GatewayRequest();
      request.setVersion(req.version);
      request.setNamespace(req.namespace);
      request.setAction(String(req.action));
      request.setData(data || ``);

      const metadata = new Metadata();

      const rpc_access_token = localStorage.getItem("rpc.access_token");

      if (authenticate && rpc_access_token) {
        try {
          const access_token: AccessToken = JSON.parse(rpc_access_token);
          metadata.append("Authorization", `Bearer ${access_token.token}`);
        } catch (e) {
          console.error("Invalid rpc authentication object.");
        }
      }


      this.client.request(request, metadata, (err, response) => {

        if (err) {
          switch (err.code) {
            case StatusCode.Unauthenticated:
              window.location.href = '/';
              break;
          }
          reject(err);
        }

        const data_object = response?.toObject();

        try {

          if (!data_object) {
            throw new Error("Cannot parse response");
          }

          const parsed = JSON.parse(data_object.response);

          switch (data_object.status) {
            case StatusCode.OK:
              resolve(parsed);
              break;
            default:
              console.log(data_object.status);
              reject({status: 'error', response: `code: ${data_object.status} message: ${data_object.response}`});
          }

        } catch (e) {
          reject({status: 'error', response: `${e}`});
        }

      } );

    });

  }
}

export interface GatewayReq<T, M> {
  action: M;
  data: T;
  namespace: Namespace;
  version: Version;
}

export interface AccessToken {
  username: string;
  token: string;
}

export enum StatusCode {
  OK = 0,
  Cancelled = 1,
  Unknown = 2,
  InvalidArgument = 3,
  DeadlineExceeded = 4,
  NotFound = 5,
  AlreadyExists = 6,
  PermissionDenied = 7,
  ResourceExhausted = 8,
  FailedPrecondition = 9,
  Aborted = 10,
  OutOfRange = 11,
  Unimplemented = 12,
  Internal = 13,
  Unavailable = 14,
  DataLoss = 15,
  Unauthenticated = 16
}

export enum Version {
  Base = 0.1
}

export enum Namespace {
  Gateway = "service.Gateway",
  Identity = "service.Identity",
  Document = "service.Document",
  Batch = "service.Batch"
}

type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | JSONArray;

interface JSONObject {
  [x: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> {
}

