<section [ngClass]="{'active': states.panel && router.url == '/dashboard/batches'}">

    <div class="content">

        <ul>
            <li [ngClass]="{'active': states.adjust}" (click)="trigger_adjust_pane()">Adjust</li>
            <li  [ngClass]="{'active': states.saved}" (click)="trigger_saved_pane()">Saved</li>
	    <li (click)="clear_filter()">Clear Filter</li>
        </ul>

        <div  [ngClass]="{'active': states.adjust}"  class="adjust_filter__wrapper scrollbar__"  [ngStyle]="{'padding-bottom': (true)?'100px':' 100px'}"  >
            <div class="field_group name_field" *ngIf="filter.name != 'Bookmarked' && filter.awid != 'ed51b2ea-7dea-47c1-b6e6-0ac4e404e4d0'">
                <div class="field__input">
                    <label>Name</label>
                    <input type="text" autocomplete="off" #name (keyup)="update_name((name)?name:'')" [(ngModel)]="filter.name">
                </div>
            </div>  

            <div class="field_group" >
                <div class="field__input">
                    <div>
                        <label>Columns</label>
                        <element-dropdown-filter (change)="add_column_change($event)" [options]="add_column_option" [data]="available_columns"></element-dropdown-filter>
                    </div>
                    <ul class="columns_listing scrollbar__" cdkDropList (cdkDropListDropped)="drop($event)">
                        <li cdkDrag *ngFor="let column of filter.columns; let idx = index" class="column" draggable [id]="'column-'+idx" >
                            <i class="alpha-hamburger-menu" cdkDragHandle></i> 
                            <label>{{column.name}}</label>
                            <i class="icon-trash" (click)="remove_column(column)"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="field_group" >
                <div class="field__input">
                    <label>Filters</label>
                    <div class="filter_listing " *ngIf="states.active_filter_count > 0">
                        <div class="field_group" *ngFor="let column of filter.columns">
                            <div class="field__input">
                                <label>{{column.name}}</label>
                                <ng-template #dynamic></ng-template>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            

            <div class="filter_actions">
                <span *ngIf="has_filter_changed" class="changed">Unsaved Changes</span>
                <button class="button__ "  (click)="trigger()">Cancel</button>
                <button class="button__ primary" (click)="onUpdate()" *ngIf="!states.is_new">Update Filter</button>
                <button class="button__ primary" (click)="onCreate($event)" *ngIf="states.is_new">Create Filter</button>
            </div>


        </div>
        <div  [ngClass]="{'active': states.saved}"  class="saved_workspace__wrapper">
            <ul cdkDropList (cdkDropListDropped)="drop_saved($event)">
                <li cdkDrag *ngFor="let _filter of filters" draggable>
                    <div *ngIf="!_filter.toggle_delete && _filter.name != 'Bookmarked'  && _filter.awid != 'ed51b2ea-7dea-47c1-b6e6-0ac4e404e4d0'">
                        <i class="alpha-hamburger-menu" cdkDragHandle></i> 
                        <div class="field__group">
                            <div class="field-toggle">
                                <input type="checkbox" [checked]="_filter.active"  [id]="_filter.awid"  [name]="_filter.awid" [value]="_filter.awid" (change)="toggle_workspace_active_flag($event, _filter)" >
                                <label [for]="_filter.awid" >
                                    <div class="switch">
                                        <div class="dot"></div>
                                    </div>
                                    <span>{{_filter.name}}</span>
                                </label>
                            </div>
                        </div>    
                        <i class="icon-document" (click)="duplicate_workspace(_filter.awid)" ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="icon-trash" (click)="toggleDeleteWorkspace(_filter)" ></i>
                    </div>
                    <div *ngIf="_filter.toggle_delete">
                        <label>Are you sure you want to delete?</label>
                        <div class="delete_actions">
                            <button class="button__" (click)="deleteWorkspace(_filter)">Yes</button>
                            <button class="button__" (click)="toggleDeleteWorkspace(_filter)">No</button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>
<div [ngClass]="{'active': states.panel && router.url == '/dashboard/batches'}" class="overlay" (click)="trigger()">

</div>
