import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Status} from "../../services/batch.service";

@Component({
  selector: 'element-tag-values',
  templateUrl: './tag-values.component.html',
  styleUrls: ['./tag-values.component.scss']
})
export class TagValuesComponent implements OnInit, OnChanges {


	@Output() onClick = new EventEmitter();
  @Input() values: any[] = [];

  _values: string[] = [];

  @Input() isStatus = false;

  @Input() label = "View more";

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.values.currentValue){
      this._values = changes.values.currentValue.map((value: any) => (typeof value == "string") ? value : value?.label);
    }
  }
	clicked(){
    this.onClick.emit(true);
  }
  ngOnInit(): void {
  }


}
