<div class="dashboard__topbar">
  <div class="search__wrapper">
    <i class="icon-search"></i>
    <input type="text" [(ngModel)]="filter" (keyup)="onChange($event)">
    <div class="spacer"></div>
    <div class="advanced-filter" (click)="toggleAdvancedSearch()" *ngIf="router.url == '/dashboard/batches'"
         [ngClass]="{active: advancedSearch}">
      <i class="icon-cog"></i>&nbsp;&nbsp;Filters
    </div>
  </div>
  <div class="actions__wrapper" *ngIf=" role != Role.Trucker">

    <button class="button__ outline" [routerLink]="['create']" *ngIf="router.url == '/dashboard/batches' ">Create
      Batch
    </button>
    <button class="button__ outline" (click)="toggleCreateAccount()" *ngIf="router.url == '/dashboard/accounts'">Create
      Account
    </button>
  </div>
  <div class="account__menu">
    <mat-icon [matBadge]="unread_notice_count" [matBadgeHidden]="(unread_notice_count == 0)" matBadgeColor="warn">
	    <span class="material-symbols-outlined" (mouseover)="processNoticeHover()">
		notifications
	    </span>
      <ul class="notice_wrapper scrollbar__" *ngIf=" notices && notices.length > 0">
        <li *ngFor="let notice of notices">
          <div class="inner" [ngClass]="{'read':(notice.isRead)?'read':''}" (mouseover)="noticeItemOnHover(notice)">
            <div>
              <strong>{{notice.title}}</strong>
              <p [innerHtml]="notice.description"></p>
            </div>
            <div class="actions">
			<span class="material-symbols-outlined" (click)="dismissNotice(notice)">
			    close
			</span>
              <span *ngIf="notice.actions.id" class="material-symbols-outlined" (click)="noticeAction(notice.actions)">
                {{notice.actions.icon}}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </mat-icon>
    <div class="account__avatar" (click)="toggleAvatarMouseover()"><p>{{username_initials}}</p></div>
    <ul class="account__setting" [ngClass]="{active: states.account_menu.active}">
      <li [routerLink]="['settings']" routerLinkActive="router-link-active"><i class="icon-cog"></i>&nbsp;&nbsp;Account
        Settings
      </li>
      <li (click)="logout()"><i class="icon-logout"></i>&nbsp;&nbsp;Logout</li>
    </ul>
  </div>
</div>
