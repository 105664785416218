export { Account, Role } from "./account";
export { Message, MessageType } from "./message";

export interface HttpResponse {
  status: number;
  message: string;
}

export enum TypeEnum {
  Filter = "filter",
  MultiFilterSelect = "multi-filter-select",
  DateFilter = "date-filter",
  TextFieldFilter = "textfield-filter",
  MultiFilterSearch = "multi-filter-search",
  Select = "select",
  MultiSelect = "multi-select",
  MultiSelectTags = "multi-select-tags",
  DropDown = "dropdown-filter",
  Uploader = "document-uploader",
  DisregardConflict = "disregard-conflict",
  StateToggler = "state-toggler",
}
export interface OnChangePayload {
  type: TypeEnum;
  id?: string;
  payload: any;
}

export interface IValidator {
  [key: string]: boolean | any;
  conflict: {
    [key: string]: boolean;
  };
}

export interface DropDownFilterOptions {
  placeholder?: string;
  icon?: string;
  hide_label?: boolean;
  keys?: {
    identifier?: string;
    label?: string;
    children?: string;
  };
}

export interface DropDownFilterData {
  [key: string]: any;
}


export interface OutlookCallback{
  accessToken:string,
  account:{
    emailAddress:string,
    accountType:string,
    displayName:string,
    timeZone:string,
  }
  item:string
}