import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";

import { config } from "../app.config";

import { AuthenticationService } from "../services/authentication.service";
import { switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  requests:RequestObservable = {};

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const current_account = this.authenticationService.currentAccountValue;
    const isLoggedIn = current_account && current_account.token;

    if (isLoggedIn) {
      request = request.clone({
        url: config.apiUrl + request.url,
        setHeaders: {
          Authorization: `Bearer ${current_account.token}`
        }
      });
    } else {
      request = request.clone({
        url: config.apiUrl + request.url
      });
    }
    
    return next.handle(request)
   

  }
}


interface RequestObservable {
  [key: string]: Observable<HttpEvent<any>>;
}