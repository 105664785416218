<element-quick-edit
  (onChange)="onQuickEditChange($event)"
  [selection]="selection.selected"
  [toggle]="quick_edit.active"
  [filter]="table.options.page"
></element-quick-edit>
<div
  class="table__groups"
  #table_group_ele
  *ngIf="table?.options?.groups?.length > 0"
>
  <div
    *ngFor="let group of table?.options?.groups"
    (click)="groupBy(group.id)"
    [ngClass]="{ active: table?.options?.page?.groupBy == group.id }"
    [innerHTML]="group.label"
    [ngStyle]="{
      width: group.tabWidth ? group.tabWidth + 'px' : '',
      'background-color': group.bgColor ? group.bgColor : '',
      color: group.textColor ? group.textColor : ''
    }"
    (click)="onClickCapture(group?.onClick)"
  ></div>
</div>
<div class="wrapper scrollbar__" #wrapperEle [ngClass]="{ loading: isLoading }">
  <div class="loader" [ngClass]="{ active: isLoading }">
    <img src="../../../assets/branding/loader.gif"/>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
    class="mat-elevation-z8"
    #batchListing
  >
   <ng-container matColumnDef="clicker" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.px]="0" [style.min-width.px]="0" [style.max-width.px]="0" >
      </th>
      <td mat-cell class="clicker" *matCellDef="let element" #iconElem [style.width.px]="0" [style.min-width.px]="0" [style.max-width.px]="0" (click)="viewBatch(element)" >
      </td>
    </ng-container>
    <ng-container matColumnDef="bid" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element.bid) : null"
          [checked]="selection.isSelected(element.bid)"
          [aria-label]="checkboxLabel(element.bid)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="bookmarked" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <i class="icon-bookmark"></i>
      </th>
      <td mat-cell *matCellDef="let element" #iconElem>
        <i
          class="icon-bookmark"
          [ngClass]="{ 'bookmark-active': element.bookmarked }"
          (click)="bookmarkBatch(element, iconElem)"
        ></i>
      </td>
    </ng-container>
    <ng-container matColumnDef="awb_bol_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AWB/BOL Number.</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard (onClick)="onAwbBolClick(element)" [value]="element.awb_bol_number"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="batch_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
       	<element-clipboard (onClick)="viewBatch(element)" [value]="element.batch_id"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="courier_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AWB/BOL</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="viewBatch(element)"
        [innerHTML]="element.courier_type | awb_bol"
      ></td>
    </ng-container>
    <ng-container matColumnDef="created_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.created_by"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="created_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.created_on"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="estimated_date_arrival">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ETA</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.estimated_date_arrival"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="lfd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>LFD</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.lfd"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="container_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Container Number
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.container_number"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="reference_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Reference Number
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard (onClick)="viewBatch(element)" [value]="element.reference_number"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="log_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Log Code</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.log_code"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="importers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Importer</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard (onClick)="viewBatch(element)" [value]="element.importers"></element-clipboard>

      </td>
    </ng-container>
    <ng-container matColumnDef="order_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</th>
      <td
        mat-cell
        *matCellDef="let element"

      >
        <element-tag-values (onClick)="viewBatch(element)" [values]="element.order_number" label="Order Number"></element-tag-values>
      </td>
    </ng-container>
    <ng-container matColumnDef="gov_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gov. Statuses</th>
      <td
        mat-cell
        *matCellDef="let element"
      >

        <element-tag-values (onClick)="viewBatch(element)" [values]="element.gov_status" label="Gov. Statuse"></element-tag-values>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="viewBatch(element)"
        [innerHTML]="element.status | batch_status"
      ></td>
    </ng-container>
    <ng-container matColumnDef="suppliers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Suppliers</th>
      <td
        mat-cell
        *matCellDef="let element"
      >

        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.suppliers"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="truckers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Truckers</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.truckers"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="updated_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-clipboard  (onClick)="viewBatch(element)" [value]="element.updated_on"></element-clipboard>
      </td>
    </ng-container>
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <element-tag-values (onClick)="viewBatch(element)" [values]="element.tags" label="Tag"></element-tag-values>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
      <td mat-cell *matCellDef="let element">
        <i
          class="icon-revision_edit"
          *ngIf="editHideConditions(element)"
          (click)="reviseBatch(element)"
        ></i>
        <i
          class="icon-trash"
          *ngIf="discardHideConditions(element)"
          (click)="toggleDraftDiscard(element)"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [style.maxHeight.px]="50" [style.height.px]="50"
    >
    </tr>
  </table>
  <mat-toolbar class="pager_toolbar">
    <mat-toolbar-row>
      <div class="table_actions">
        <button
          mat-stroked-button
          [matBadge]="selection.selected.length"
          matBadgePosition="after"
          matBadgeColor="#559765"
          matBadgeSize="small"
          *ngIf="selection.selected.length > 0"
          (click)="toggleQuicedit()"
        >
          Quick Edit
        </button
        >&nbsp;&nbsp;
        <button
          mat-stroked-button
          matBadgePosition="after"
          matBadgeColor="#559765"
          matBadgeSize="small"
          *ngIf="
            table.options.page.count &&
            table.options.page.count > 0 &&
            !hide_export
          "
          (click)="exportWorkspace()"
        >
          Export <b>({{ table.options.page.count }})</b>
        </button>
      </div>
      <mat-paginator
        class="paginator"
        [pageSizeOptions]="[25, 50, 100]"
        [pageSize]="table.options.page.size"
        [length]="table.options.page.count"
        [pageIndex]="table.options.page.current - 1"
        (page)="onPagerChange($event)"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      ></mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
