import {Injectable} from '@angular/core';
import {GatewayRpcService, Namespace, Version} from "./gateway.rpc.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentRpcService {

  constructor(private rpc: GatewayRpcService) {

  }

  messageBase64: string | undefined;

  progress(per: number): number {
    return per;
  }

  async getAsciMessage() {
    try {
      const streamResponse = await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.GetASCIMessage, data: {}
        }
      );

      if (this.messageBase64 && this.messageBase64 == streamResponse.stream ) {
        console.log('trigger');
        return undefined;
      }

      this.messageBase64 = streamResponse.stream;

      if (!this.messageBase64) {
        return undefined;
      }

      const bytesCharacters = atob(this.messageBase64);
      const bytes = new Uint8Array(bytesCharacters.length);
      for (let i = 0; i < bytesCharacters.length; i++) {
        bytes[i] = bytesCharacters.charCodeAt(i);
      }
      console.log('trigger');
      return bytes;
    } catch (e) {
      console.error(e);
    }
  }

  async upload(bid: string, file: File) {
    try {
      this.rpc.progress = (per) => this.progress(per);

      return await this.rpc.streamRequest<{ bid: string }, DocumentActions>(
        {version: Version.Base, namespace: Namespace.Document, action: DocumentActions.DocumentUpload, data: {bid}}
        , file);
    } catch (e) {
      return e;
    }
  }

  async getBatchDocuments(bid: string, parentBid: string | undefined) {
    try {
      const documents = await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Document, action: DocumentActions.GetBatchDocuments, data: {bid, parentBid}}
      );
      return documents.documents;
    } catch (e) {
      return e;
    }
  }

  async getDocuments(bid: string, parentBid: string | undefined) {
    try {
      const documents = await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Document, action: DocumentActions.GetDocuments, data: {bid, parentBid}}
      );
      return documents.documents;
    } catch (e) {
      return e;
    }
  }

  async getDocumentTypes() {
    try {
      const documentTypes = await this.rpc.request(
        {version: Version.Base, namespace: Namespace.Document, action: DocumentActions.GetDocumentTypes, data: {}}
      );
      return documentTypes.Types;
    } catch (e) {
      return e;
    }
  }

  async createUpdateDocument(bid: string, did: string, dtid: string) {
    try {
      return await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.CreateUpdateDocument, data: {bid, document: {did, dtid}}
        }
      );
    } catch (e) {
      return e;
    }
  }

  async generatePreview(document_ids: string[], bid: string) {
    try {
      const streamResponse = await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.GeneratePreview, data: {document_ids, bid}
        }
      );
      const bytesCharacters = atob(streamResponse.stream);
      const bytes = new Uint8Array(bytesCharacters.length);
      for (let i = 0; i < bytesCharacters.length; i++) {
        bytes[i] = bytesCharacters.charCodeAt(i);
      }
      return bytes;
    } catch (e) {
      console.error(e);
    }
  }

  async getDocumentDownload(bid: string, document_ids: string[]) {
    try {
      const streamResponse = await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.GetDocumentDownload, data: {bid, document_ids}
        }
      );
      const bytesCharacters = atob(streamResponse.stream);
      const bytes = new Uint8Array(bytesCharacters.length);
      for (let i = 0; i < bytesCharacters.length; i++) {
        bytes[i] = bytesCharacters.charCodeAt(i);
      }
      return bytes;
    } catch (e) {
      console.error(e);
    }
  }

  async getDocumentStream(bid: string, dtid: string) {
    try {
      const streamResponse = await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.GetDocumentStream, data: {bid, dtid}
        }
      );
      const bytesCharacters = atob(streamResponse.stream);
      const bytes = new Uint8Array(bytesCharacters.length);
      for (let i = 0; i < bytesCharacters.length; i++) {
        bytes[i] = bytesCharacters.charCodeAt(i);
      }
      return bytes;
    } catch (e) {
      return e;
    }
  }

  async removeDocument(did: string) {
    try {

      return await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.RemoveDocument, data: {did}
        }
      );
    } catch (e) {
      return e;
    }
  }

  async getDocumentToken(did: string) {
    try {

      return await this.rpc.request(
        {
          version: Version.Base, namespace: Namespace.Document,
          action: DocumentActions.GetDocumentToken, data: {did}
        }
      );
    } catch (e) {
      return e;
    }
  }
}

export enum DocumentActions {
  GeneratePreview = "GeneratePreview",
  GetDocumentDownload = "GetDocumentDownload",
  GetDocumentStream = "GetDocumentStream",
  GetDocumentToken = "GetDocumentToken",
  GetDocuments = "GetDocuments",
  GetBatchDocuments = "GetBatchDocuments",
  CreateUpdateDocument = "CreateUpdateDocument",
  RemoveDocument = "RemoveDocument",
  GetDocumentTypes = "GetDocumentTypes",
  DocumentUpload = "DocumentUpload",
  GetASCIMessage = "GetASCIMessage"
}
