export interface Account {
  oid: number;
  username: string;
  password: string;
  token?: string;
  role: Role;
}

export enum Role {
  Alpha = "Alpha",
  Manager = "Manager",
  Clerk = "Clerk",
  Supplier = "Supplier",
  Bookmarked = "Bookmarked",
  Importer = "Importer",
  Trucker = "Trucker",
}

export enum Claim {
  Actor = "actort",
  ID = "nameid",
  Name = "unique_name",
  Role = "role",
  Expire = "exp",
}

export interface CreateAccountRequest {
  username: string;
  role: Role;
  password: string;
  confirm_paassword: string;
}

export interface AccountAssociationRequest {
  aaid?: string;
  aid: string;
  aida: string;
}
