import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  OnChangePayload,
  TypeEnum,
  DropDownFilterData,
  DropDownFilterOptions,
} from "src/app/models";
import {Newid, Offset} from "src/app/helpers/util";

@Component({
  selector: "element-dropdown-filter",
  templateUrl: "./dropdown-filter.component.html",
  styleUrls: ["./dropdown-filter.component.scss"],
})
export class DropdownFilterComponent implements OnInit, AfterViewInit {

  constructor() {}
  @Input() options: DropDownFilterOptions = {};
  @Input() data: DropDownFilterData[] = [];
  @Input() value: string = "";
  //@ts-ignore
  @Output() change: EventEmitter<OnChangePayload> = new EventEmitter(null);
  //@ts-ignore
  selected: DropDownFilterData;
  search: string = "";
  toggle: boolean = false;

  bodyOffset: any = document.querySelector("body")?.offsetHeight;
  //@ts-ignore
  @ViewChild("viewpoint") viewpoint: ElementRef;
  //@ts-ignore
  viewpointNative: HTMLDivElement;
	random_id:string;

  ngAfterViewInit() {
    this.viewpointNative = this.viewpoint.nativeElement;
  }
  ngOnInit(): void {
    this.random_id = Newid();

    const defaultOptions = {
      placeholder: "Document Types",
      icon: "fa fa-caret-down",
      hide_label: false,
      keys: {
        identifier: "dtid",
        label: "name",
        children: "types",
      },
    };

    this.options = { ...defaultOptions, ...this.options };

    if (this.value) {
      for (let x = 0; x < this.data.length; x++) {
        const parent = this.data[x];
        if (this.options.keys?.children && this.options.keys.identifier)
          for (let i = 0; i < parent[this.options.keys.children].length; i++) {
            const child = parent[this.options.keys.children][i];
            if (child[this.options.keys.identifier] == this.value) {
              this.selected = child;
            }
          }
      }
    }
  }

  select(item: any) {
    this.selected = item;
    this.toggler();

    this.change.emit({
      type: TypeEnum.DropDown,
      payload: item,
    });
  }

  toggler() {
    this.toggle = !this.toggle;

    const viewpointOffset: any = Offset(this.viewpointNative);
    if (viewpointOffset.top + 300 > this.bodyOffset) {
      this.viewpointNative.style.top = "-300px";
    }
  }
}
